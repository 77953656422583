import gql from 'graphql-tag';

/*=============================================m_ÔÔ_m=============================================\
    SectionBases
\================================================================================================*/
export const SECTION_BASE_VERSION_FRAGMENT = gql`
    fragment SectionBaseVersionFragment on SectionBaseVersion {
        id
        active
        version
        # sectionBase {
        #     ...SectionBaseFragment
        # }
        isLatest
        createdAt
        updatedAt
    }
`;

export const SECTION_BASE_FRAGMENT = gql`
    fragment SectionBaseFragment on SectionBase {
        id
        name
        repository
        sourceCodeStatus {
            status
            progress
            message
            date
            commit
        }
        sectionBaseVersion {
            ...SectionBaseVersionFragment
        }
        members
        createdAt
        updatedAt
    }
    ${SECTION_BASE_VERSION_FRAGMENT}
`;

/*=============================================m_ÔÔ_m=============================================\
    WwObjectBases
\================================================================================================*/
export const WWOBJECT_BASE_VERSION_FRAGMENT = gql`
    fragment WwObjectBaseVersionFragment on WwObjectBaseVersion {
        id
        active
        version
        isLatest
        createdAt
        updatedAt
    }
`;

export const WWOBJECT_BASE_FRAGMENT = gql`
    fragment WwObjectBaseFragment on WwObjectBase {
        id
        name
        repository
        type
        sourceCodeStatus {
            status
            progress
            message
            date
            commit
        }
        wwObjectBaseVersion {
            ...WwObjectBaseVersionFragment
        }
        members
        createdAt
        updatedAt
    }
    ${WWOBJECT_BASE_VERSION_FRAGMENT}
`;

/*=============================================m_ÔÔ_m=============================================\
    Plugins
\================================================================================================*/
export const PLUGIN_VERSION_FRAGMENT = gql`
    fragment PluginVersionFragment on PluginVersion {
        id
        active
        version
        isLatest
        createdAt
        updatedAt
    }
`;

export const PLUGIN_FRAGMENT = gql`
    fragment PluginFragment on Plugin {
        id
        name
        repository
        sourceCodeStatus {
            status
            progress
            message
            date
            commit
        }
        pluginVersion {
            ...PluginVersionFragment
        }
        members
        createdAt
        updatedAt
    }
    ${PLUGIN_VERSION_FRAGMENT}
`;

// export const PLUGIN_FRAGMENTS = gql`
//     fragment PluginVersionFragment on PluginVersion {
//         id
//         active
//         version
//         createdAt
//         updatedAt
//     }
//     fragment PluginCategoryFragment on PluginCategory {
//         id
//         name
//         createdAt
//         updatedAt
//     }
//     fragment PluginFragment on Plugin {
//         id
//         name
//         storeName
//         storeDesc
//         name
//         logo
//         hasCode
//         repository
//         archived
//         pluginCategoryId
//         pluginCategory {
//             ...PluginCategoryFragment
//         }
//         organizations {
//             organization {
//                 ...OrganizationFragment
//             }
//             role
//         }
//         pluginVersion {
//             ...PluginVersionFragment
//         }
//         createdAt
//         updatedAt
//     }
//     ${ORGANIZATION_FRAGMENTS}
// `;
