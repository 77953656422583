<template>
    <div class="ww-manager-select" @click="openPopupSelect()" :class="{ disabled: disabled }">
        <div class="text">{{ wwManagerLang.getText(selectedValue.text) }}</div>
        <wwEditorIcon class="icon" name="chevron-down-outline" small="small" />
    </div>
</template>

<script>
export default {
    name: 'wwManagerSelect',
    props: {
        options: Object,
        value: [String, Object, Number, Boolean],
        disabled: [String, Boolean],
    },
    data() {
        return {
            wwManagerLang: wwLib.wwManagerLang,
            selectedValue: {},
        };
    },
    computed: {
        type() {
            return this.options.type || 'text';
        },
        values() {
            return (
                this.options.values || [
                    {
                        value: 'Error',
                        text: {
                            en: 'Error',
                            fr: 'Erreur',
                        },
                    },
                ]
            );
        },
    },

    watch: {
        value: {
            handler: function () {
                this.setSelectedValue();
            },
            deep: true,
        },
    },
    methods: {
        init() {
            this.setSelectedValue();
        },

        setSelectedValue() {
            this.selectedValue = null;
            for (const value of this.values) {
                if (this.value) {
                    if (_.isEqual(value.value, this.value)) {
                        this.selectedValue = value;
                    }
                }
            }
            if (!this.selectedValue) {
                for (const value of this.values) {
                    if (value.default) {
                        this.selectedValue = value;
                    }
                }
            }
        },

        async openPopupSelect() {
            try {
                let localValue = await wwLib.wwPopupSelects.open(this.options);
                this.$emit('change', localValue);
                this.$emit('input', localValue);
            } catch (error) {
                wwLib.wwLog.error(error);
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
.ww-manager-select {
    display: flex;
    justify-content: space-between;
    flex-direction: row !important;
    border: 1px solid var(--ww-color-dark-300);
    padding: 7px 10px 7px 10px;
    border-radius: var(--ww-spacing-02);
    color: var(--ww-color-dark-600);
    cursor: pointer;
    transition: border-color 0.3s ease;
    align-items: center;

    &.disabled {
        cursor: not-allowed;
    }

    .text {
        font-size: 1rem;
        text-transform: uppercase;
        padding-right: 7px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .arrow {
        font-size: 1.5rem;
        transition: color 0.3s ease;
    }

    &:hover {
        border-color: var(--ww-color-blue-300);
    }
}

.popup-select {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.7);
}
</style>
