import sourcesCodes from './sourcesCodes.lang';
import accountSettings from './accountSettings.lang';
import websites from './websites.lang';
import organizations from './organizations.lang';
import utils from './utils.lang';
import popup from './popup.lang';

const lang = {
    en: {
        sourcesCodes: sourcesCodes.en,
        accountSettings: accountSettings.en,
        websites: websites.en,
        organizations: organizations.en,
        utils: utils.en,
        popup: popup.en,
    },
    fr: {
        sourcesCodes: sourcesCodes.fr,
        accountSettings: accountSettings.fr,
        websites: websites.fr,
        organizations: organizations.fr,
        utils: utils.fr,
        popup: popup.fr,
    },
};

export default lang;
