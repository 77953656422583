<template>
    <div class="ww-popup-website-publish">
        <label class="publish__label caption-s" for="commit">
            Title
            <span class="publish__label-required">{{ $t('utils.required') }}</span>
        </label>
        <input
            type="text"
            class="publish__input ww-editor-input -large caption-m"
            name="commit"
            v-model="commit"
            placeholder="A couple of words is best ..."
        />
        <label class="publish__label caption-s">Advanced</label>
        <div class="publish__row">
            <wwManagerRadio small :value="isPrerenderDisabled" @change="setIsPrerenderDisabled"></wwManagerRadio>
            <span class="caption-m ml-2">Disable pre-rendering</span>
        </div>
        <span class="publish__label caption-s mt-2"
            >We recommend disabling pre-rendering for projects that don’t require SEO. Disabling pre-rendering will
            simplify the application build process and reduce the time needed to publish the application.
            <br /><br />NOTE: While disabling pre-rendering will not negatively affect the performance of the app or SEO
            ranking quality, it will increase the time it takes for each page to be indexed by search engines.</span
        >
    </div>
</template>

<script>
import services from '@/services';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'wwPopupWebsitePublish',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            commit: '',
            isPrerenderDisabled: false,
        };
    },
    computed: {
        ...mapGetters({
            workspace: 'v2/getCurrentOrganization',
            design: 'v2/getCurrentDesign',
        }),
    },
    watch: {
        commit() {
            if (this.commit.length) this.options.setButtonState('PUBLISH', 'ok');
            else this.options.setButtonState('PUBLISH', 'disabled');
        },
    },
    methods: {
        ...mapActions({
            updateDesign: 'v2/updateDesign',
        }),
        async beforeNext() {
            this.options.setLoadingStatus(true);
            try {
                const websiteId = this.options.data.websiteId;
                const { updateStatus } = await services.api.design.publish(
                    websiteId,
                    this.commit,
                    '',
                    this.options.data.env
                );
                if (updateStatus === 'MAJOR' || updateStatus === 'MINOR') {
                    wwLib.wwNotification.open({
                        text: {
                            en: '<b>Please upgrade your instance of weweb-server.</b>',
                        },
                        color: 'red',
                    });

                    wwLib.wwModals.open({
                        title: { en: `New ${updateStatus} version weweb-server available` },
                        text: {
                            en: `A new ${updateStatus} version of weweb-server is available at <a target="_blank" href="https://github.com/weweb-assets/weweb-server">https://github.com/weweb-assets/weweb-server</a>.<br/>It <b>is</b> mandatory to update your instance of weweb-server before you can configure it again.`,
                        },
                        buttons: [
                            {
                                text: {
                                    en: 'Ok',
                                },
                                color: '-primary',
                                value: true,
                                enter: true,
                            },
                        ],
                    });
                } else if (updateStatus === 'ERROR') {
                    wwLib.wwNotification.open({
                        text: '<b>Error connecting to your instance of weweb-server.</b>',
                        color: 'red',
                    });
                }
            } catch (err) {
                wwLib.wwLog.error(err);
            }
            this.options.setLoadingStatus(false);
        },
        async setIsPrerenderDisabled(newIsPrerenderDisabled) {
            this.isPrerenderDisabled = newIsPrerenderDisabled;

            await services.api.design.updateDesign(this.design.id, {
                prerenderDisabled: this.isPrerenderDisabled,
            });
            this.updateDesign({
                organizationId: this.workspace.id,
                design: { ...this.design, prerenderDisabled: this.isPrerenderDisabled },
            });
        },
    },
    created() {
        this.options.setButtonState('PUBLISH', 'disabled');

        this.isPrerenderDisabled = this.design.prerenderDisabled || false;
    },
};
</script>

<style scoped lang="scss">
.ww-popup-website-publish {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;
    .publish {
        &__label {
            display: flex;
            font-weight: 500;
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
            &-required {
                margin-left: auto;
                color: var(--ww-color-dark-400);
            }
        }
        &__input {
            margin-bottom: var(--ww-spacing-03);
        }
        &__row {
            display: flex;
            align-items: center;
        }
    }
}
</style>
