import wwLoader from './elements/wwLoader.vue';
import wwLoaderSmall from './elements/wwLoaderSmall.vue';
import wwManagerGithubButton from './elements/wwManagerGithubButton.vue';
import wwManagerMenu from './elements/wwManagerMenu.vue';
import wwManagerMenuButton from './elements/wwManagerMenuButton.vue';
import wwManagerRadio from './elements/wwManagerRadio.vue';
import wwManagerSearchBar from './elements/wwManagerSearchBar.vue';
import wwManagerSelect from './elements/wwManagerSelect.vue';
import wwModal from './elements/wwModal.vue';
import wwNotification from './elements/wwNotification.vue';
import wwPopup from './elements/wwPopup.vue';
import wwPopupSelect from './elements/wwPopupSelect.vue';
import wwTab from './elements/wwTab.vue';
import wwTable from './elements/wwTable.vue';
import wwTabs from './elements/wwTabs.vue';
import wwEditorIcon from './elements/wwEditorIcon.vue';
import wwManagerDetailsButton from './elements/wwManagerDetailsButton.vue';
import wwEditorSelect from './elements/wwEditorSelect/wwEditorSelect.vue';
import wwEditorFlyOut from './elements/wwEditorFlyOut.vue';
import wwWorkspaceFlair from './elements/wwWorkspaceFlair.vue';

export default {
    install(Vue) {
        Vue.component('wwLoader', wwLoader);
        Vue.component('wwLoaderSmall', wwLoaderSmall);
        Vue.component('wwManagerMenu', wwManagerMenu);
        Vue.component('wwManagerMenuButton', wwManagerMenuButton);
        Vue.component('wwManagerGithubButton', wwManagerGithubButton);
        Vue.component('wwManagerRadio', wwManagerRadio);
        Vue.component('wwManagerSearchBar', wwManagerSearchBar);
        Vue.component('wwManagerSelect', wwManagerSelect);
        Vue.component('wwModal', wwModal);
        Vue.component('wwNotification', wwNotification);
        Vue.component('wwPopup', wwPopup);
        Vue.component('wwPopupSelect', wwPopupSelect);
        Vue.component('wwTab', wwTab);
        Vue.component('wwTable', wwTable);
        Vue.component('wwTabs', wwTabs);
        Vue.component('wwEditorIcon', wwEditorIcon);
        Vue.component('wwManagerDetailsButton', wwManagerDetailsButton);
        Vue.component('wwEditorSelect', wwEditorSelect);
        Vue.component('wwEditorFlyOut', wwEditorFlyOut);
        Vue.component('wwWorkspaceFlair', wwWorkspaceFlair);
    },
};
