import gql from 'graphql-tag';
import { USER_FRAGMENT } from './user.fragments';

/*=============================================m_ÔÔ_m=============================================\
    Auth
\================================================================================================*/
export const LOGIN_MANAGER = gql`
    query LoginManager($email: String!, $password: String!) {
        loginManager(email: $email, password: $password) {
            success
        }
    }
`;
export const LOGOUT = gql`
    query Logout {
        logout {
            success
        }
    }
`;

/*=============================================m_ÔÔ_m=============================================\
    Users
\================================================================================================*/
export const GET_ME = gql`
    query GetMe {
        getMe {
            ...UserFragment
        }
    }
    ${USER_FRAGMENT}
`;

export const GET_USERS = gql`
    mutation DeleteOrganizationMember($organizationId: String!, $userId: String!) {
        deleteOrganizationMember(organizationId: $organizationId, userId: $userId) {
            success
        }
    }
`;

export const GET_USER = gql`
    mutation UpdateOrganizationMemberRole($organizationId: String!, $userId: String!, $role: String!) {
        updateOrganizationMemberRole(organizationId: $organizationId, userId: $userId, role: $role) {
            id
        }
    }
`;

export const SIGN_UP_USER = gql`
    mutation SignUpUser($email: String!, $password: String!, $name: String!, $referralId: String) {
        signUpUser(email: $email, password: $password, name: $name, referralId: $referralId) {
            id
        }
    }
`;
export const SIGN_UP_USER_TO_ORGANIZATION = gql`
    mutation SignUpUserToOrganization($password: String!, $token: String!) {
        signUpUserToOrganization(password: $password, token: $token) {
            id
        }
    }
`;
export const SEND_MAIL_FOR_NEW_PASSWORD = gql`
    mutation SendMailForNewPassword($email: String!) {
        sendMailForNewPassword(email: $email) {
            success
        }
    }
`;
export const UPDATE_FORGOTTEN_PASSWORD = gql`
    mutation UpdateForgottenPassword($token: String!, $newPassword: String!) {
        updateForgottenPassword(token: $token, newPassword: $newPassword) {
            success
        }
    }
`;
export const UPDATE_USER = gql`
    mutation UpdateUser($id: String!, $email: String, $name: String, $picture: String) {
        updateUser(id: $id, email: $email, name: $name, picture: $picture) {
            id
        }
    }
`;

/*=============================================m_ÔÔ_m=============================================\
    Admin
\================================================================================================*/
export const ADD_WEWEB_ADMIN = gql`
    mutation CreateOrganizationInvitations($organizationId: String!, $users: JSON!, $message: String) {
        createOrganizationInvitations(organizationId: $organizationId, users: $users, message: $message) {
            id
            name
            email
            role
            status
            author {
                id
                name
            }
            updatedAt
        }
    }
`;

export const REMOVE_WEWEB_ADMIN = gql`
    query GetOrganizationInvitations($organizationId: String!, $status: String) {
        getOrganizationInvitations(organizationId: $organizationId, status: $status) {
            id
            name
            email
            role
            status
            author {
                name
            }
            updatedAt
        }
    }
`;
