import apollo from '@/apollo';
import { GET_MAINTENANCE } from './info.graphql';

export default {
    /*=============================================m_ÔÔ_m=============================================\
        Maintenance
    \================================================================================================*/
    async getMaintenance() {
        const result = await apollo.query({
            query: GET_MAINTENANCE,
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_MAINTENANCe');

        return result.data.getMaintenance;
    },
};
