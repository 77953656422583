export default {
    async addDashboardSvgIcons() {
        const doc = window.document;

        if (!doc.body.querySelector('.ww-manager-icons-svg')) {
            const response = await axios.get(`${process.env.VUE_APP_BASE_URL || '/'}icons.svg`, {
                withCredentials: false,
            });
            if (response && response.data) {
                const div = doc.createElement('div');
                div.innerHTML = response.data;
                const dashboardIconsSvg = div.firstChild;
                dashboardIconsSvg.classList.add('ww-manager-icons-svg');
                dashboardIconsSvg.style.display = 'none';

                doc.body.prepend(dashboardIconsSvg);
            }
        }
    },
};
