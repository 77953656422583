export default {
    en: {
        help: 'Need help?',
        settings: 'Account settings',
        editInfo: 'Edit information',
        logout: 'Sign out',
        preview: 'preview',
        choose: 'choose',
        name: 'Name',
        email: 'Email',
        password: 'Password',
        year: 'year',
        month: 'month',
        next: 'Next',
        filters: 'Filters',
        add: 'Add',
        remove: 'Remove',
        required: 'required',
    },
    fr: {
        help: "Besoin d'aide ?",
        settings: 'Options du compte',
        editInfo: 'Modifier mes informations',
        logout: 'Se déconnecter',
        preview: 'aperçu',
        choose: 'choisir',
        name: 'Nom',
        email: 'Email',
        password: 'Mot de passe',
        year: 'an',
        month: 'mois',
        next: 'Suivant',
        filters: 'Filtres',
        all: 'Tous',
        add: 'Ajouter',
        remove: 'Supprimer',
        required: 'requis',
    },
};
