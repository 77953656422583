<template>
    <div class="ww-popup-website-domain-buy ww-scroll-bar">
        <!-- SEARCH -->
        <div class="domain-search">
            <input
                class="domain-search-input ww-editor-input -large"
                :placeholder="$t('popup.domain.inputLabel')"
                v-model="domain.name"
                @keyup.enter="checkDomainName"
                :disabled="loading"
            />
            <button
                class="domain-search-button ww-editor-button -primary"
                @click="checkDomainName"
                :disabled="!domain.name.length || loading"
            >
                {{ $t('popup.domain.availability') }}
            </button>
        </div>
        <!-- LOADER -->
        <wwLoaderSmall v-if="loading" class="domain-loader" />
        <!-- DOMAIN INFO -->
        <div v-else-if="domain.info" class="domain-info">
            <div class="domain-elem" :class="elem.status" v-for="elem in domain.info" :key="elem.id">
                <!-- NAME -->
                <div class="name label-m">{{ elem.name }}</div>
                <!-- NOT AVAILABLE -->
                <span class="taken label-m" v-if="!domainPrice(elem)">{{ $t('popup.domain.notAvailable') }}</span>
                <!-- PRICE -->
                <span v-if="domainPrice(elem)" class="domain-price label-s">
                    <span class="price">{{ getPrice(domainPrice(elem).price_after_taxes + 1) }}</span>
                    <span class="discount" v-if="domainPrice(elem).discount">
                        {{ getPrice(domainPrice(elem).normal_price_after_taxes + 1) }}
                    </span>
                    <span class="duration" v-if="domainPrice(elem).duration_unit === 'y'">
                        <span> / </span>
                        <span v-if="domainPrice(elem).min_duration > 1">{{ domainPrice(elem).min_duration }}</span>
                        <span>{{ $t('utils.year') }}</span>
                    </span>
                    <span class="duration" v-else-if="domainPrice(elem).duration_unit === 'm'">
                        / {{ $t('utils.month') }}
                    </span>
                </span>
                <!-- SELECTED -->
                <wwManagerRadio
                    v-if="domainPrice(elem)"
                    class="radio"
                    :value="elem.selected"
                    @change="elem.selected = $event"
                />
            </div>
            <!-- MORE INFO -->
            <a
                class="more-info caption-m"
                :href="`https://shop.gandi.net/domain/suggest?search=${domain.name}`"
                target="_blank"
            >
                {{ $t('popup.domain.moreOn') }}
            </a>
        </div>
        <!-- DOMAIN ERROR -->
        <div v-else-if="domain.error" class="domain-error label-l">{{ domain.error }}</div>
    </div>
</template>

<script>
// import { DOMAIN_CHECK } from '../../apollo/graphql';
import services from '@/services';

export default {
    name: 'wwPopupWebsiteDomainBuy',
    props: {
        options: {
            type: Object,
            default() {
                return {};
            },
        },
        active: Boolean,
    },
    data() {
        return {
            loading: false,
            domain: {
                name: '',
                info: undefined,
            },
        };
    },
    watch: {
        domainSelected() {
            this.setButtonStatus();
        },
    },
    computed: {
        isCheckAvailability() {
            return !!this.domain.name.length;
        },
        domainSelected() {
            const domains = [];
            if (!this.domain.info) return domains;
            for (const domain of this.domain.info) {
                if (domain.selected) {
                    domains.push(domain);
                }
            }
            return domains;
        },
    },
    methods: {
        setButtonStatus() {
            const status = !this.domainSelected.length ? 'disabled' : undefined;
            this.options.setButtonState('NEXT', status);
        },
        domainPrice(domain) {
            if (!domain || !domain.prices) return undefined;
            return domain.prices[0];
        },
        getPrice(value) {
            return new Intl.NumberFormat('fr', {
                style: 'currency',
                currency: 'EUR',
            }).format(value);
        },
        async updateDomainName(domainName) {
            this.domain.name = domainName;
        },
        async checkDomainName() {
            try {
                this.loading = true;
                this.domain.info = undefined;
                this.domain.error = undefined;
                const result = await services.api.design.domainCheck(this.domain.name);
                if (result.data) {
                    for (const domain of result.data) domain.selected = false;
                    this.domain.info = result.data;
                }
            } catch (err) {
                this.domain.error = 'Domain not found';
                wwLib.wwLog.error(err);
            }
            this.loading = false;
        },
        async beforeNext() {
            this.options.setLoadingStatus(true);
            try {
                await services.api.design.domainAdd(this.options.data.websiteId, this.domainSelected);
                wwLib.wwNotification.open({
                    text: {
                        en: 'The domain names you have selected will be added to this project within 48h.',
                        fr: 'Les noms de domaine que vous avez sélectionnés seront ajoutés à ce projet dans les 48h.',
                    },
                    color: 'green',
                });
                return true;
            } catch (err) {
                wwLib.wwLog.error(err);
                wwLib.wwNotification.open({
                    text: {
                        en: '<b>An error occured. Please try later or contact the support.</b> ',
                        fr: "<b>Une erreur est survenue. Veuillez essayer plus tard ou contacter l'équipe.</b>",
                    },
                    color: 'red',
                });
            }
            this.options.setLoadingStatus(false);
            return false;
        },
    },
    mounted() {
        // this.options.result.addDomain = {};
        this.setButtonStatus();
    },
};
</script>

<style lang="scss" scoped>
.ww-popup-website-domain-buy {
    width: 100%;
    display: flex;
    flex-direction: column;
    .domain-search {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: var(--ww-spacing-03);
        &-input {
            width: 100%;
        }
        &-button {
            margin-left: var(--ww-spacing-02);
        }
    }
    .domain-loader {
        margin: 0 auto;
    }
    .domain-info {
        text-align: center;
        width: 100%;
        margin-top: var(--ww-spacing-04);
        .domain-elem {
            display: flex;
            align-items: center;
            background: var(--ww-color-dark-200);
            border-left: var(--ww-spacing-01) solid transparent;
            border-radius: var(--ww-border-radius-01);
            padding: var(--ww-spacing-03) var(--ww-spacing-04);
            margin: var(--ww-spacing-04) 0;
            &.available {
                border-left: var(--ww-spacing-01) solid var(--ww-color-green-600);
            }
            &.unavailable {
                border-left: var(--ww-spacing-01) solid var(--ww-color-red-600);
            }
            .taken {
                margin-left: auto;
                color: var(--ww-color-red-600);
            }
            .domain-price {
                margin-left: auto;
                margin-right: var(--ww-spacing-02);
                .discount {
                    text-decoration: line-through;
                }
            }
        }
        .more-info {
            padding: var(--ww-spacing-04) 0;
            color: var(--ww-color-blue-500);
        }
    }
    .domain-error {
        margin: var(--ww-spacing-04) auto;
        color: var(--ww-color-red-500);
    }
}
</style>
