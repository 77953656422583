<template>
    <div class="ww-popup-form ww-scroll-bar">
        <div class="fields-container">
            <div class="field-container" v-for="(field, index) in d_fields" :key="index">
                <div class="field" v-if="field.type == 'text'">
                    <label class="publish__label caption-s" for="commit">
                        {{ field.label[$i18n.locale] }}
                    </label>
                    <input
                        type="text"
                        class="publish__input ww-editor-input -large caption-m"
                        name="name"
                        @change="checkField(field, $event)"
                        v-model="options.result[field.key]"
                        :placeholder="field.label[$i18n.locale].split(':')[0]"
                    />

                    <div class="desc" v-if="field.desc" v-html="field.desc[$i18n.locale]"></div>
                    <div class="error" v-if="field.error" v-html="field.error[$i18n.locale]"></div>
                </div>
                <div class="field" v-else-if="field.type == 'textarea'">
                    <div class="label">{{ field.label[$i18n.locale] }}</div>
                    <div class="desc" v-if="field.desc" v-html="field.desc[$i18n.locale]"></div>
                    <textarea
                        class="textarea"
                        v-model="options.result[field.key]"
                        @change="checkField(field, $event)"
                        :color="field.error ? 'red' : field.color || 'green'"
                        :style="field.style"
                    ></textarea>
                    <div class="error" v-if="field.error" v-html="field.error[$i18n.locale]"></div>
                </div>
                <div class="field" v-else-if="field.type == 'color'">
                    <div class="label">{{ field.label[$i18n.locale] }}</div>
                    <div class="desc" v-if="field.desc" v-html="field.desc[$i18n.locale]"></div>
                    <wwManagerColorSelect
                        class="color"
                        v-model="options.result[field.key]"
                        :style="field.style"
                        @change="$forceUpdate()"
                    ></wwManagerColorSelect>
                </div>
                <div class="field" v-else-if="field.type == 'shadow'">
                    <div class="label">{{ wwManagerLang.getText(field.label) }}</div>
                    <div class="desc" v-if="field.desc" v-html="wwManagerLang.getText(field.desc)"></div>
                    <wwManagerShadow
                        v-model="options.result[field.key]"
                        :style="field.style"
                        @change="$forceUpdate()"
                    ></wwManagerShadow>
                </div>
                <div class="field" v-else-if="field.type == 'image'">
                    <div class="label">{{ field.label[$i18n.locale] }}</div>
                    <div class="desc" v-if="field.desc" v-html="field.desc[$i18n.locale]"></div>
                    <div class="image">
                        <button class="ww-editor-button -primary -green image-select" @click="selectImage(field.key)">
                            Select image
                        </button>
                        <img class="preview" :src="options.result[field.key]" />
                    </div>
                </div>
                <div class="field" v-else-if="field.type == 'select'">
                    <div class="label">{{ field.label[$i18n.locale] }}</div>
                    <div class="desc" v-if="field.desc" v-html="field.desc[$i18n.locale]"></div>
                    <div class="select">
                        <wwManagerSelect
                            class="select"
                            :options="field.options"
                            :value="field.value"
                            @change="selectValue(field, $event)"
                        ></wwManagerSelect>
                    </div>
                </div>
                <div class="field" v-else-if="field.type == 'radio'">
                    <div class="label">{{ field.label[$i18n.locale] }}</div>
                    <div class="desc" v-if="field.desc" v-html="field.desc[$i18n.locale]"></div>
                    <div class="radio">
                        <wwManagerRadio :value="field.value" @change="selectValue(field, $event)"></wwManagerRadio>
                    </div>
                </div>
                <div class="field" v-else-if="field.type == 'page'">
                    <div class="label">{{ field.label[$i18n.locale] }}</div>
                    <div class="desc" v-if="field.desc" v-html="field.desc[$i18n.locale]"></div>
                    <div class="select">
                        <wwManagerSelect
                            class="select"
                            :options="field.options"
                            :value="field.value"
                            @change="selectValue(field, $event)"
                        ></wwManagerSelect>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'wwPopupForm',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
        active: Boolean,
    },
    data() {
        return {
            wwManagerLang: wwLib.wwManagerLang,
            d_fields: null,
        };
    },
    computed: {},
    watch: {
        d_fields: {
            deep: true,
            handler() {
                wwLib.wwLog.log('WATCH');
            },
        },
    },
    methods: {
        async init() {
            this.d_fields = _.cloneDeep(this.options.storyData.fields);

            for (const field of this.d_fields) {
                if (field.value) {
                    this.options.result[field.key] = field.value;
                } else if (field.valueData) {
                    let value = this.options.data;
                    let splitted = field.valueData.split('.');
                    for (let i = 0; i < splitted.length; i++) {
                        if (typeof value[splitted[i]] !== 'undefined') {
                            value = value[splitted[i]];
                        } else {
                            value = '';
                            break;
                        }
                    }

                    this.options.result[field.key] = value;
                    field.value = value;
                } else {
                    this.options.result[field.key] = '';
                    field.value = '';
                }

                if (field.validation) {
                    await this.checkField(field, field.value, true);
                }

                if (field.type == 'page') {
                    let selectedPageId = null;
                    for (const page of wwLib.wwWebsiteData.getPages()) {
                        if (field.value == page.id || field.value == page.name) {
                            selectedPageId = page.id;
                            field.value = page.id;
                            break;
                        }
                    }

                    field.options = {
                        type: 'text',
                        values: [
                            {
                                value: null,
                                default: selectedPageId ? false : true,
                                text: {
                                    en: 'None',
                                    fr: 'Aucune',
                                },
                            },
                        ],
                    };

                    for (const page of wwLib.wwWebsiteData.getPages()) {
                        field.options.values.push({
                            value: page.id,
                            default: selectedPageId == page.id,
                            text: {
                                en: page.name,
                                fr: page.name,
                            },
                        });
                    }
                }
            }

            this.$forceUpdate();
        },

        async selectImage(fieldKey) {
            try {
                let options = {
                    firstPage: 'IMAGE_SELECT',
                };

                const result = await wwLib.wwPopups.open(options);
                if (result.image) {
                    this.options.result[fieldKey] = result.image;
                    this.$forceUpdate();
                }
            } catch (error) {
                wwLib.wwLog.error(error);
            }
        },

        selectValue(field, value) {
            field.value = value;
            this.options.result[field.key] = value;
            this.$forceUpdate();
        },

        async checkField(field, value, first) {
            if (field.validation) {
                let validationOk = true;
                if (field.validation.regex) {
                    let regex = new RegExp(field.validation.regex);
                    validationOk = regex.test(value);
                } else if (field.validation.function && typeof field.validation.function === 'function') {
                    validationOk = field.validation.function(value);
                    if (validationOk.then) {
                        validationOk = await validationOk;
                    }
                }

                if (validationOk) {
                    field.error = null;
                    field.ok = true;
                    this.options.setButtonState('NEXT', null);
                } else {
                    if (!first) {
                        field.error = field.validation.message || {
                            en: 'Error',
                            fr: 'Erreur',
                        };
                    }
                    field.ok = false;

                    this.options.setButtonState('NEXT', 'disabled');
                }

                this.$forceUpdate();
            }
        },

        beforeNext() {
            for (const field of this.d_fields) {
                if (field.ok === false) {
                    this.checkField(field, field.value);
                    return false;
                }
            }
            return true;
        },
    },
    mounted() {
        this.init();
    },
    created() {},
    beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.ww-popup-form {
    width: 100%;
    display: flex;
    justify-content: center;

    .fields-container {
        width: 100%;

        @media (min-width: 768px) {
            width: 500px;
        }

        @media (min-width: 992px) {
            width: 600px;
        }

        .field-container {
            width: 100%;
            margin: 20px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .field {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .publish {
                    &__label {
                        display: flex;
                        font-weight: 500;
                        color: var(--ww-color-dark-500);
                        margin-bottom: var(--ww-spacing-01);
                        &-required {
                            margin-left: auto;
                            color: var(--ww-color-dark-400);
                        }
                    }
                    &__input {
                        margin-bottom: var(--ww-spacing-03);
                    }
                }

                .label {
                    font-size: 1.2rem;
                    margin-bottom: 10px;
                }

                .desc {
                    color: var(--ww-color-dark-500);
                    font-size: 0.8rem;
                    margin-bottom: 10px;
                }

                .error {
                    color: var(--ww-color-red-500);
                    font-size: 0.8rem;
                    margin-bottom: 10px;
                }

                .textarea,
                .text {
                    width: 100%;
                }

                .textarea {
                    height: 200px;
                }

                .image {
                    display: flex;
                    align-items: center;

                    .preview {
                        margin-left: 20px;
                        width: 100px;
                        height: auto;
                    }
                }
            }
        }
    }
}
</style>
