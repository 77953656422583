<template>
    <div class="ww-popup-website-domain-connect">
        <div class="faq-title caption-m">Steps:</div>
        <ul>
            <li class="faq caption-m">
                Once your domain name is added, we will give you the records so you can add them in your DNS.
                <br />
                <span
                    >If you don’t know how to do this, take a look at
                    <a
                        href="https://docs.gandi.net/en/domain_names/common_operations/dns_records.html"
                        target="_blank"
                        class="link"
                        >this short documentation</a
                    >.
                </span>
            </li>
            <li class="faq caption-m">
                Once your DNS records are added, weweb.io takes care of doing the redirection automatically.
                <br />
                <span>This usually takes from <b>15 minutes to 3 hours</b>. </span>
            </li>

            <li class="faq caption-m">
                When the site goes online, it will be published from the content currently on your editor.
                <br />
                <span>Make sure the content is ready to be seen!</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'wwPopupWebsiteDomainConnectHelp',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
};
</script>

<style scoped lang="scss">
.ww-popup-website-domain-connect {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;
    .domain-connect {
        &__label {
            display: flex;
            font-weight: 500;
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
            &-required {
                margin-left: auto;
                color: var(--ww-color-dark-400);
            }
        }
        &__input {
            margin-bottom: var(--ww-spacing-03);
        }
        &__error {
            color: var(--ww-color-red-500);
        }
    }

    .faq-title {
        margin-top: var(--ww-spacing-02);
    }
    .faq {
        margin-bottom: var(--ww-spacing-02);

        span {
            color: var(--ww-color-dark-400);
        }
    }

    .link {
        color: var(--ww-color-blue-500);
        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
