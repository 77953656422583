import wwPopupWorkspaceInvite from './wwPopupWorkspaceInvite.vue';
import wwPopupWorkspaceCreate from './wwPopupWorkspaceCreate.vue';
import wwPopupWorkspaceDelete from './wwPopupWorkspaceDelete.vue';
import wwPopupWorkspaceContactUs from './wwPopupWorkspaceContactUs.vue';
import wwPopupWorkspaceMessageSent from './wwPopupWorkspaceMessageSent.vue';
import wwPopupWorkspacePurchaseSeats from './wwPopupWorkspacePurchaseSeats.vue';

export default function install(Vue) {
    Vue.component('wwPopupWorkspaceInvite', wwPopupWorkspaceInvite);
    Vue.component('wwPopupWorkspaceCreate', wwPopupWorkspaceCreate);
    Vue.component('wwPopupWorkspaceDelete', wwPopupWorkspaceDelete);
    Vue.component('wwPopupWorkspaceContactUs', wwPopupWorkspaceContactUs);
    Vue.component('wwPopupWorkspaceMessageSent', wwPopupWorkspaceMessageSent);
    Vue.component('wwPopupWorkspacePurchaseSeats', wwPopupWorkspacePurchaseSeats);
}
