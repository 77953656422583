window.wwNotificationSelf = null;

export default {
    openNotification: [],
    init() {
        window.wwNotificationSelf = this;
    },
    /*=============================================m_ÔÔ_m=============================================\
      OPEN
    \================================================================================================*/
    open(options) {
        options.text = options.text || {
            en: 'Notification',
            fr: 'Notification',
        };
        options.duration = options.duration || 5000;
        options.color = options.color || 'grey';

        options.id = Math.random();

        options.close = function () {
            if (window.wwNotificationSelf.openNotification.indexOf(options) !== -1) {
                window.wwNotificationSelf.openNotification.splice(
                    window.wwNotificationSelf.openNotification.indexOf(options),
                    1
                );
                //            return resolve(result);
            }
        };
        setTimeout(function () {
            options.close();
        }, options.duration);

        window.wwNotificationSelf.openNotification.push(options);
        return options;
    },
};
