<template>
    <div id="app">
        <!-- ROUTER -->
        <transition name="fade" mode="out-in">
            <router-view id="router" :name="componentRouterName" />
        </transition>

        <!-- MAINTENANCE POPUP -->
        <wwMaintenancePopup />

        <!-- POPUPS -->
        <transition-group name="popup" tag="div" class="popups">
            <wwPopup
                v-for="wwpopup in openPopups"
                :key="wwpopup.id"
                :options="wwpopup.options"
                :close="wwpopup.close"
            ></wwPopup>
        </transition-group>

        <!-- MODALS -->
        <transition-group name="modal" tag="div" class="modals">
            <wwModal
                v-for="wwmodal in openModals"
                :key="wwmodal.id"
                :options="wwmodal.options"
                :close="wwmodal.close"
            ></wwModal>
        </transition-group>

        <!-- wwNotification -->
        <transition-group name="notifications" tag="div" class="ww-notifications">
            <wwNotification v-for="wwnotif in openNotification" :key="wwnotif.id" :options="wwnotif"></wwNotification>
        </transition-group>

        <!-- wwPopupSelect -->
        <transition-group name="popup-select" tag="div" class="popup-selects">
            <wwPopupSelect
                v-for="wwpopupselect in openPopupSelects"
                :key="wwpopupselect.id"
                :options="wwpopupselect.options"
                :close="wwpopupselect.close"
            ></wwPopupSelect>
        </transition-group>

        <!-- wwLoader -->
        <wwLoader :loading="fullLoading"></wwLoader>
    </div>
</template>

<script>
import wwMaintenancePopup from '@/components/elements/wwMaintenancePopup.vue';
import wwPopup from '@/components/elements/wwPopup.vue';
import wwPopupSelect from '@/components/elements/wwPopupSelect.vue';
import wwModal from '@/components/elements/wwModal.vue';
import wwNotification from '@/components/elements/wwNotification.vue';
import wwLoader from '@/components/elements/wwLoader.vue';

import { mapGetters } from 'vuex';

export default {
    name: 'App',
    components: {
        wwMaintenancePopup,
        wwPopup,
        wwModal,
        wwNotification,
        wwPopupSelect,
        wwLoader,
    },
    data() {
        return {
            openPopups: wwLib.wwPopups.openPopups,
            openModals: wwLib.wwModals.openModals,
            openNotification: wwLib.wwNotification.openNotification,
            openPopupSelects: wwLib.wwPopupSelects.openPopupSelects,
        };
    },
    computed: {
        ...mapGetters({
            fullLoading: 'v2/getFullPageLoading',
        }),
        componentRouterName() {
            if (!this.$route) return 'guest';
            return this.$route.matched.some(record => record.meta.requiresAuth) ? 'auth' : 'guest';
        },
    },
    async mounted() {
        wwLib.wwAssets.addDashboardSvgIcons();
    },
};
</script>

<style lang="scss">
#app {
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    display: flex;

    .popups {
        position: relative;
        z-index: 105;
    }

    .popup-selects {
        position: relative;
        z-index: 106;
    }

    .modals {
        position: relative;
        z-index: 107;
    }

    .popup-enter-active,
    .popup-leave-active,
    .popup-select-enter-active,
    .popup-select-leave-active,
    .modal-enter-active,
    .modal-leave-active {
        transition: all 0.3s;
    }
    .popup-enter, .popup-leave-to, /* .list-leave-active below version 2.1.8 */
    .modal-enter, .modal-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: scale(0.9);
    }

    .popup-select-enter, .popup-select-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: scale(1.1);
    }

    .notifications-enter-active,
    .notifications-leave-active {
        transition: all 0.3s cubic-bezier(0.25, 0.25, 0.145, 1.305);
    }

    .notifications-enter, .notifications-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateX(-100%);
    }
    .ww-notifications {
        position: absolute;
        left: var(--ww-spacing-04);
        bottom: var(--ww-spacing-04);
        min-width: 300px;
        z-index: 1000;
    }

    .fade-enter {
        opacity: 0;
    }
    .fade-enter-active {
        transition: opacity 0.2s ease;
    }
    .fade-leave,
    .fade-leave-active {
        transition: opacity 0.2s ease;
        opacity: 0;
    }
}
</style>
