<template>
    <div class="loader" :class="{ loading: loading, block: block }">
        <wwLoaderSmall />
    </div>
</template>

<script>
export default {
    props: {
        loading: [String, Boolean],
        block: [String, Boolean],
    },
};
</script>

<style lang="scss" scoped>
.loader {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    align-items: center;
    justify-content: center;
    z-index: 1;
    &.loading {
        display: flex;
    }

    &.block {
        position: initial;
    }
}
</style>
