<template>
    <div class="ww-popup-website-unpublish ww-scroll-bar">
        <div class="description">
            <div>
                This will unpublish the
                <b>{{ options.data.design.name }}</b> project
            </div>
            <p>
                Please type <b>{{ options.data.design.name }}</b> to confirm.
            </p>
        </div>
        <label class="website__label caption-s" for="website-name">
            {{ $t('websites.settings.websiteName') }}
            <span class="website__label-required"> {{ $t('utils.required') }}</span>
        </label>
        <input
            type="text"
            class="website__input ww-editor-input -large caption-m"
            name="website-name"
            v-model="userInput"
            :placeholder="$t('utils.name')"
        />
    </div>
</template>

<script>
export default {
    name: 'wwPopupWebsiteUnpublish',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
        active: Boolean,
    },
    data() {
        return {
            userInput: '',
        };
    },
    watch: {
        userInput() {
            this.setButtonState();
        },
    },
    computed: {
        isValid() {
            if (!this.userInput) return false;
            return this.options.data.design.name.trim() === this.userInput.trim();
        },
    },
    methods: {
        setButtonState() {
            if (this.isValid) this.options.setButtonState('UNPUBLISH', 'ok');
            else this.options.setButtonState('UNPUBLISH', 'disabled');
        },
        async unpublishWebsite() {
            try {
                await axios.post(`${process.env.VUE_APP_API_URL}/design/${this.options.data.design.id}/unpublish`);

                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Project successfully unpublished</b> ',
                        fr: '<b>Publication de projet supprimé avec succès</b>',
                    },
                    color: 'green',
                    duration: '5000',
                });

                this.$router.replace(
                    `/workspaces/${this.options.data.workspace.id}/projects/${this.options.data.design.id}/deployments`
                );
            } catch (err) {
                wwLib.wwLog.error(err);
                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Error while unpublishing the project</b>',
                        fr: '<b>Erreur lors de la suppression de la publication du projet</b>',
                    },
                    color: 'red',
                    duration: '5000',
                });
            }
        },
        async beforeNext() {
            this.options.setLoadingStatus(true);
            await this.unpublishWebsite();
            this.options.setLoadingStatus(false);
        },
    },
    mounted() {
        this.setButtonState();
    },
};
</script>

<style lang="scss" scoped>
.ww-popup-website-unpublish {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;

    .description {
        .tooltip {
            padding: var(--ww-spacing-02);
            border-radius: var(--ww-spacing-01);
            background-color: var(--ww-color-red-100);
        }
    }
    .website {
        &__label {
            display: flex;
            font-weight: 500;
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
            &-required {
                margin-left: auto;
                color: var(--ww-color-dark-400);
            }
        }
        &__row {
            display: flex;
            align-items: center;
        }
        &__input {
            width: 100%;
            margin-bottom: var(--ww-spacing-03);
            &-radio {
                max-width: 50%;
            }
        }
        &__loader {
            transform: scale(0.8);
        }
        &__error {
            margin: var(--ww-spacing-04) auto;
            color: var(--ww-color-red-500);
        }
        &__lang-selector {
            margin-bottom: var(--ww-spacing-03);
        }
    }
}
</style>
