<template>
    <div class="sign-up-workspace">
        <div class="container">
            <form class="form" @submit.prevent="signUp">
                <label class="auth-label" for="email">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    class="auth-input ww-editor-input -large"
                    v-model="email"
                    placeholder="name@company.com"
                    required
                    autofocus
                    autocomplete="email"
                    aria-label="Email"
                    disabled
                />
                <label class="auth-label" for="name">Name</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    class="auth-input ww-editor-input -large"
                    v-model="name"
                    placeholder="John Doe"
                    required
                    autocomplete="name"
                    aria-label="Name"
                    disabled
                />
                <label class="auth-label" for="new-password">Password</label>
                <input
                    type="password"
                    id="new-password"
                    name="new-password"
                    class="auth-input ww-editor-input -large"
                    v-model="password"
                    placeholder="••••••••••••"
                    required
                    autocomplete="new-password"
                    aria-label="New password"
                />
                <label class="auth-label" for="confirm-password">Confirm password</label>
                <input
                    type="password"
                    id="confirm-password"
                    name="confirm-password"
                    class="auth-input ww-editor-input -large"
                    v-model="confirmPassword"
                    placeholder="••••••••••••"
                    required
                    autocomplete="new-password"
                    aria-label="Confirm password"
                />
                <div v-if="error" class="error-message label-s">{{ error }}</div>
                <button class="submit-button ww-editor-button -primary" type="submit">
                    <span v-if="!isLoading">Sign up</span>
                    <span v-else>
                        <wwVeryLittleLoader />
                    </span>
                </button>
                <span class="rgpd label-xs">
                    By signing up, you agree to our
                    <a class="ww-editor-link" href="//www.weweb.io/terms-and-conditions/" target="_blank">
                        General Terms & Conditions
                    </a>
                    <br />
                    and to our
                    <a class="ww-editor-link" href="//www.weweb.io/privacy-policy/" target="_blank"> Privacy Policy </a>
                </span>
            </form>
            <div class="bottom-action label-button">
                <router-link class="ww-editor-link" to="/sign-in">Back to sign-in</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import services from '@/services';
import wwVeryLittleLoader from '@/components/elements/wwVeryLittleLoader.vue';
const PASSWORD_REGEX =
    /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/gm;

export default {
    name: 'SignUpWorkspace',
    components: { wwVeryLittleLoader },
    data() {
        return {
            email: '',
            name: '',
            password: '',
            confirmPassword: '',
            token: '',
            error: '',
            isLoading: false,
        };
    },
    computed: {
        match() {
            return this.password === this.confirmPassword;
        },
    },
    methods: {
        async signUp() {
            if (this.isLoading) return;
            if (!this.match) return (this.error = 'Please enter the same password in both inputs.');
            if (this.password.length > 72) return (this.error = 'Password must not contain more than 72 characters.');
            if (!this.password.match(PASSWORD_REGEX)) {
                this.error =
                    'Password must contain at least 8 characters including 1 lowercase, 1 uppercase, 1 number, 1 special character.';
                return;
            }
            this.error = '';
            this.isLoading = true;
            try {
                await services.api.user.signUpUserToOrganization(this.password, this.token);
                this.isLoading = false;
                await this.signIn();
            } catch (err) {
                switch ((err.graphQLErrors && err.graphQLErrors[0] && err.graphQLErrors[0].message) || true) {
                    case '404: Not Found':
                        this.error = 'Invalid token, please ask your admin to resend an invitation.';
                        break;
                    default:
                        this.error =
                            'Unknown error, please contact support@weweb.io. This can be caused by VPN or an enterprise network.';
                }
                wwLib.wwLog.error(err);
                this.isLoading = false;
            }
        },
        async signIn() {
            if (this.isLoading) return;
            this.isLoading = true;
            try {
                await services.api.user.loginManager(this.email, this.password);

                this.$router.replace({ name: 'sign-up-after' });
                this.isLoading = false;
            } catch (err) {
                this.error =
                    'Unknown error, please contact support@weweb.io. This can be caused by VPN or an enterprise network.';
                wwLib.wwLog.error(err);
            }
            this.isLoading = false;
        },
    },
    mounted() {
        const { email, name, token } = this.$route.query;
        if (!email || !name || !token) this.$router.replace({ name: 'sign-up' });
        this.email = email.replace(/ /g, '+');
        this.name = name;
        this.token = token;
    },
};
</script>

<style scoped lang="scss">
.sign-up-workspace {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: var(--ww-spacing-04);
            width: 100%;
            .auth-label {
                margin-bottom: var(--ww-spacing-02);
                font-size: var(--ww-font-size-03);
                color: var(--ww-color-dark-500);
            }
            .auth-input {
                width: 100%;
                margin-bottom: var(--ww-spacing-03);
            }
            .error-message {
                width: 100%;
                text-align: center;
                color: var(--ww-color-red-500);
            }
            .submit-button {
                width: 100%;
                margin-top: var(--ww-spacing-03);
            }
            .rgpd {
                width: 100%;
                text-align: center;
                margin-top: var(--ww-spacing-02);
                color: var(--ww-color-dark-500);
            }
        }
        .bottom-action {
            margin: var(--ww-spacing-03) 0;
        }
    }
}
</style>
