export default {
    en: {
        organizations: 'Organizations',
        organization: 'Organization',
        createOrganization: 'Create',
        search: 'Organization name',
        ownedBy: 'owned by',
        members: {
            name: 'Members',
            search: 'Name, email...',
            roles: {
                owner: 'Owner',
                admin: 'Admin',
                member: 'Member',
            },
            table: {
                role: 'role',
                actions: 'Actions',
                noMembers: 'No members',
            },
            remove: 'remove',
        },
        invitations: {
            name: 'Invitations',
            inviteMember: 'Invite members',
            search: 'Name, email...',
            status: {
                name: 'status',
                pending: 'pending',
                accepted: 'accepted',
            },
            table: {
                role: 'Role',
                invitedBy: 'Invited By',
                actions: 'Actions',
                joined: 'Joined',
                noInvitations: 'No invitations',
            },
            resend: 'Resend',
            revoke: 'Revoke',
            inviteMessage: 'Write a few words for your new members!',
        },
        settings: {
            name: 'Settings',
            informations: {
                name: 'Information',
                organizationName: 'Name',
                update: 'Update',
            },
            ownership: {
                name: 'Ownership',
                owner: 'Owner',
                giveOwnership: 'Give ownership',
            },
            dangerZoneTitle: 'Danger zone',
            deleteDesc: 'This action will permanently delete this workspace',
            deleteButton: 'DELETE WORKSPACE',
        },
        billing: {
            billingPlan: 'Billing plan',
            summary: ['This workspace has', 'projects and', 'members or pending invitations.'],
            projectDetails: 'View projects details',
            views: 'views,',
            pages: 'pages,',
            dataCached: 'cached records and',
            additionalDataCached: 'additional cached records.',
            currently: '{name} is currently on the',
            plan: 'plan.',
            upgradeWorkspace: 'Upgrade this workspace',
            upgrade: 'Upgrade',
            needMore: 'Need more ?',
            contactUs: 'Contact us',
            payment: 'Payment',
            addPayment: 'Add payment details',
            updatePayment: 'Update payment details',
            removeCard: 'Remove card',
            history: 'Billing history',
            created: 'Workspace created the',
            paymentTitles: {
                type: 'Card type',
                number: 'Number',
                expiry: 'Expiry',
            },
            historyTitles: {
                date: 'Date',
                status: 'Status',
                amount: 'Amount',
            },
            noPaymentMethods: 'No payment methods',
            noHistory: 'No payments to date.',
            viewInvoice: 'View Invoice',
        },
        plans: {
            back: 'Back to Billing',
            choosePlan: 'Choose a plan',
            current: 'Current plan',
            upgrade: 'Upgrade',
            downgrade: 'Downgrade',
            features: {
                liveProjects: 'Live projects',
                support: 'Support',
                customDomains: 'Custom domains',
                views: 'Monthly visits',
                workspaceMembers: 'Members',
                totalUserFileSize: 'File upload',
                ipWhitelist: 'IP Whitelisting',
                staging: 'Staging environment',
                backups: 'Backups',
                auth: 'Authentication',
                authRoles: 'Advanced roles & permissions',
            },
            usage: 'Usage',
            featuresText: 'Features',
            yearly: 'Pay yearly',
            monthly: 'Pay monthly',
            month: 'month',
            saveText: 'Get 2 months free !',
        },
    },
};
