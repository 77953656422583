export default {
    en: {
        sourcesCodes: 'Coded components',
        createSourcesCodes: 'Import component',
    },
    fr: {
        sourcesCodes: 'Coded components',
        createSourcesCodes: 'Importer un composant',
    },
};
