import wwPopupList from './wwPopupList.vue';
import wwPopupForm from './wwPopupForm.vue';
import wwPopupMessage from './wwPopupMessage.vue';
// Workspace
import workspacePlugin from './workspace';
// Website
import websitePlugin from './website';
// Source Code
import sourceCodePlugin from './sourceCode';

export default {
    install(Vue) {
        Vue.component('wwPopupList', wwPopupList);
        Vue.component('wwPopupForm', wwPopupForm);
        Vue.component('wwPopupMessage', wwPopupMessage);
        // Workspace
        Vue.use(workspacePlugin);
        // Website
        Vue.use(websitePlugin);
        // Source Code
        Vue.use(sourceCodePlugin);
    },
};
