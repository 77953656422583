<template>
    <div class="ww-popup-website-publish-logs__preview p-3 caption-m ww-border-radius-01">
        <div v-if="deploy">{{ deploy.logs }}</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'wwPopupWebsitePublishLogs',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            websiteId: undefined,
            deployId: undefined,
        };
    },
    computed: {
        ...mapGetters({
            user: 'v2/getUser',
            deploys: 'v2/getDesignDeploys',
        }),
        deploy() {
            return this.deploys.find(elem => elem.id === this.deployId);
        },
    },
    created() {
        this.websiteId = this.options.data.websiteId;
        this.deployId = this.options.data.deployId;
    },
};
</script>

<style scoped lang="scss">
.ww-popup-website-publish-logs__preview {
    font-family: Menlo, Monaco, 'Roboto Mono', monospace;
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    white-space: pre-wrap;
    word-break: break-all;
    color: white;
    background: black;
    overflow: auto;
}
</style>
