<template>
    <div class="ww-scroll-bar">
        <wwManagerSearchBar
            class="search"
            placeholder="Search a workspace by UID"
            v-model="search"
        ></wwManagerSearchBar>
        <div class="search-result">
            <div v-if="isSearching" class="p-3 text-center text-blue-500">Searching...</div>
            <div v-else-if="workspace" class="workspace-result p-3">
                {{ workspace.name }}
                <span v-if="workspace.id === currentWorkspace.id" class="ww-editor-button -primary -dark small"
                    >Is owner</span
                >
                <span
                    v-else-if="localWorkspaces.some(work => work.id === workspace.id)"
                    class="ww-editor-button -primary -dark small"
                    >Added</span
                >
                <button v-else @click="addWorkspace(workspace)" class="ww-editor-button -primary">Add</button>
            </div>
            <div v-else-if="search && !workspace" class="p-3 text-center text-yellow-500">Workspace not found</div>
            <div v-else class="p-3 text-center label-m">Grant Access To</div>
        </div>
        <div class="workspaces-list ww-scroll-bar mt-2">
            <div class="workspace" v-for="workspace in availableWorkspaces" :key="workspace.id">
                <div class="flex flex-col">
                    <span class="label-l">{{ workspace.name }}</span>
                    <span class="label-s text-stale-500 mt-1" style="font-style: italic">{{ workspace.id }}</span>
                </div>
                <button @click="addWorkspace(workspace)" class="ww-editor-button -primary">Add</button>
            </div>
            <div class="workspace" v-for="workspace in localWorkspaces" :key="workspace.id">
                <div class="flex flex-col">
                    <span class="label-l">{{ workspace.name }}</span>
                    <span class="label-s text-stale-500 mt-1" style="font-style: italic">{{ workspace.id }}</span>
                </div>
                <button @click="removeWorkspace(workspace.id)" class="ww-editor-button -primary -red small">
                    Remove
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import services from '@/services';
export default {
    name: 'wwPopupSourceCodeWorkspaces',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            localWorkspaces: [],
            search: '',
            workspace: null,
            isSearching: false,
        };
    },
    computed: {
        ...mapGetters({
            user: 'v2/getUser',
            currentWorkspace: 'v2/getCurrentOrganization',
            organizations: 'v2/getOrganizations',
        }),
        currentWorkspaces() {
            return this.options?.data?.workspaces || [];
        },
        availableWorkspaces() {
            return this.organizations
                .filter(
                    org => !this.localWorkspaces.some(work => work.id === org.id) && org.id !== this.currentWorkspace.id
                )
                .map(org => ({ id: org.id, name: org.name }));
        },
    },
    watch: {
        search(value) {
            if (!value) {
                this.workspace = null;
                return;
            }
            this.searchWorkspace(value);
        },
    },
    created() {
        this.localWorkspaces = [...this.currentWorkspaces];
    },
    methods: {
        async searchWorkspace(workspaceId) {
            this.isSearching = true;
            try {
                this.workspace = await services.api.workspace.getOrganizationInfo(workspaceId);
            } catch (error) {
                this.workspace = null;
            }
            this.isSearching = false;
        },
        async addWorkspace(workspace) {
            this.localWorkspaces.push(workspace);
            this.options.result.workspaces = [...this.localWorkspaces];
            this.workspace = null;
            this.search = '';
        },
        removeWorkspace(workspaceId) {
            this.localWorkspaces = this.localWorkspaces.filter(workspace => workspace.id !== workspaceId);
            this.options.result.workspaces = [...this.localWorkspaces];
        },
    },
};
</script>

<style lang="scss" scoped>
.workspaces-list {
    max-height: 300px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    > .workspace {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--ww-spacing-03) var(--ww-spacing-03);
        border-radius: var(--ww-border-radius-02);
        background-color: var(--ww-color-dark-200);
        border: 1px solid var(--ww-color-dark-300);
    }
}
.workspace-result {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--ww-border-radius-02);
    background-color: var(--ww-color-blue-100);
    border: 1px solid var(--ww-color-dark-300);
}
.search-result {
    min-height: 60px;
    display: flex;
    align-items: center;
    > * {
        width: 100%;
    }
}
</style>
