<template>
    <div>
        <div class="label-m my-4">
            Currently on Team plan with {{ members }}/{{ currentOrganization.features.members }} seats used.
            {{
                (currentOrganization.features.maxMembers || 1) < 100
                    ? `(${currentOrganization.features.maxMembers || 1} max)`
                    : ''
            }}
        </div>
        <div class="flex items-center">
            <input
                type="number"
                class="ww-editor-input -large w-100"
                min="1"
                :max="currentOrganization.features.maxMembers"
                v-model="seats"
                disabled
            />
            <button class="ww-editor-button -icon -large -secondary ml-1" @click="removeSeat">
                <wwEditorIcon class="ww-editor-button-icon text-stale-500" name="remove" large />
            </button>
            <button class="ww-editor-button -icon -large -secondary ml-1" @click="addSeat">
                <wwEditorIcon class="ww-editor-button-icon text-stale-500" name="add" large />
            </button>
        </div>
        <div class="caption-m my-4" v-if="seats > currentOrganization.features.members">
            You are adding {{ seats - currentOrganization.features.members }} seat(s), for a total of {{ seats }} seats
        </div>
        <div class="caption-m my-4" v-if="seats < currentOrganization.features.members">
            You are removing {{ currentOrganization.features.members - seats }} seat(s), for a total of
            {{ seats }} seats
        </div>
        <div class="ww-box border-red-500 label-l text-stale-900 p-4" v-if="seats < members">
            You need to remove members before removing seats.
        </div>
        <UpgradeCard
            v-if="seats > currentOrganization.features.maxMembers"
            title="Want to purchase more seats?"
            type="workspace"
            small
            hasPlan
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UpgradeCard from '@/components/elements/UpgradeCard.vue';
import stripe from '@/services/stripe.js';

export default {
    components: { UpgradeCard },
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            workspaceId: null,
            members: 1,
            seats: 1,
        };
    },
    computed: {
        ...mapGetters({
            currentOrganization: 'v2/getCurrentOrganization',
        }),
    },
    watch: {
        seats() {
            if (
                this.seats === this.currentOrganization.features.members ||
                this.seats > this.currentOrganization.features.maxMembers ||
                this.seats < this.members
            ) {
                this.options.setButtonState('NEXT', 'disabled');
            } else {
                this.options.setButtonState('NEXT', 'ok');
            }
        },
    },
    methods: {
        addSeat() {
            this.seats += 1;
        },
        removeSeat() {
            if (this.seats <= 1) return;
            this.seats -= 1;
        },
        async beforeNext() {
            this.options.setLoadingStatus(true);
            try {
                const { data } = await axios.post(
                    `${process.env.VUE_APP_API_URL.replace('/v1', '/v2')}/stripe/workspaces/${this.workspaceId}/seats`,
                    { quantity: this.seats }
                );
                await stripe.callback(data, this.workspaceId);
            } catch (err) {
                wwLib.wwLog.error(err);
            }
            this.options.setLoadingStatus(false);
        },
    },
    mounted() {
        this.workspaceId = this.options.data.workspaceId;
        this.members = this.options.data.membersCount;
        this.seats = this.currentOrganization.features.members;
    },
};
</script>

<style scoped lang="scss"></style>
