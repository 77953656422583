<template>
    <div class="ww-popup-source-code-type">
        <div class="action-box" @click="figma" v-if="user.beta.figma">
            <i class="action-box__icon fab fa-figma"></i>
            <div class="action-box__name label-l">Figma</div>
        </div>
        <div class="action-box" @click="github">
            <i class="action-box__icon fab fa-github"></i>
            <div class="action-box__name label-l">Github</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'wwPopupSourceCodeType',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    computed: {
        ...mapGetters({
            user: 'v2/getUser',
        }),
    },
    methods: {
        github() {
            this.$emit('next', 'CREATE_SOURCE_CODE_GITHUB');
        },
        figma() {
            this.$emit('next', 'CREATE_SOURCE_CODE_FIGMA');
        },
    },
};
</script>

<style scoped lang="scss">
.ww-popup-source-code-type {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .action-box {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        padding: var(--ww-spacing-05) 0;
        width: 45%;
        box-shadow: var(--ww-box-shadow-01);
        border-radius: var(--ww-border-radius-01);
        color: var(--ww-color-dark-700);
        border: 1px solid transparent;
        cursor: pointer;
        transition: all 0.3s ease;
        height: 175px;
        &__icon {
            font-size: 50px;
        }
        &:hover {
            border: 1px solid var(--ww-color-blue-500);
            background-color: var(--ww-color-blue-100);
            color: var(--ww-color-blue-500);
        }
    }
}
</style>
