<template>
    <div class="ww-popup-plan-delete ww-scroll-bar">
        <iframe :src="src" frameborder="0" width="100%" height="100%"></iframe>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
        active: Boolean,
    },
    computed: {
        ...mapGetters({
            user: 'v2/getUser',
        }),
        src() {
            return this.options.data.url;
        },
    },
    methods: {
        onMessage(event) {
            if (!event.data) return;
            if (event.data === 'FORM_COMPLETED') {
                this.close(true);
            }
            if (event.data === 'CANCELLED') {
                this.close(false);
            }
        },
        close(result) {
            wwLib.wwPopups.openPopups.find(popup => popup.options.firstPage === 'PLAN_DOWNGRADE')?.close(result);
        },
    },
    mounted() {
        window.addEventListener('message', this.onMessage);
    },
    unmounted() {
        window.removeEventListener('message', this.onMessage);
    },
};
</script>

<style lang="scss" scoped>
.ww-popup-plan-delete {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;

    .description {
        .tooltip {
            padding: var(--ww-spacing-02);
            border-radius: var(--ww-spacing-01);
            background-color: var(--ww-color-red-100);
        }
    }
    .website {
        &__label {
            display: flex;
            font-weight: 500;
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
            &-required {
                margin-left: auto;
                color: var(--ww-color-dark-400);
            }
        }
        &__input {
            width: 100%;
            margin-bottom: var(--ww-spacing-03);
            &-radio {
                max-width: 50%;
            }
        }
    }
}
</style>
