<template>
    <div class="ww-popup-website-create-tutorial ww-scroll-bar">
        <div class="message">Please wait while we're personalizing the tutorial project...</div>
        <wwLoader :loading="true" :block="true" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import services from '@/services';

export default {
    name: 'wwPopupWebsiteCreateTutorial',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    computed: {
        ...mapGetters({
            workspace: 'v2/getCurrentOrganization',
        }),
    },
    methods: {
        ...mapActions({
            fetchDesigns: 'v2/fetchDesigns',
        }),
        async createTutorial() {
            try {
                const templateId = '151922a4-e884-436e-a30a-ae6db30338e0';
                const projectName = 'Tutorial';
                const userflowQuery = '?userflow=c629b47b-1616-4af8-af3a-29f73ada5786';

                const { id } = await services.api.design.duplicateDesign(
                    this.workspace.id,
                    templateId,
                    projectName,
                    [{ lang: 'en', default: true }],
                    true
                );

                await this.fetchDesigns(this.workspace.id);
                if (id) window.open(`${process.env.VUE_APP_MANAGER_URL}/${id}${userflowQuery}`, '_blank');
            } catch (error) {
                wwLib.wwLog.error(error);
                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Error while creating the tutorial</b>',
                        fr: '<b>Erreur lors de la création du tutoriel</b>',
                    },
                    color: 'red',
                });
            }

            this.options.end();
        },
    },
    async mounted() {
        await this.createTutorial();
    },
};
</script>

<style lang="scss" scoped>
.ww-popup-website-create-tutorial {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .message {
        margin-bottom: var(--ww-spacing-04);
    }
}
</style>
