import axios from 'axios';
import lodash from 'lodash';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import moment from 'vue-moment';
import App from './App.vue';
import wwElements from './components/';
import i18n from './i18n';
import router from './router';
import store from './store/';
import wwLib from './wwLib';
import '@/assets/css';

if (window.top.location.hostname === window.self.location.hostname) {
    Vue.use(VueCookies);
    Vue.use(moment);
    Vue.use(wwElements);

    Vue.config.productionTip = false;

    // ADD AXIOS
    Vue.axios = axios;
    window.axios = axios;
    Vue.axios.defaults.withCredentials = true;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return axios;
            },
        },
        $http: {
            get() {
                return axios;
            },
        },
    });

    const vueApp = new Vue({
        store,
        router,
        i18n,
        render: h => h(App),
    });

    wwLib.init(i18n);
    window.wwLib = wwLib;
    window._ = lodash;

    vueApp.$mount('#app');

    axios.interceptors.response.use(
        response => response,
        async error => {
            let config = null;
            switch (error.response.data.code) {
                case 'WORKSPACE_DOWNGRADE_MEMBERS':
                    config = {
                        title: 'You cannot perform this action',
                        description:
                            'You have too many members to downgrade. Please remove members and/or invitations to downgrade.',
                        link: 'workspaces-members',
                        buttons: [{ text: { en: 'Go to members' }, color: '-primary', value: true, enter: true }],
                    };
            }
            if (config) {
                const confirm = await wwLib.wwModals.open({
                    title: { en: config.title },
                    text: { en: config.description },
                    buttons: config.buttons,
                });
                if (confirm) router.push({ name: config.link });
            }

            return Promise.reject(error);
        }
    );
}
