import router from '../router';

const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);

export default {
    async callback({ url, clientSecret }, workspaceId) {
        if (url) {
            return (window.location.href = url);
        } else if (clientSecret) {
            const res = await stripe.confirmCardPayment(clientSecret);
            if (res.error) {
                return await wwLib.wwModals.open({
                    title: { en: 'Payment failed' },
                    text: { en: res.error.message },
                    buttons: [{ text: { en: 'Close' }, color: '-primary', value: false, escape: true }],
                });
            }
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
        router.push({ name: 'payment-callback', query: { workspaceId } });
    },
};
