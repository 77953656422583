<template>
    <div class="ww-manager-click-menu" ref="">
        <div class="btn" :class="{ transparent: isTransparent }" @click="show($event)">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import wwManagerMenu from './wwManagerMenu.vue';
import Vue from 'vue';

export default {
    name: 'wwManagerDetailsButton',
    props: {
        actions: Array,
        item: [Boolean, String, Array, Object, Number],
        disabled: [Boolean, String],
        isTransparent: Boolean,
    },
    data() {
        return {
            wwManagerMenu: null,
            d_componentInstance: null,
        };
    },
    methods: {
        show(event) {
            if (this.d_componentInstance && this.d_componentInstance.$el) {
                this.close();
                event.preventDefault();
                event.stopPropagation();
                return;
            }

            this.d_componentInstance = new this.wwManagerMenu({
                propsData: {
                    actions: this.actions,
                    x: event.clientX - 10,
                    y: event.clientY + 10,
                },
            });

            this.d_componentInstance.$mount();
            document.querySelector('#app').append(this.d_componentInstance.$el);

            this.d_componentInstance.$on('runAction', this.runAction);
            this.d_componentInstance.$on('runMouseEnter', this.runMouseEnter);
            this.d_componentInstance.$on('runMouseLeave', this.runMouseLeave);
            setTimeout(() => {
                window.addEventListener('click', this.close);
            }, 1);
        },
        close() {
            if (this.d_componentInstance && this.d_componentInstance.$el) {
                this.d_componentInstance.close();

                setTimeout(() => {
                    if (this.d_componentInstance && this.d_componentInstance.$el) {
                        this.d_componentInstance.$el.remove();
                        this.d_componentInstance = null;
                    }
                }, 400);
            }

            window.removeEventListener('click', this.close);
        },
        runAction(action) {
            if (action && action.action && typeof action.action == 'function') {
                action.action(this.item ? this.item : undefined);
            }
            this.close();
        },
        runMouseEnter(action) {
            if (action && action.mouseenter && typeof action.mouseenter == 'function') {
                action.mouseenter(this.item ? this.item : undefined);
            }
        },
        runMouseLeave(action) {
            if (action && action.mouseleave && typeof action.mouseleave == 'function') {
                action.mouseleave(this.item ? this.item : undefined);
            }
        },
    },
    mounted() {
        this.wwManagerMenu = Vue.extend(wwManagerMenu);
    },
    beforeDestroy() {
        this.close();

        window.removeEventListener('click', this.close);
    },
};
</script>

<style scoped lang="scss">
.ww-manager-click-menu {
    position: relative;
    display: inline-block;

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: var(--ww-color-dark-600);
        background-color: var(--ww-color-white);
        padding: var(--ww-spacing-00) var(--ww-spacing-01);
        border-radius: 4px;
        border: 1px solid var(--ww-color-dark-200);

        i {
            font-size: 14px;
            color: var(--ww-color-blue-500);
        }

        &:hover {
            cursor: pointer;
        }

        &.transparent {
            background-color: transparent;
            border: none;
        }
    }

    .menu {
        z-index: 9999;
        position: absolute;
        background-color: var(--ww-color-white);
        border: 1px solid var(--ww-color-dark-200);
        box-shadow: 0px 2px 8px rgba(30, 35, 36, 0.12);
        border-radius: 8px;
        transition: transform 0.3s ease, opacity 0.3s ease;
        transform-origin: 100% 0%;
        transform: scale(0.5);
        opacity: 0;
        pointer-events: none;
        border-radius: 3px;
        text-align: left;
        overflow: hidden;

        &.up {
            top: auto;
            bottom: 50%;
            transform-origin: 100% 100%;
        }

        &.show {
            pointer-events: all;
            transform: scale(1);
            opacity: 1;
        }

        .action {
            padding: 10px;
            cursor: pointer;
            transition: background-color 0.2s ease;

            &:hover {
                background-color: #ececec;
            }

            &.red {
                color: var(--ww-color-red-500);
                &:hover {
                    color: white;
                    background-color: var(--ww-color-red-500);
                }
            }

            // &.orange {
            //     color: var(--ww-color-yellow-700);
            //     &:hover {
            //         color: white;
            //         background-color: var(--ww-color-yellow-700);
            //     }
            // }

            // &.blue {
            //     color: var(--ww-color-blue-400);
            //     &:hover {
            //         color: white;
            //         background-color: var(--ww-color-blue-400);
            //     }
            // }

            // &.green {
            //     color: var(--ww-color-green-500);
            //     &:hover {
            //         color: white;
            //         background-color: var(--ww-color-green-500);
            //     }
            // }

            // &.yellow {
            //     color: var(--ww-color-yellow-500);
            //     &:hover {
            //         color: white;
            //         background-color: var(--ww-color-yellow-500);
            //     }
            // }

            // &.pink {
            //     color: var(--ww-color-purple-500);
            //     &:hover {
            //         color: white;
            //         background-color: var(--ww-color-purple-500);
            //     }
            // }

            // &.grey {
            //     color: var(--ww-color-dark-600);
            //     &:hover {
            //         color: white;
            //         background-color: var(--ww-color-dark-600);
            //     }
            // }
        }
    }
}
</style>
