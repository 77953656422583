<template>
    <button
        class="ww-manager-radio"
        role="switch"
        :class="{ '-active': value, '-secondary': secondary }"
        :aria-checked="value ? 'true' : 'false'"
        @click="toggleValue()"
    >
        <div class="selector"></div>
    </button>
</template>

<script>
export default {
    name: 'wwManagerRadio',
    props: {
        value: { type: Boolean },
        secondary: { type: Boolean },
    },
    methods: {
        toggleValue() {
            this.$emit('input', !this.value);
            this.$emit('change', !this.value);
        },
    },
};
</script>

<style scoped lang="scss">
.ww-manager-radio {
    --ww-radio-selector-size: 1rem;
    --ww-radio-padding: var(--ww-spacing-01);
    --ww-radio-transition: all 0.3s ease;
    --ww-radio-border-radius: 2rem;
    --ww-radio-border-size: 0.1rem;

    --ww-radio-color-1: var(--ww-color-primary);
    --ww-radio-color-2: #ffff;

    &.-secondary {
        --ww-radio-color-1: var(--ww-color-secondary);
    }
    &:disabled {
        cursor: not-allowed;
        --ww-radio-color-1: var(--ww-color-gray-light);
    }

    // reset button style
    outline: none;
    border: none;
    padding: 0;
    box-sizing: content-box;

    position: relative;
    height: calc(var(--ww-radio-padding) * 2 + var(--ww-radio-selector-size));
    width: calc(var(--ww-radio-padding) * 4 + var(--ww-radio-selector-size) * 2);

    background-color: var(--ww-radio-color-2);
    border: var(--ww-radio-border-size) solid var(--ww-radio-color-1);
    border-radius: var(--ww-radio-border-radius);
    cursor: pointer;

    transition: var(--ww-radio-transition);

    .selector {
        position: absolute;
        top: var(--ww-radio-padding);
        left: var(--ww-radio-padding);

        width: var(--ww-radio-selector-size);
        height: var(--ww-radio-selector-size);

        background-color: var(--ww-radio-color-1);
        border-radius: var(--ww-radio-border-radius);
        transition: var(--ww-radio-transition);
    }

    &.-active {
        background-color: var(--ww-radio-color-1);
        .selector {
            left: calc(var(--ww-radio-selector-size) + var(--ww-radio-padding) * 3);
            background-color: var(--ww-radio-color-2);
        }
    }
}
</style>
