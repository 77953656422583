import gql from 'graphql-tag';

/*=============================================m_ÔÔ_m=============================================\
    Auth
\================================================================================================*/
export const GET_MAINTENANCE = gql`
    query GetMaintenance {
        getMaintenance {
            message
        }
    }
`;
