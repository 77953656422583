<template>
    <div class="guest -fullscreen" v-if="$route.meta.fullscreen">
        <router-view :key="$route.fullPath" />
    </div>
    <div class="guest" v-else>
        <div v-if="referralId" class="guest__card mb-4">
            <img v-if="isLoading" src="@/assets/img/building-animation.svg" class="mx-auto py-3" />
            <div v-else-if="error" class="label-l text-red-500">Referral not found.</div>
            <template v-else>
                <div class="paragraph-m text-stale-700">{{ referral.user.name }} wants you to try out WeWeb!</div>
                <div class="caption-m text-stale-500 mt-3">Build production-grade applications 10x faster.</div>
                <div class="border-green-500 bg-green-100 text-green-500 ww-border-radius-02 label-m py-3 mt-3">
                    Enjoy {{ referral.discountPercent }}% off on all price plans
                </div>
            </template>
        </div>
        <div class="guest__card">
            <div class="guest__logo">
                <svg width="125" height="55" viewBox="0 0 94 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.77993 10.8465C8.77993 10.5552 8.87957 10.3083 9.07858 10.1061C9.27758 9.90384 9.5203 9.80259 9.80727 9.80259C10.094 9.80259 10.3378 9.90384 10.536 10.1061C10.735 10.3083 10.8343 10.5552 10.8343 10.8465V16.4355C10.8343 16.9067 10.9223 17.3503 11.0993 17.7661C11.2763 18.1815 11.5139 18.5454 11.8117 18.86C12.1101 19.1745 12.4636 19.4214 12.8725 19.6004C13.2808 19.7806 13.7174 19.8697 14.1811 19.8697C14.6451 19.8697 15.0808 19.7806 15.4897 19.6004C15.898 19.4214 16.257 19.1745 16.5665 18.86C16.876 18.5454 17.1187 18.1815 17.2952 17.7661C17.4722 17.3503 17.5607 16.9067 17.5607 16.4355V6.67183C17.5607 6.38049 17.6603 6.13274 17.8585 5.93134C18.0575 5.72912 18.3005 5.62787 18.588 5.62787C18.8747 5.62787 19.1177 5.72912 19.3167 5.93134C19.5157 6.13274 19.6145 6.38049 19.6145 6.67183V16.4355C19.6145 17.1991 19.4712 17.9172 19.1842 18.591C18.8967 19.2636 18.5096 19.8482 18.0247 20.3409C17.5379 20.8356 16.9645 21.2282 16.3015 21.5195C15.6385 21.8117 14.9318 21.9573 14.1811 21.9573C13.2534 21.9573 12.4134 21.7504 11.6627 21.3347C10.9112 20.9192 10.2821 20.3641 9.77442 19.6683C9.28844 20.3641 8.66943 20.9192 7.91874 21.3347C7.16724 21.7504 6.32804 21.9573 5.40034 21.9573C4.64965 21.9573 3.94295 21.8117 3.27995 21.5195C2.61696 21.2282 2.04274 20.8356 1.55676 20.3409C1.07105 19.8482 0.690145 19.2636 0.414576 18.591C0.13792 17.9172 0 17.1991 0 16.4355V6.67183C0 6.38049 0.099368 6.13274 0.298375 5.93134C0.496839 5.72912 0.739557 5.62787 1.02707 5.62787C1.31404 5.62787 1.55676 5.72912 1.75604 5.93134C1.95505 6.13274 2.05442 6.38049 2.05442 6.67183V16.4355C2.05442 16.9067 2.14211 17.3503 2.3194 17.7661C2.4956 18.1815 2.73316 18.5454 3.03153 18.86C3.33018 19.1745 3.68285 19.4214 4.09173 19.6004C4.50006 19.7806 4.93635 19.8697 5.40034 19.8697C6.30605 19.8697 7.08498 19.5447 7.7363 18.8933C8.38816 18.2428 8.73595 17.4237 8.77993 16.4355V10.8465Z"
                        fill="#212121"
                    />
                    <path
                        d="M29.2236 7.78254C28.4941 7.78254 27.8036 7.91221 27.1526 8.16961C26.5007 8.42839 25.9154 8.77629 25.3966 9.21329C24.8772 9.6514 24.446 10.1728 24.1045 10.7795C23.7613 11.3856 23.5246 12.037 23.3913 12.732H35.0879C34.9554 12.037 34.7119 11.3856 34.3592 10.7795C34.0049 10.1728 33.5686 9.6514 33.0498 9.21329C32.5309 8.77629 31.9451 8.42839 31.2937 8.16961C30.6427 7.91221 29.9523 7.78254 29.2236 7.78254ZM23.3584 14.8199C23.4689 15.538 23.7008 16.2062 24.0543 16.8231C24.4078 17.4402 24.8441 17.9738 25.3629 18.4221C25.8817 18.8713 26.4733 19.2247 27.136 19.4826C27.7982 19.7406 28.4941 19.8697 29.2236 19.8697C30.04 19.8697 30.8026 19.7182 31.5093 19.4159C32.216 19.1124 32.8453 18.6911 33.3978 18.1529C33.5968 17.9506 33.8396 17.8497 34.1273 17.8497C34.414 17.8497 34.657 17.9506 34.856 18.1529C35.055 18.3551 35.1536 18.602 35.1536 18.8933C35.1536 19.1858 35.055 19.4324 34.856 19.6338C34.1273 20.4653 33.3036 21.0544 32.3876 21.4017C31.4707 21.7504 30.4163 21.924 29.2236 21.924C28.1188 21.924 27.0861 21.7217 26.1252 21.3181C25.1647 20.9134 24.3193 20.3531 23.5903 19.6338C22.8616 18.9165 22.2874 18.0637 21.8674 17.0755C21.4482 16.0881 21.238 15.0108 21.238 13.8427C21.238 12.7209 21.4419 11.6714 21.8508 10.6954C22.26 9.71899 22.8225 8.86622 23.5411 8.13595C24.2579 7.40678 25.109 6.82301 26.0924 6.38517C27.0749 5.94789 28.1406 5.70677 29.2893 5.66125C30.3495 5.66125 31.3486 5.87561 32.2882 6.30158C33.2268 6.72838 34.0606 7.30002 34.7893 8.01815C35.5188 8.73711 36.0987 9.57884 36.529 10.5439C36.9599 11.5089 37.1972 12.5419 37.2411 13.6413C37.2859 14.0228 37.1917 14.3142 36.9599 14.5164C36.728 14.7186 36.4796 14.8199 36.2146 14.8199H23.3584Z"
                        fill="#212121"
                    />
                    <path
                        d="M47.579 10.8465C47.579 10.5551 47.6786 10.3082 47.8776 10.106C48.0767 9.90378 48.3194 9.80253 48.6063 9.80253C48.893 9.80253 49.1369 9.90378 49.335 10.106C49.5341 10.3082 49.6334 10.5551 49.6334 10.8465V16.4354C49.6334 16.9066 49.7214 17.3502 49.8984 17.766C50.0754 18.1815 50.313 18.5454 50.6108 18.8599C50.9092 19.1744 51.2627 19.4213 51.6715 19.6004C52.0799 19.7805 52.5164 19.8696 52.9802 19.8696C53.4441 19.8696 53.8799 19.7805 54.2888 19.6004C54.6971 19.4213 55.056 19.1744 55.3655 18.8599C55.675 18.5454 55.9178 18.1815 56.0942 17.766C56.2712 17.3502 56.3597 16.9066 56.3597 16.4354V6.67176C56.3597 6.38043 56.4594 6.13268 56.6576 5.93128C56.8566 5.72906 57.0996 5.62781 57.3871 5.62781C57.6738 5.62781 57.9168 5.72906 58.1158 5.93128C58.3148 6.13268 58.4136 6.38043 58.4136 6.67176V16.4354C58.4136 17.1991 58.2703 17.9172 57.9833 18.5909C57.6958 19.2635 57.3086 19.8481 56.8237 20.3409C56.3369 20.8355 55.7635 21.2281 55.1005 21.5194C54.4376 21.8116 53.7308 21.9573 52.9802 21.9573C52.0525 21.9573 51.2124 21.7504 50.4618 21.3346C49.7103 20.9191 49.0812 20.364 48.5735 19.6682C48.0875 20.364 47.4685 20.9191 46.7178 21.3346C45.9663 21.7504 45.1271 21.9573 44.1994 21.9573C43.4487 21.9573 42.742 21.8116 42.079 21.5194C41.416 21.2281 40.8418 20.8355 40.3558 20.3409C39.8701 19.8481 39.4892 19.2635 39.2134 18.5909C38.937 17.9172 38.7991 17.1991 38.7991 16.4354V6.67176C38.7991 6.38043 38.8984 6.13268 39.0974 5.93128C39.2959 5.72906 39.5386 5.62781 39.8261 5.62781C40.1131 5.62781 40.3558 5.72906 40.5551 5.93128C40.7541 6.13268 40.8535 6.38043 40.8535 6.67176V16.4354C40.8535 16.9066 40.9412 17.3502 41.1185 17.766C41.2947 18.1815 41.5322 18.5454 41.8306 18.8599C42.1293 19.1744 42.4819 19.4213 42.8908 19.6004C43.2991 19.7805 43.7354 19.8696 44.1994 19.8696C45.1051 19.8696 45.884 19.5446 46.5354 18.8933C47.1872 18.2427 47.535 17.4236 47.579 16.4354V10.8465Z"
                        fill="#212121"
                    />
                    <path
                        d="M68.0224 7.78254C67.2929 7.78254 66.6025 7.91221 65.9514 8.16961C65.2996 8.42839 64.7142 8.77629 64.1954 9.21329C63.676 9.6514 63.2449 10.1728 62.9033 10.7795C62.5602 11.3856 62.3234 12.037 62.1901 12.732H73.8867C73.7542 12.037 73.5107 11.3856 73.158 10.7795C72.8037 10.1728 72.3674 9.6514 71.8486 9.21329C71.3298 8.77629 70.7439 8.42839 70.0926 8.16961C69.4415 7.91221 68.7511 7.78254 68.0224 7.78254ZM62.1573 14.8199C62.2678 15.538 62.4996 16.2062 62.8531 16.8231C63.2066 17.4402 63.6429 17.9738 64.1617 18.4221C64.6805 18.8713 65.2721 19.2247 65.9349 19.4826C66.597 19.7406 67.2929 19.8697 68.0224 19.8697C68.8388 19.8697 69.6014 19.7182 70.3081 19.4159C71.0148 19.1124 71.6442 18.6911 72.1967 18.1529C72.3957 17.9506 72.6384 17.8497 72.9262 17.8497C73.2129 17.8497 73.4559 17.9506 73.6549 18.1529C73.8539 18.3551 73.9524 18.602 73.9524 18.8933C73.9524 19.1858 73.8539 19.4324 73.6549 19.6338C72.9262 20.4653 72.1024 21.0544 71.1864 21.4017C70.2696 21.7504 69.2151 21.924 68.0224 21.924C66.9174 21.924 65.8849 21.7217 64.9241 21.3181C63.9635 20.9134 63.1181 20.3531 62.3891 19.6338C61.6604 18.9165 61.0862 18.0637 60.6662 17.0755C60.247 16.0881 60.0369 15.0108 60.0369 13.8427C60.0369 12.7209 60.2408 11.6714 60.6496 10.6954C61.0588 9.71899 61.6213 8.86622 62.34 8.13595C63.0567 7.40678 63.9079 6.82301 64.8912 6.38517C65.8738 5.94789 66.9394 5.70677 68.0881 5.66125C69.1483 5.66125 70.1474 5.87561 71.0871 6.30158C72.0256 6.72838 72.8594 7.30002 73.5881 8.01815C74.3176 8.73711 74.8975 9.57884 75.3278 10.5439C75.7587 11.5089 75.996 12.5419 76.04 13.6413C76.0848 14.0228 75.9906 14.3142 75.7587 14.5164C75.5268 14.7186 75.2784 14.8199 75.0134 14.8199H62.1573Z"
                        fill="#212121"
                    />
                    <path
                        d="M79.6186 13.8429C79.6186 14.6741 79.7731 15.4538 80.0826 16.1829C80.3921 16.9132 80.8167 17.5527 81.358 18.102C81.8997 18.6529 82.5287 19.0847 83.2466 19.3984C83.9644 19.7129 84.7322 19.8696 85.5494 19.8696C86.3666 19.8696 87.1401 19.7129 87.8688 19.3984C88.5975 19.0847 89.2323 18.6529 89.7744 18.102C90.3153 17.5527 90.7399 16.9132 91.0494 16.1829C91.3589 15.4538 91.5134 14.6741 91.5134 13.8429C91.5134 13.0133 91.3589 12.2273 91.0494 11.4868C90.7399 10.7455 90.3153 10.1005 89.7744 9.55033C89.2323 9.00077 88.5975 8.56845 87.8688 8.25394C87.1401 7.94026 86.3666 7.78272 85.5494 7.78272C84.7542 7.78272 84.0035 7.93391 83.2968 8.23739C82.589 8.54059 81.9716 8.96104 81.4411 9.50012C80.9106 10.0384 80.4806 10.6784 80.1491 11.4192C79.8176 12.1597 79.6406 12.9677 79.6186 13.8429ZM85.6162 5.66143C86.6764 5.66143 87.6872 5.88048 88.6477 6.31832C89.6083 6.75615 90.4532 7.34544 91.1827 8.08592C91.9114 8.8264 92.4913 9.69131 92.9222 10.6784C93.3525 11.6658 93.5678 12.7208 93.5678 13.8429C93.5678 14.9434 93.3579 15.9807 92.9387 16.9579C92.5187 17.934 91.9497 18.7923 91.2321 19.5336C90.5143 20.2741 89.6688 20.8578 88.6971 21.2838C87.7246 21.7106 86.6764 21.9241 85.5494 21.9241C84.4447 21.9241 83.4127 21.7216 82.4519 21.318C81.4905 20.9136 80.6459 20.353 79.9172 19.634C79.1882 18.9167 78.6132 18.0636 78.194 17.0757C77.774 16.0883 77.5647 15.011 77.5647 13.8429V1.38546C77.5647 1.09412 77.6643 0.847202 77.8625 0.644978C78.0615 0.442753 78.3045 0.341505 78.592 0.341505C78.8787 0.341505 79.1217 0.442753 79.3207 0.644978C79.5189 0.847202 79.6186 1.09412 79.6186 1.38546V8.72543C80.3481 7.91708 81.2201 7.21633 82.2366 6.62096C83.252 6.02698 84.3787 5.70668 85.6162 5.66143Z"
                        fill="#212121"
                    />
                </svg>
            </div>

            <transition name="fade" mode="out-in">
                <router-view :key="$route.fullPath" />
            </transition>
        </div>

        <div>
            <svg
                class="backgroundRect1_auth"
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="2.4738"
                    y="14.6017"
                    width="44"
                    height="44"
                    rx="10"
                    transform="rotate(-16 2.4738 14.6017)"
                    fill="none"
                    stroke="#099AF2"
                    stroke-width="4"
                />
            </svg>
            <svg
                class="backgroundRect2_auth"
                width="112"
                height="112"
                viewBox="0 0 112 112"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="1.31069"
                    y="17.5169"
                    width="95.3999"
                    height="95.3999"
                    rx="10"
                    transform="rotate(-9.78073 1.31069 17.5169)"
                    fill="none"
                    stroke="#099AF2"
                    stroke-width="4"
                />
            </svg>
            <svg
                class="backgroundRect3_auth"
                width="79"
                height="79"
                viewBox="0 0 79 79"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="8.78046"
                    y="2.18457"
                    width="68"
                    height="68"
                    rx="10"
                    transform="rotate(5.56633 8.78046 2.18457)"
                    fill="none"
                    stroke="#099AF2"
                    stroke-width="4"
                />
            </svg>
            <svg
                class="backgroundRect4_auth"
                width="93"
                height="88"
                viewBox="0 0 93 88"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="-12.8294"
                    y="2.58431"
                    width="110.361"
                    height="110.361"
                    rx="10"
                    transform="rotate(21.0203 -12.8294 2.58431)"
                    fill="none"
                    stroke="#099AF2"
                    stroke-width="4"
                />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoading: false,
            referral: {},
            error: false,
        };
    },
    computed: {
        referralId() {
            return this.$route.params.referralId;
        },
    },
    methods: {
        async getReferrals() {
            this.isLoading = true;
            try {
                if (!this.referralId) return;
                const { data } = await axios.get(
                    `${process.env.VUE_APP_API_URL.replace('/v1', '/v2')}/referrals/${this.referralId}`
                );
                this.referral = data;
            } catch {
                this.error = true;
            }
            this.isLoading = false;
        },
    },
    created() {
        this.getReferrals();
    },
};
</script>

<style lang="scss">
.guest {
    overflow: auto;
    padding: var(--ww-spacing-08) 0;
    background-color: var(--ww-color-dark-200);
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.-fullscreen {
        padding: 0;
    }

    &__card {
        z-index: 1;
        width: 375px;
        max-width: 90%;
        border: 1px solid var(--ww-color-dark-300);
        border-radius: var(--ww-border-radius-02);
        padding: var(--ww-spacing-06);
        background-color: var(--ww-color-white);
    }
    &__logo {
        margin: var(--ww-spacing-05) 0;
    }

    .fade-enter {
        opacity: 0;
    }
    .fade-enter-active {
        transition: all 2s ease;
    }
    .fade-leave-active {
        transition: all 2s ease;
        opacity: 0;
    }
}

.backgroundRect1_auth {
    position: absolute;
    bottom: 280px;
    left: 100px;
    animation: floating-v1 22s ease-in-out infinite;
}
.backgroundRect2_auth {
    position: absolute;
    bottom: 30px;
    left: 100px;
    animation: floating-v3 22s ease-in-out infinite;
}
.backgroundRect3_auth {
    position: absolute;
    bottom: 180px;
    left: 40px;
    animation: floating-v3 22s ease-in-out infinite;
}
.backgroundRect4_auth {
    position: absolute;
    bottom: 0px;
    left: 0px;
    animation: floating-v4 22s ease-in-out infinite;
}

@keyframes floating-v1 {
    0% {
        transform: translateX(0px) translateY(0px);
    }
    33% {
        transform: translateX(10px) translateY(10px) scale(1.1);
    }
    66% {
        transform: translateX(10px) translateY(-10px) scale(1.1);
    }
    100% {
        transform: translateX(0px) translateY(0px);
    }
}

@keyframes floating-v2 {
    0% {
        transform: translateX(0px) translateY(0px);
    }
    33% {
        transform: translateX(10px) translateY(10px) scale(1.1);
    }
    66% {
        transform: translateX(10px) translateY(-10px) scale(1.1);
    }
    100% {
        transform: translateX(0px) translateY(0px);
    }
}

@keyframes floating-v3 {
    0% {
        transform: translateX(0px) translateY(0px);
    }
    33% {
        transform: translateX(-5px) translateY(-10px) rotate(10deg) scale(1.05);
    }
    66% {
        transform: translateX(-5px) translateY(20px) scale(1.05);
    }
    100% {
        transform: translateX(0px) translateY(0px);
    }
}

@keyframes floating-v4 {
    0% {
        transform: translateX(0px) translateY(0px);
    }
    33% {
        transform: translateX(-10px) translateY(10px) scale(1.1);
    }
    66% {
        transform: translateX(-10px) translateY(0px) scale(1.1);
    }
    100% {
        transform: translateX(0px) translateY(0px);
    }
}
</style>
