<template>
    <svg
        class="ww-editor-icon"
        focusable="false"
        aria-hidden="true"
        :class="{ small: small, large: large, huge: huge }"
        v-on="$listeners"
    >
        <use :xlink:href="iconPath"></use>
    </svg>
</template>

<script>
export default {
    name: 'wwEditorIcon',
    props: {
        /** Name of the icons (see assets/icons filename) */
        name: { type: String, required: true },
        small: { type: Boolean, default: false },
        large: { type: Boolean, default: false },
        huge: { type: Boolean, default: false },
    },
    computed: {
        iconPath() {
            return `#sprite-${this.name}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.ww-editor-icon {
    fill: var(--primary-color, currentColor);
    --ww-icon-size: var(--ww-icon-size-2);
    width: var(--ww-icon-size);
    height: var(--ww-icon-size);
    &.small {
        --ww-icon-size: var(--ww-icon-size-1);
    }
    &.large {
        --ww-icon-size: var(--ww-icon-size-3);
    }
    &.huge {
        --ww-icon-size: var(--ww-icon-size-4);
    }
}
</style>
