<template>
    <div class="ww-table ww-scroll-bar" @scroll="scroll($event)" ref="tableContainer">
        <table>
            <thead>
                <tr>
                    <th
                        v-for="(title, index) in titles"
                        :key="index"
                        class="table-categories caption-m"
                        :class="titleClass(title)"
                        @click="sortBy(title.sortBy)"
                    >
                        <span>{{ title.text }}</span>
                        <i class="fas fa-sort-up"></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="!loading && (!dataSorted || dataSorted.length == 0)">
                    <td :colspan="titles.length" class="no-data" v-html="noDataMsg || 'No data'"></td>
                </tr>
                <tr v-if="loading">
                    <td :colspan="titles.length" class="loader-container">
                        <wwLoaderSmall class="loader"></wwLoaderSmall>
                    </td>
                </tr>
                <slot v-for="(item, index) in dataSorted" name="item" :item="item" :index="index"></slot>
                <tr v-if="!loading && d_limit < data.length">
                    <td :colspan="titles.length">Loading...</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'wwTable',
    props: {
        titles: Array,
        data: Array,
        color: String,
        defaultSort: String,
        defaultIsAsc: { type: Boolean, default: true },
        loading: [String, Boolean],
        noDataMsg: String,
        limit: Number,
    },
    data() {
        return {
            el: undefined,
            isDraged: false,
            isAsc: true,
            sortedBy: undefined,
            d_limit: 0,
        };
    },
    watch: {
        defaultSort() {
            this.sortedBy = this.defaultSort || undefined;
        },
        defaultIsAsc() {
            this.isAsc = this.defaultIsAsc;
        },
        dataSorted() {
            if (!this.loading && this.d_moreLimit) {
                setTimeout(() => {
                    this.d_moreLimit = false;
                    this.scroll();
                }, 500);
            }
        },
    },
    computed: {
        dataSorted() {
            if (this.loading) return [];
            if (!this.data) return [];
            if (!this.sortedBy) return this.data.slice(0, this.d_limit);
            const copy = _.clone(this.data);

            return copy
                .sort((a, b) => {
                    let _a =
                        this.sortedBy.indexOf('.') === -1 ? a[this.sortedBy] : this.getValueByString(a, this.sortedBy);
                    let _b =
                        this.sortedBy.indexOf('.') === -1 ? b[this.sortedBy] : this.getValueByString(b, this.sortedBy);

                    _a = typeof _a == 'undefined' ? -10000000 : _a;
                    _b = typeof _b == 'undefined' ? -10000000 : _b;

                    if (typeof _a == 'string') {
                        _a = _a.toLowerCase();

                        if (!_b) {
                            _b = '';
                        }
                    }

                    if (typeof _b == 'string') {
                        _b = _b.toLowerCase();

                        if (!_a) {
                            _a = '';
                        }
                    }

                    let result = 0;

                    if (_a < _b) result = this.isAsc ? -1 : 1;
                    else result = this.isAsc ? 1 : -1;

                    return result;
                })
                .slice(0, this.d_limit);
        },
    },
    methods: {
        titleClass(title) {
            if (!title.sortBy) return;
            const classList = ['cursor'];

            this.sortedBy === title.sortBy && classList.push('selected');
            this.sortedBy === title.sortBy && this.isAsc && classList.push('asc');
            this.sortedBy === title.sortBy && !this.isAsc && classList.push('desc');

            return classList;
        },
        init() {
            this.sortedBy = this.defaultSort || undefined;
            this.isAsc = this.defaultIsAsc;
        },
        getValueByString(o, s) {
            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
            s = s.replace(/^\./, ''); // strip a leading dot
            var a = s.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (o && k in o) {
                    o = o[k];
                } else {
                    return;
                }
            }
            return o;
        },
        sortBy(sortBy) {
            if (!sortBy) return;
            this.isAsc = this.sortedBy !== sortBy || !this.isAsc;
            this.sortedBy = sortBy;
        },
        scroll() {
            if (!this.d_moreLimit) {
                const scroll =
                    this.$el.getBoundingClientRect().height +
                    this.$el.scrollTop -
                    this.$el.querySelector('table').getBoundingClientRect().height;
                if (Math.abs(scroll) < 200) {
                    this.d_limit += 20;
                    this.d_moreLimit = true;
                }
            }
        },
    },
    created() {
        this.d_limit = parseInt(this.limit) || 20;
        this.init();
    },
};
</script>

<style scoped lang="scss">
.ww-table {
    max-height: 70vh;
    overflow-y: auto;
    color: var(--ww-color-dark-600);

    table {
        min-width: 100%;
        border: none;
        border-collapse: collapse;
        position: relative;
        max-height: 100%;

        .table-categories {
            color: var(--ww-color-dark-500);
        }

        thead {
            position: sticky;
            top: 0;
            background-color: #f4f7f7;
            z-index: 1;
        }

        tbody {
            tr {
                &:hover {
                    background-color: var(--ww-color-dark-300);
                }
                &:not(:last-child) {
                    border-bottom: 1px solid var(--ww-color-dark-400);
                }
            }
        }
        tr {
            th {
                text-align: left;
                white-space: nowrap;
                user-select: none;
                padding: var(--ww-spacing-02) var(--ww-spacing-01) var(--ww-spacing-04) var(--ww-spacing-01);
                z-index: 2;
                &.cursor {
                    cursor: pointer;
                    z-index: 99;
                    &.selected {
                        color: var(--ww-color-blue-500);
                    }
                }
                i {
                    margin-left: var(--ww-spacing-02);
                    opacity: 0;
                    transition: all 0.3s ease;
                }
                &.asc,
                &.desc {
                    i {
                        opacity: 1;
                    }
                }
                &.desc {
                    i {
                        transform: scaleY(-1);
                    }
                }
            }
            td {
                padding: var(--ww-spacing-04) var(--ww-spacing-01);
                color: var(--ww-color-dark-700);
                transition: background-color 0.1s ease;
                &.no-data {
                    border-bottom: none;
                    text-align: center;
                }
                &.loader-container {
                    border-bottom: none;
                    text-align: center;
                    .loader {
                        display: inline-block;
                    }
                }

                &.-nowrap {
                    white-space: nowrap;
                }
                &.-small {
                    width: 1%;
                }
                &.-medium {
                    width: 1%;
                }
            }
        }
    }
}
</style>
