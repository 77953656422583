<template>
    <span class="ww-plan px-2 py-0 label-s" :class="[{ small }, `-${plan.name}`]" v-if="plan">
        {{ plan.name }}
    </span>
</template>

<script>
export default {
    props: {
        plan: { type: Object, default: null },
        small: { type: Boolean, default: false },
    },
};
</script>
