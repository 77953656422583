<template>
    <transition name="fade-loader" mode="out-in">
        <div v-show="!isFetching" class="auth" key="loaded">
            <transition name="page-progress">
                <div v-show="pageProgress < 100" class="page-progress-abs">
                    <div class="page-progress-bar" :style="{ width: `${pageProgress}%` }"></div>
                </div>
            </transition>
            <div class="left" :class="{ open: sidebarOpen }">
                <Sidebar />
            </div>
            <div class="close-menu" v-if="sidebarOpen" @click="sidebarOpen = !sidebarOpen"></div>
            <div class="right">
                <div class="content">
                    <div class="open-sidebar label-s" @click="sidebarOpen = !sidebarOpen">Open Menu</div>
                    <transition name="fade" mode="out-in">
                        <router-view />
                    </transition>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
    name: 'Auth',
    components: {
        Sidebar,
    },
    data() {
        return {
            isFetching: true,
            sidebarOpen: false,
        };
    },
    computed: {
        ...mapGetters({
            pageProgress: 'v2/getPageProgress',
        }),
    },
    methods: {
        ...mapActions({
            fetchOrganizations: 'v2/fetchOrganizations',
        }),
        ...mapMutations({
            setPageProgress: 'v2/setPageProgress',
        }),
    },
    async created() {
        this.isFetching = true;
        await this.fetchOrganizations();
        this.isFetching = false;
    },
};
</script>

<style scoped lang="scss">
.loader {
    height: 100%;
    width: 100%;
}
.auth {
    position: relative;
    overflow: hidden;
    display: flex;
    height: 100vh;
    width: 100vw;
    .page-progress {
        &-abs {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 3px;
            z-index: 1;
        }
        &-bar {
            background-color: var(--ww-color-blue-500);
            width: 0%;
            height: 100%;
            transition: all 0.8s ease-in-out;
            border-top-right-radius: var(--ww-border-radius-00);
            border-bottom-right-radius: var(--ww-border-radius-00);
        }
        &-leave-active {
            transition: all 0.4s ease 0.9s;
        }
        &-leave-to {
            transform: translateY(-100%);
        }
    }
    .left {
        position: relative;
        height: 100vh;
        flex-basis: 250px;
        margin-right: 1px;

        @media (max-width: 992px) {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(-100%);
            transition: 0.3s ease;
            z-index: 100;

            &.open {
                transform: translateX(0);
            }
        }
    }
    .close-menu {
        z-index: 99;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @media (min-width: 993px) {
            display: none;
        }
    }
    .right {
        padding-top: var(--ww-spacing-05);
        height: 100vh;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        background-color: var(--ww-color-dark-200);

        .content {
            width: 95%;
            max-width: 1000px;
            z-index: 1;

            .open-sidebar {
                margin-bottom: var(--ww-spacing-03);
                @media (min-width: 993px) {
                    display: none;
                }
            }
        }
        .background-rect-1 {
            position: absolute;
            top: 70%;
            left: 50px;
            animation: floating-v1 12s ease-in-out infinite;
        }
        .background-rect-2 {
            position: absolute;
            top: 40%;
            right: 0px;
            animation: floating-v2 12s ease-in-out infinite;
        }
        .background-rect-3 {
            position: absolute;
            top: 10%;
            left: 0px;
            animation: floating-v3 12s ease-in-out infinite;
        }
    }
}

.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    transition: opacity 0.2s ease;
}
.fade-leave,
.fade-leave-active {
    transition: opacity 0.2s ease;
    opacity: 0;
}

.fade-loader-enter-active,
.fade-loader-leave-active {
    transition: opacity 1s ease;
}
.fade-loader-enter, .fade-loader-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

@keyframes floating-v1 {
    0% {
        transform: translateX(0px) translateY(0px);
    }
    33% {
        transform: translateX(10px) translateY(10px) scale(1.1);
    }
    66% {
        transform: translateX(10px) translateY(-10px) scale(1.1);
    }
    100% {
        transform: translateX(0px) translateY(0px);
    }
}

@keyframes floating-v2 {
    0% {
        transform: translateX(0px) translateY(0px);
    }
    33% {
        transform: translateX(10px) translateY(10px) scale(1.1);
    }
    66% {
        transform: translateX(10px) translateY(-10px) scale(1.1);
    }
    100% {
        transform: translateX(0px) translateY(0px);
    }
}

@keyframes floating-v3 {
    0% {
        transform: translateX(0px) translateY(0px);
    }
    33% {
        transform: translateX(-10px) translateY(10px) scale(1.1);
    }
    66% {
        transform: translateX(-10px) translateY(-10px) scale(1.1);
    }
    100% {
        transform: translateX(0px) translateY(0px);
    }
}
</style>
