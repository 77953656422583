<template>
    <div class="ww-popup-message ww-scroll-bar">
        <div class="vertical-container">
            <div>{{ message[$i18n.locale] }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'wwPopupMessage',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
        active: Boolean,
    },
    data() {
        return {
            wwManagerLang: wwLib.wwManagerLang,
            message: this.options.data.message,
        };
    },
    watch: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.ww-popup-message {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--ww-color-dark-600);
    .vertical-container {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .message {
            margin: 30px 0px;
            text-align: left;
        }
    }
}
</style>
