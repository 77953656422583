import Vue from 'vue';
import wwPopups from '@/components/popups/';

Vue.use(wwPopups);

export default {
    SIZES: {
        SMALL: '-small',
        MEDIUM: '-medium',
        LARGE: '-large',
        FULL: '-full',
    },
    story: {},
    openPopups: [],

    init() {
        this.initStory();
    },
    /*=============================================m_ÔÔ_m=============================================\
      ADD POPUP
    \================================================================================================*/
    addPopup(name, popup) {
        Vue.component(name, popup);
    },
    /*=============================================m_ÔÔ_m=============================================\
      ADD STORY
    \================================================================================================*/
    addStory(name, data) {
        this.story[name] = data;
    },
    /*=============================================m_ÔÔ_m=============================================\
      OPEN POPUP
    \================================================================================================*/
    open(options) {
        const self = this;

        return new Promise(function (resolve, reject) {
            options = options || {};
            options.data = options.data || {};
            options.programmaticallyHandled = options.programmaticallyHandled || false;
            options.theme = options.theme || '';

            if (!options.firstPage || !self.story[options.firstPage]) {
                return reject();
            }

            const currentPopup = {
                options: options,
                id: Math.random(),
            };

            currentPopup.close = result => {
                self.openPopups.splice(self.openPopups.indexOf(currentPopup), 1);
                if (result) {
                    return resolve(result);
                } else {
                    return reject();
                }
            };

            self.openPopups.push(currentPopup);
        });
    },

    /*=============================================m_ÔÔ_m=============================================\
      STORY
    \================================================================================================*/
    initStory() {
        this.story = {
            MESSAGE: {
                title: {
                    en: 'Information',
                    fr: 'Information',
                },
                type: 'wwPopupMessage',
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Close',
                            fr: 'Fermer',
                        },
                        next: false,
                    },
                },
            },
            INVITE_MEMBERS: {
                title: {
                    en: 'Invite members',
                    fr: 'Inviter des membres',
                },
                type: 'wwPopupInviteMembers',
                storyData: {},
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Send',
                            fr: 'Envoyer',
                        },
                        next: false,
                    },
                },
            },
            WORKSPACE_CREATE: {
                title: {
                    en: 'Create workspace',
                    fr: 'Créer un workspace',
                },
                type: 'wwPopupWorkspaceCreate',
                size: this.SIZES.MEDIUM,
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Create',
                            fr: 'Créer',
                        },
                        next: false,
                    },
                },
            },
            WORKSPACE_DELETE: {
                title: {
                    en: 'Delete workspace',
                    fr: 'Supprimer le workspace',
                },
                type: 'wwPopupWorkspaceDelete',
                size: this.SIZES.MEDIUM,
                buttons: {
                    DELETE: {
                        text: {
                            en: 'I understand, delete workspace',
                            fr: 'Je comprends, supprimer le workspace',
                        },
                        next: false,
                    },
                },
            },
            PLAN_DOWNGRADE: {
                title: '',
                type: 'wwPopupPlanDowngrade',
                size: this.SIZES.MEDIUM,
            },
            /*=============================================m_ÔÔ_m=============================================\
                USER SETTINGS
            \================================================================================================*/
            USER_UPDATE: {
                title: {
                    en: 'Edit information',
                    fr: 'Éditer ses informations',
                },
                type: 'wwPopupForm',
                size: this.SIZES.MEDIUM,
                storyData: {
                    fields: [
                        {
                            label: {
                                en: 'Name :',
                                fr: 'Nom :',
                            },
                            type: 'text',
                            color: 'grey',
                            key: 'userName',
                            valueData: 'user.name',
                        },
                        {
                            label: {
                                en: 'Email :',
                                fr: 'Email :',
                            },
                            type: 'text',
                            color: 'grey',
                            key: 'userMail',
                            valueData: 'user.email',
                        },
                    ],
                },
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Save',
                            fr: 'Enregistrer',
                        },
                        next: false,
                    },
                },
            },
            /*=============================================m_ÔÔ_m=============================================\
                WORKSPACE
            \================================================================================================*/
            WORKSPACE_UPDATE: {
                title: {
                    en: 'Edit workspace',
                    fr: 'Éditer le workspace',
                },
                type: 'wwPopupForm',
                size: this.SIZES.MEDIUM,
                storyData: {
                    fields: [
                        {
                            label: {
                                en: 'Name :',
                                fr: 'Nom :',
                            },
                            type: 'text',
                            color: 'grey',
                            key: 'workspaceName',
                            valueData: 'workspace.name',
                        },
                    ],
                },
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Save',
                            fr: 'Enregistrer',
                        },
                        next: false,
                    },
                },
            },
            WORKSPACE_INVITE: {
                title: {
                    en: 'Invite new members to your workspace',
                },
                type: 'wwPopupWorkspaceInvite',
                size: this.SIZES.LARGE,
                buttons: {
                    NEXT: { text: { en: 'Invite' }, next: false },
                },
            },
            WORKSPACE_INVITE_GUESTS: {
                title: {
                    en: 'Invite new guests to your workspace',
                },
                type: 'wwPopupWorkspaceInvite',
                size: this.SIZES.LARGE,
                buttons: {
                    NEXT: { text: { en: 'Invite' }, next: false },
                },
            },
            WORKSPACE_PURCHASE_SEATS: {
                title: { en: 'Add seats to plan' },
                type: 'wwPopupWorkspacePurchaseSeats',
                size: this.SIZES.MEDIUM,
                buttons: {
                    NEXT: { text: { en: 'Confirm update' }, next: false },
                },
            },
            /*=============================================m_ÔÔ_m=============================================\
                WEBSITE
            \================================================================================================*/
            WEBSITE_CREATE: {
                title: {
                    en: 'Choose a project name',
                },
                type: 'wwPopupWebsiteCreate',
                size: this.SIZES.MEDIUM,
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Create project',
                        },
                        next: false,
                    },
                },
            },
            WEBSITE_DELETE: {
                title: {
                    en: 'Delete project',
                    fr: 'Supprimer le projet',
                },
                type: 'wwPopupWebsiteDelete',
                size: this.SIZES.MEDIUM,
                buttons: {
                    DELETE: {
                        text: {
                            en: 'I understand, delete project',
                            fr: 'Je comprends, supprimer le projet',
                        },
                        next: false,
                    },
                },
            },
            WEBSITE_UNPUBLISH: {
                title: {
                    en: 'Unpublish project',
                    fr: 'Supprimer la publication du projet',
                },
                type: 'wwPopupWebsiteUnpublish',
                size: this.SIZES.MEDIUM,
                buttons: {
                    UNPUBLISH: {
                        text: {
                            en: 'I understand, unpublish project',
                            fr: 'Je comprends, supprimer la publication le projet',
                        },
                        next: false,
                    },
                },
            },
            WEBSITE_CREATE_TUTORIAL: {
                title: {
                    en: 'Tutorial',
                    fr: 'Tutoriel',
                },
                type: 'wwPopupWebsiteCreateTutorial',
                size: this.SIZES.SMALL,
            },
            WEBSITE_MARKETPLACE: {
                title: 'Choose a template',
                type: 'wwPopupWebsiteMarketplace',
                size: this.SIZES.FULL,
            },
            WEBSITE_PUBLISH: {
                title: {
                    en: 'Publish',
                    fr: 'Publish',
                },
                type: 'wwPopupWebsitePublish',
                size: this.SIZES.MEDIUM,
                buttons: {
                    PUBLISH: {
                        text: {
                            en: 'Publish',
                            fr: 'Publier',
                        },
                    },
                },
            },
            WEBSITE_PUBLISH_LOGS: {
                title: {
                    en: 'Publication logs',
                    fr: 'Publication logs',
                },
                type: 'wwPopupWebsitePublishLogs',
                size: this.SIZES.LARGE,
            },
            WEBSITE_RENAME: {
                title: {
                    en: 'Edit website',
                    fr: 'Edit website',
                },
                type: 'wwPopupWebsiteRename',
                size: this.SIZES.MEDIUM,
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Save',
                            fr: 'Save',
                        },
                        next: false,
                    },
                },
            },
            WEBSITE_SELF_HOST_WEWEBPREVIEWURL: {
                title: {
                    en: 'Edit weweb-server URL',
                },
                type: 'wwPopupWebsiteSelfHostWewebPreviewURL',
                size: this.SIZES.MEDIUM,
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Save',
                            fr: 'Save',
                        },
                        next: false,
                    },
                },
            },
            WEBSITE_SELF_HOST_DOMAIN: {
                title: {
                    en: 'Edit weweb-server Domain',
                },
                type: 'wwPopupWebsiteSelfHostDomain',
                size: this.SIZES.MEDIUM,
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Save',
                            fr: 'Save',
                        },
                        next: false,
                    },
                },
            },
            WEBSITE_DOMAIN_BUY: {
                title: {
                    en: 'Buy and add new domain name',
                    fr: 'Acheter et ajouter un nouveau nom de domaine',
                },
                type: 'wwPopupWebsiteDomainBuy',
                size: this.SIZES.MEDIUM,
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Buy domain name',
                            fr: 'Acheter le nom de domaine',
                        },
                        next: false,
                    },
                },
            },
            WEBSITE_DOMAIN_CONNECT: {
                title: {
                    en: 'Connect an existing domain name',
                    fr: 'Connecter un nom de domaine existant',
                },
                type: 'wwPopupWebsiteDomainConnect',
                size: this.SIZES.MEDIUM,
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Connect domain name',
                            fr: 'Connecter le nom de domain',
                        },
                        next: false,
                    },
                },
            },
            WEBSITE_DOMAIN_CONNECT_HELP: {
                title: {
                    en: 'Pending Validation...',
                    fr: 'Validation en attente...',
                },
                type: 'wwPopupWebsiteDomainConnectHelp',
                size: this.SIZES.MEDIUM,
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Ok',
                            fr: 'Ok',
                        },
                        next: false,
                    },
                },
            },
            WEBSITE_DOMAIN_CONNECT_CAA: {
                title: {
                    en: 'Missing a CAA record.',
                },
                type: 'wwPopupWebsiteDomainConnectCAA',
                size: this.SIZES.MEDIUM,
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Ok',
                            fr: 'Ok',
                        },
                        next: false,
                    },
                },
            },
            CONTACT_US: {
                title: {
                    en: 'Contact us',
                    fr: 'Contactez-nous',
                },
                type: 'wwPopupWorkspaceContactUs',
                size: this.SIZES.MEDIUM,
                buttons: {
                    SEND: {
                        text: {
                            en: 'Send message',
                            fr: 'Envoyer mon message',
                        },
                        next: 'MESSAGE_SENT',
                    },
                },
            },
            MESSAGE_SENT: {
                title: {
                    en: 'Message sent',
                    fr: 'Message envoyé',
                },
                type: 'wwPopupWorkspaceMessageSent',
                size: this.SIZES.MEDIUM,
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Ok',
                            fr: 'Ok',
                        },
                        next: false,
                    },
                },
            },
            /*=============================================m_ÔÔ_m=============================================\
                SOURCE CODE
            \================================================================================================*/
            SECTION_BASE_VERSIONS: {
                title: {
                    en: 'Component versions',
                    fr: 'Versions du composant',
                },
                type: 'wwPopupSectionBaseVersions',
                size: this.SIZES.LARGE,
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Set this version active',
                            fr: 'Choisir cette version',
                        },
                        next: null,
                    },
                },
            },
            WWOBJECT_BASE_VERSIONS: {
                title: {
                    en: 'Component versions',
                    fr: 'Versions du composant',
                },
                type: 'wwPopupWwObjectBaseVersions',
                size: this.SIZES.LARGE,
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Set this version active',
                            fr: 'Choisir cette version',
                        },
                        next: null,
                    },
                },
            },
            PLUGIN_VERSIONS: {
                title: {
                    en: 'Component versions',
                    fr: 'Versions du composant',
                },
                type: 'wwPopupPluginVersions',
                size: this.SIZES.LARGE,
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Set this version active',
                            fr: 'Choisir cette version',
                        },
                        next: null,
                    },
                },
            },
            CREATE_SOURCE_CODE_TYPE: {
                title: {
                    en: 'Select component source',
                    fr: 'Choisir la source du composant',
                },
                type: 'wwPopupSourceCodeType',
                size: this.SIZES.MEDIUM,
                storyData: {
                    edition: false,
                },
            },
            CREATE_SOURCE_CODE_GITHUB: {
                title: {
                    en: 'Import component from Github',
                    fr: 'Importer un composant depuis Github',
                },
                type: 'wwPopupSourceCodeGithub',
                size: this.SIZES.MEDIUM,
                storyData: {
                    edition: false,
                },
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Create',
                            fr: 'Créer',
                        },
                        next: null,
                    },
                },
            },
            CREATE_SOURCE_CODE_FIGMA: {
                title: {
                    en: 'Import component from Figma',
                    fr: 'Importer un composant depuis Figma',
                },
                type: 'wwPopupSourceCodeFigma',
                size: this.SIZES.MEDIUM,
                storyData: {
                    edition: false,
                },
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Create',
                            fr: 'Créer',
                        },
                        next: null,
                    },
                },
            },
            EDIT_SOURCE_CODE_GITHUB: {
                title: {
                    en: 'Edit component',
                    fr: 'Editer un composant',
                },
                type: 'wwPopupSourceCodeGithub',
                size: this.SIZES.MEDIUM,
                storyData: {
                    edition: true,
                },
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Save',
                            fr: 'Sauvegarder',
                        },
                        next: null,
                    },
                },
            },
            EDIT_SOURCE_CODE_FIGMA: {
                title: {
                    en: 'Edit component',
                    fr: 'Editer un composant',
                },
                type: 'wwPopupSourceCodeFigma',
                size: this.SIZES.MEDIUM,
                storyData: {
                    edition: true,
                },
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Save',
                            fr: 'Sauvegarder',
                        },
                        next: null,
                    },
                },
            },
            MANAGE_SOURCE_CODE_WORKSPACES: {
                title: {
                    en: 'Share with workspaces',
                    fr: 'Share with workspaces',
                },
                type: 'wwPopupSourceCodeWorkspaces',
                size: this.SIZES.MEDIUM,
                storyData: {
                    edition: true,
                },
                buttons: {
                    NEXT: {
                        text: {
                            en: 'Save',
                            fr: 'Sauvegarder',
                        },
                        next: null,
                    },
                },
            },
        };
    },
};
