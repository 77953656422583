import gql from 'graphql-tag';
import {
    SECTION_BASE_VERSION_FRAGMENT,
    WWOBJECT_BASE_VERSION_FRAGMENT,
    PLUGIN_VERSION_FRAGMENT,
} from './sourceCode.fragments';

/*=============================================m_ÔÔ_m=============================================\
    SectionBases
\================================================================================================*/
export const CREATE_SECTION_BASE = gql`
    mutation CreateSectionBase($name: String!, $organizationId: String!, $repository: JSON!) {
        createSectionBase(name: $name, organizationId: $organizationId, repository: $repository) {
            success
            data
        }
    }
`;

export const UPDATE_SECTION_BASE = gql`
    mutation UpdateSectionBase($sectionBaseId: String!, $repository: JSON!) {
        updateSectionBase(sectionBaseId: $sectionBaseId, repository: $repository) {
            success
        }
    }
`;

export const DELETE_SECTION_BASE = gql`
    mutation DeleteSectionBase($sectionBaseId: String!) {
        deleteSectionBase(sectionBaseId: $sectionBaseId) {
            success
        }
    }
`;

export const FORCE_FETCH_SECTION_BASE = gql`
    mutation ForceFetchSectionBase($sectionBaseId: String!) {
        forceFetchSectionBase(sectionBaseId: $sectionBaseId) {
            success
        }
    }
`;

export const GET_SECTION_BASE_VERSIONS = gql`
    query GetSectionBaseVersions($sectionBaseId: String!) {
        getSectionBaseVersions(sectionBaseId: $sectionBaseId) {
            ...SectionBaseVersionFragment
        }
    }
    ${SECTION_BASE_VERSION_FRAGMENT}
`;

export const SET_SECTION_BASE_ACTIVE_VERSION = gql`
    mutation SetSectionBaseActiveVersion($sectionBaseId: String!, $sectionBaseVersionId: String!) {
        setSectionBaseActiveVersion(sectionBaseId: $sectionBaseId, sectionBaseVersionId: $sectionBaseVersionId) {
            success
        }
    }
`;

/*=============================================m_ÔÔ_m=============================================\
    WwObjectBases
\================================================================================================*/

export const CREATE_WWOBJECT_BASE = gql`
    mutation CreateWwObjectBase($name: String!, $organizationId: String!, $repository: JSON!, $type: String) {
        createWwObjectBase(name: $name, organizationId: $organizationId, repository: $repository, type: $type) {
            success
            data
        }
    }
`;

export const UPDATE_WWOBJECT_BASE = gql`
    mutation UpdateWwObjectBase($wwObjectBaseId: String!, $repository: JSON!) {
        updateWwObjectBase(wwObjectBaseId: $wwObjectBaseId, repository: $repository) {
            success
        }
    }
`;

export const DELETE_WWOBJECT_BASE = gql`
    mutation DeleteWwObjectBase($wwObjectBaseId: String!) {
        deleteWwObjectBase(wwObjectBaseId: $wwObjectBaseId) {
            success
        }
    }
`;

export const FORCE_FETCH_WWOBJECT_BASE = gql`
    mutation ForceFetchWwObjectBase($wwObjectBaseId: String!) {
        forceFetchWwObjectBase(wwObjectBaseId: $wwObjectBaseId) {
            success
        }
    }
`;

export const GET_WWOBJECT_BASE_VERSIONS = gql`
    query GetWwObjectBaseVersions($wwObjectBaseId: String!) {
        getWwObjectBaseVersions(wwObjectBaseId: $wwObjectBaseId) {
            ...WwObjectBaseVersionFragment
        }
    }
    ${WWOBJECT_BASE_VERSION_FRAGMENT}
`;

export const SET_WWOBJECT_BASE_ACTIVE_VERSION = gql`
    mutation SetWwObjectBaseActiveVersion($wwObjectBaseId: String!, $wwObjectBaseVersionId: String!) {
        setWwObjectBaseActiveVersion(wwObjectBaseId: $wwObjectBaseId, wwObjectBaseVersionId: $wwObjectBaseVersionId) {
            success
        }
    }
`;

/*=============================================m_ÔÔ_m=============================================\
    Plugins
\================================================================================================*/
export const CREATE_PLUGIN = gql`
    mutation CreatePlugin($name: String!, $organizationId: String!, $repository: JSON!) {
        createPlugin(name: $name, organizationId: $organizationId, repository: $repository) {
            success
            data
        }
    }
`;

export const UPDATE_PLUGIN = gql`
    mutation UpdatePlugin($pluginId: String!, $repository: JSON!) {
        updatePlugin(pluginId: $pluginId, repository: $repository) {
            success
        }
    }
`;

export const DELETE_PLUGIN = gql`
    mutation DeletePlugin($pluginId: String!) {
        deletePlugin(pluginId: $pluginId) {
            success
        }
    }
`;

export const FORCE_FETCH_PLUGIN = gql`
    mutation ForceFetchPlugin($pluginId: String!) {
        forceFetchPlugin(pluginId: $pluginId) {
            success
        }
    }
`;

export const GET_PLUGIN_VERSIONS = gql`
    query GetPluginVersions($pluginId: String!) {
        getPluginVersions(pluginId: $pluginId) {
            ...PluginVersionFragment
        }
    }
    ${PLUGIN_VERSION_FRAGMENT}
`;

export const SET_PLUGIN_ACTIVE_VERSION = gql`
    mutation SetPluginActiveVersion($pluginId: String!, $pluginVersionId: String!) {
        setPluginActiveVersion(pluginId: $pluginId, pluginVersionId: $pluginVersionId) {
            success
        }
    }
`;
