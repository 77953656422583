<template>
    <div class="sign-in">
        <div class="container">
            <form class="form" @submit.prevent="googleSignIn">
                <button class="submit-button ww-editor-button -secondary" type="submit">
                    <template v-if="!isLoading">
                        <wwEditorIcon class="ww-editor-button-icon -left" name="google" large />
                        Sign in with Google
                    </template>
                    <span v-else>
                        <wwVeryLittleLoader />
                    </span>
                </button>
            </form>
            <div class="sign-in__separator">
                <span class="sign-in__separator--left"></span>
                <span class="sign-in__separator--label">or</span>
                <span class="sign-in__separator--right"></span>
            </div>
            <form class="form" @submit.prevent="signIn">
                <label class="auth-label" for="email">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    class="auth-input ww-editor-input -large"
                    v-model="email"
                    placeholder="name@company.com"
                    required
                    autofocus
                    autocomplete="email"
                />
                <label class="auth-label" for="current-password">
                    Password
                    <span class="ww-editor-link ml-auto">
                        <router-link to="/forgot-password"> Forgot password? </router-link>
                    </span>
                </label>
                <input
                    type="password"
                    id="current-password"
                    name="current-password"
                    class="auth-input ww-editor-input -large"
                    v-model="password"
                    placeholder="••••••••••••"
                    required
                    autocomplete="current-password"
                />
                <div v-if="error" class="error-message label-s">{{ error }}</div>
                <button class="submit-button ww-editor-button -primary" type="submit">
                    <span v-if="!isLoading">Sign in</span>
                    <span v-else>
                        <wwVeryLittleLoader />
                    </span>
                </button>
                <span class="rgpd label-xs">
                    By signing in, you agree to our
                    <a class="ww-editor-link" href="//www.weweb.io/terms-and-conditions/" target="_blank">
                        General Terms & Conditions
                    </a>
                    <br />
                    and to our
                    <a class="ww-editor-link" href="//www.weweb.io/privacy-policy/" target="_blank"> Privacy Policy </a>
                </span>
            </form>
            <div class="bottom-action label-button">
                <router-link class="ww-editor-link" to="/sign-up">Create a new account</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import services from '@/services';
import wwVeryLittleLoader from '@/components/elements/wwVeryLittleLoader.vue';

export default {
    name: 'SignIn',
    components: { wwVeryLittleLoader },
    data() {
        return {
            email: '',
            password: '',
            error: '',
            isLoading: false,
        };
    },
    methods: {
        async signIn() {
            if (this.isLoading) return;
            this.error = '';
            this.isLoading = true;
            try {
                await services.api.user.loginManager(this.email, this.password);

                if (
                    this.$route.query &&
                    this.$route.query.redirectUrl &&
                    this.$route.query.redirectUrl.startsWith('http')
                ) {
                    window.location.href = this.$route.query.redirectUrl;
                } else {
                    this.$router.replace({
                        path: this.$route.query.redirectUrl ? this.$route.query.redirectUrl : '/workspaces',
                    });
                }
            } catch (err) {
                let code = null;
                if (
                    err.graphQLErrors &&
                    err.graphQLErrors[0] &&
                    err.graphQLErrors[0].extensions &&
                    err.graphQLErrors[0].extensions.response &&
                    err.graphQLErrors[0].extensions.response.body &&
                    err.graphQLErrors[0].extensions.response.body.code
                ) {
                    code = err.graphQLErrors[0].extensions.response.body.code;
                }
                switch (code) {
                    case 'UNAUTHENTICATED':
                        this.error = 'Incorrect email or password';
                        break;
                    case 'ACCOUNT_LOCKED':
                        this.error =
                            'Account locked due to too many failed login attempts, please contact support@weweb.io';
                        break;
                    default:
                        this.error =
                            'Unknown error, please contact support@weweb.io. This can be caused by VPN or an enterprise network.';
                }
                wwLib.wwLog.error(err);
            }
            this.isLoading = false;
        },
        googleSignIn() {
            window.location.href = `${process.env.VUE_APP_API_URL}/users/google/login?redirectUrl=${
                this.$route.query.redirectUrl || ''
            }`;
        },
    },
};
</script>

<style scoped lang="scss">
.sign-in {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    &__separator {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        user-select: none;
        &--left,
        &--right {
            border: 1px solid var(--ww-color-dark-300);
            width: 100%;
            height: 1px;
        }
        &--label {
            font-size: var(--ww-font-size-03);
            color: var(--ww-color-dark-500);
            margin: 0 var(--ww-spacing-02);
        }
        margin-bottom: var(--ww-spacing-04);
    }
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: var(--ww-spacing-04);
            width: 100%;
            .auth-label {
                display: flex;
                width: 100%;
                margin-bottom: var(--ww-spacing-02);
                font-size: var(--ww-font-size-03);
                color: var(--ww-color-dark-500);
            }
            .auth-input {
                width: 100%;
                margin-bottom: var(--ww-spacing-03);
            }
            .error-message {
                width: 100%;
                text-align: center;
                color: var(--ww-color-red-500);
            }
            .submit-button {
                width: 100%;
                margin-top: var(--ww-spacing-03);
            }
        }
        .rgpd {
            width: 100%;
            text-align: center;
            margin-top: var(--ww-spacing-02);
            color: var(--ww-color-dark-500);
        }
        .bottom-action {
            margin: var(--ww-spacing-03) 0;
        }
        .ml-auto {
            margin-left: auto;
        }
    }
}
</style>
