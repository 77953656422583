<template>
    <div class="ww-popup-workspace-create">
        <label class="publish__label caption-s" for="commit">
            Workspace name
            <span class="publish__label-required">{{ $t('utils.required') }}</span>
        </label>
        <input
            type="text"
            class="publish__input ww-editor-input -large caption-m"
            name="name"
            v-model="name"
            placeholder="name"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'wwPopupWorkspaceCreate',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    watch: {
        name() {
            this.setButtonState();
        },
    },
    data() {
        return {
            name: '',
        };
    },
    methods: {
        ...mapActions({
            createOrganizationAct: 'v2/createOrganization',
        }),
        setButtonState() {
            if (this.name.length > 1) {
                this.options.setButtonState('CREATE', 'ok');
            } else {
                this.options.setButtonState('CREATE', 'disabled');
            }
        },
        async createOrganization(name) {
            try {
                if (!name) throw new Error('FAILED_TO_CREATE_ORGANIZATION');
                const workspaceName = name.trim();
                const organization = await this.createOrganizationAct(workspaceName);
                if (!organization) throw new Error('FAILED_TO_CREATE_ORGANIZATION');
                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Workspace created</b>',
                        fr: '<b>Workspace créée</b>',
                    },
                    color: 'green',
                    duration: 5000,
                });
                this.$router.push({
                    path: `/workspaces/${organization.id}`,
                });
            } catch (err) {
                wwLib.wwLog.error(err);
                this.isLoading = false;
                if (err && err.graphQLErrors) {
                    for (const e of err.graphQLErrors) {
                        switch (e.extensions.code) {
                            case '409: Conflict':
                                return wwLib.wwNotification.open({
                                    text: {
                                        en: '<b>Workspace name already exist.</b> ',
                                        fr: '<b>Le nom du workspace existe déjà.</b>',
                                    },
                                    color: 'orange',
                                    duration: '5000',
                                });
                        }
                    }
                }
                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Error while creating the workspace</b>',
                        fr: '<b>Erreur lors de la creation du workspace</b>',
                    },
                    color: 'red',
                    duration: 5000,
                });
            }
        },
        async beforeNext() {
            this.options.setLoadingStatus(true);
            try {
                await this.createOrganization(this.name);
            } catch (err) {
                wwLib.wwLog.error(err);
            }
            this.options.setLoadingStatus(false);
        },
    },
    mounted() {
        this.setButtonState();
    },
};
</script>

<style scoped lang="scss">
.ww-popup-workspace-create {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;
    .publish {
        &__label {
            display: flex;
            font-weight: 500;
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
            &-required {
                margin-left: auto;
                color: var(--ww-color-dark-400);
            }
        }
        &__input {
            margin-bottom: var(--ww-spacing-03);
        }
    }
}
</style>
