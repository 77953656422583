<template>
    <div class="ww-popup-website-domain-connect">
        <label class="domain-connect__label caption-s" for="commit">
            Domain name
            <span class="domain-connect__label-required">{{ $t('utils.required') }}</span>
        </label>
        <input
            type="text"
            class="domain-connect__input ww-editor-input -large"
            name="name"
            v-model="name"
            placeholder="www.example.com"
        />
        <div class="domain-connect__error caption-s" v-if="error">
            Invalid domain name. It cannot be a naked domain.<br />(e.g. www.example.com or subdomain.example.com)
        </div>
        <div class="faq-title caption-m">Steps:</div>
        <ul>
            <li class="faq caption-m">
                Add your existing domain name above.
                <br />
                <span
                    >If you don't have a domain name yet, you can buy one with the domain registrar of your choice (e.g.
                    Namecheap, Google Domains, Gandi, etc.) and then come back to this page to add it.
                </span>
            </li>
            <li class="faq caption-m">
                Once your domain name is added, we will give you the records so you can add them in your DNS.
                <br />
                <span
                    >How you update your DNS records will depend on the domain registrar you chose to purchase the
                    domain. Learn more about updating DNS records
                    <a
                        href="https://docs.weweb.io/settings-billing-code-export/project-settings/custom-domain.html"
                        target="_blank"
                        class="link"
                        >here</a
                    >.
                </span>
            </li>
            <li class="faq caption-m">
                Once your DNS records are added, weweb.io takes care of doing the redirection automatically.
                <br />
                <span>This usually takes from <b>15 minutes to 3 hours</b> but can take <b>up to 48 hours</b>.</span>
                <span
                    >You can
                    <a href="https://dnschecker.org/" target="_blank" class="link"
                        >check the status of your DNS records here</a
                    >.</span
                >
            </li>

            <li class="faq caption-m">
                When the site goes online, it will be published from the content currently on your editor.
                <br />
                <span>Make sure the content is ready to be seen!</span>
            </li>
        </ul>
    </div>
</template>

<script>
import services from '@/services';

export default {
    name: 'wwPopupWebsiteDomainConnect',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            name: '',
            error: false,
        };
    },
    watch: {
        name() {
            this.name = this.name.toLowerCase();
            this.setButtonState();
        },
    },
    methods: {
        setButtonState() {
            const regex = /^([a-z0-9][a-z0-9-.]+)(?!\.co\.)\.([a-z0-9][a-z0-9-]{0,60}[a-z0-9]\.(?:co\.)*[a-z]{2,})$/;

            if (!this.name.match(regex)) {
                this.error = this.name ? true : false;
                this.options.setButtonState('NEXT', 'disabled');
                return;
            }
            this.error = false;

            this.options.setButtonState('NEXT', 'ok');
        },
        async beforeNext() {
            this.options.setLoadingStatus(true);
            try {
                if (this.name.startsWith('http://')) this.name = this.name.replace('http://', '');
                if (this.name.startsWith('https://')) this.name = this.name.replace('https://', '');

                const result = await services.api.design.domainConnect(this.options.data.websiteId, this.name);

                this.options.result.dnsRecords = result;

                this.options.setLoadingStatus(false);
                return true;
            } catch (err) {
                wwLib.wwLog.error(err);
                if (err?.graphQLErrors?.[0]?.message === 'INVALID_CAA_RECORDS') {
                    wwLib.wwPopups.open({
                        firstPage: 'WEBSITE_DOMAIN_CONNECT_CAA',
                    });
                } else {
                    wwLib.wwNotification.open({
                        text: {
                            en: '<b>An error occured. Please try later or contact the support.</b> ',
                            fr: "<b>Une erreur est survenue. Veuillez essayer plus tard ou contacter l'équipe.</b>",
                        },
                        color: 'red',
                    });
                }
            }
            this.options.setLoadingStatus(false);
            return false;
        },
    },
    mounted() {
        this.setButtonState();
    },
};
</script>

<style scoped lang="scss">
.ww-popup-website-domain-connect {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;
    .domain-connect {
        &__label {
            display: flex;
            font-weight: 500;
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
            &-required {
                margin-left: auto;
                color: var(--ww-color-dark-400);
            }
        }
        &__input {
            margin-bottom: var(--ww-spacing-03);
        }
        &__error {
            color: var(--ww-color-red-500);
        }
    }

    .faq-title {
        margin-top: var(--ww-spacing-02);
    }
    .faq {
        margin-bottom: var(--ww-spacing-02);

        span {
            color: var(--ww-color-dark-400);
        }
    }

    .link {
        color: var(--ww-color-blue-500);
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
</style>
