<template>
    <div class="ww-popup-rename-website ww-scroll-bar">
        <div class="container">
            <label for="domain" class="label label-m" style="margin-top: var(--ww-spacing-03)"
                >Edit weweb-server domain name</label
            >
            <span class="label label-s">Without 'https://' and without ending '/'</span>
            <input
                name="domain"
                class="input ww-editor-input -large"
                type="text"
                placeholder="Domain name"
                v-model="domain"
                autofocus
            />
        </div>
        <div class="container">
            <label for="domain" class="label label-m" style="margin-top: var(--ww-spacing-03)"
                >Edit weweb-server staging domain name</label
            >
            <span class="label label-s">Without 'https://' and without ending '/'</span>
            <input
                name="domain"
                class="input ww-editor-input -large"
                type="text"
                placeholder="Staging domain name"
                v-model="stagingDomain"
                autofocus
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import services from '@/services';

export default {
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
        active: Boolean,
    },
    data() {
        return {
            domain: '',
        };
    },
    computed: {
        ...mapGetters({
            design: 'v2/getCurrentDesign',
        }),
    },
    methods: {
        ...mapActions({
            fetchdesign: 'v2/fetchDesign',
        }),
        init() {
            this.domain = this.options.data.domain;
            this.stagingDomain = this.options.data.stagingDomain;
            this.options.setButtonState('NEXT', 'ok');
        },
        async beforeNext() {
            this.options.setLoadingStatus(true);
            try {
                this.domain = this.domain.replace('http://', '').replace('https://', '');
                if (this.domain.endsWith('/')) {
                    this.domain = this.domain.slice(0, -1);
                }
                if (this.stagingDomain.endsWith('/')) {
                    this.stagingDomain = this.stagingDomain.slice(0, -1);
                }

                await services.api.design.updateSelfHostDomain(this.design.id, this.domain, this.stagingDomain);

                wwLib.wwNotification.open({
                    text: {
                        en: "<b>Project's domain saved in weweb-server</b>",
                    },
                    color: 'green',
                });
            } catch (err) {
                wwLib.wwLog.error(err);
                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Error while saving domain in weweb-server</b>',
                    },
                    color: 'red',
                });
            }
            this.options.setLoadingStatus(false);
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.ww-popup-rename-website {
    width: 100%;
    display: flex;
    flex-direction: column;

    .container {
        margin-top: var(--ww-spacing-04);
        width: 100%;
        display: flex;
        flex-direction: column;
        .label {
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
        }
        .input {
            width: 100%;
        }
        .requiredLabel {
            position: absolute;
            right: 0;
            color: var(--ww-color-dark-400);
        }
        .result {
            margin: var(--ww-spacing-04) auto;
            &.error {
                color: var(--ww-color-red-500);
            }
            &.valid {
                color: var(--ww-color-green-500);
            }
        }
    }
}
</style>
