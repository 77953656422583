import gql from 'graphql-tag';

/*=============================================m_ÔÔ_m=============================================\
    Design Domain
\================================================================================================*/
export const DESIGN_DOMAIN_FRAGMENT = gql`
    fragment DesignDomainFragment on DesignDomain {
        id
        name
        status
        dnsRecords
        errorMessage
    }
`;

/*=============================================m_ÔÔ_m=============================================\
    Designs
\================================================================================================*/
export const DESIGN_VERSION_FRAGMENT = gql`
    fragment DesignVersionFragment on DesignVersion {
        id
        createdAt
        creatorUser {
            name
            email
        }
        type
        data {
            name
            percent
        }
        cacheVersion
    }
`;
export const DESIGN_METRICS_FRAGMENT = gql`
    fragment DesignMetricsFragment on DesignMetrics {
        designId
        publishedPageCount
        views
        staticCollectionSize
        cachedCollectionSize
        totalCollectionSize
        totalUserFileSize
    }
`;

export const DESIGN_FRAGMENT = gql`
    fragment DesignFragment on Design {
        id
        name
        domain {
            ...DesignDomainFragment
        }
        designVersions {
            ...DesignVersionFragment
        }
        langs
        deployHook
        plan
        planInterval
        stripeSubscriptionId
        features
        openedAt
        isPlanCancelled
        deployConfig
        createdAt
        updatedAt
    }
    ${DESIGN_VERSION_FRAGMENT}
    ${DESIGN_DOMAIN_FRAGMENT}
`;
