// let lang = 'en'

export default {
    init(i18n) {
        this.lang = i18n.locale;
    },
    availableLangs: ['fr', 'en'],

    defaultLang: 'en',

    /*
    setLang: function (lang) {
        var oldLang = this.lang;

        this.lang = lang;

        wwLib.$emit('wwLang:changed', {
            old: oldLang,
            new: this.lang
        });
    },
    */

    getText: function (text) {
        if (!text) {
            return '';
        }

        if (typeof text === 'string') {
            return text;
        }

        if (text.text) {
            if (text.text[this.lang]) {
                return text.text[this.lang] || '';
            }
            return text.text[this.defaultLang] || '';
        } else {
            if (text[this.lang]) {
                return text[this.lang] || '';
            }
            return text[this.defaultLang] || '';
        }
    },

    use(list) {
        list = list || [];
        let self = this;

        return {
            getText(key) {
                if (!list[key]) {
                    return key;
                }
                return self.getText(list[key]);
            },
        };
    },

    /*
    setText: function (obj, text, lang) {

        if (typeof obj !== 'object') {
            obj = {};
        }

        lang = lang || this.lang;

        obj[lang] = text;

        return obj;
    }
    */
};
