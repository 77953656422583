<template>
    <div class="ww-popup-workspace-message-sent">
        <div class="caption-m">Your message has been sent.</div>
        <div class="caption-m mt-2">We will contact you back in the next 24 hours.</div>
    </div>
</template>

<script>
export default {
    name: 'wwPopupWorkspaceMessageSent',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
};
</script>

<style scoped lang="scss">
.ww-popup-workspace-message-sent {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;
}
</style>
