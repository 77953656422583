<template>
    <div class="ww-popup-select">
        <wwEditorIcon @click.native="close()" class="header-close close" name="none" large />
        <div class="layout">
            <div class="header">
                <wwManagerSearchBar class="search" v-model="d_search" @enter="selectFirstResult()"></wwManagerSearchBar>
            </div>
            <div class="container ww-scroll-bar">
                <div class="content" v-if="type == 'text'">
                    <div class="option" v-for="(value, index) in c_values" :key="index" @click="close(value.value)">
                        {{ wwManagerLang.getText(value.text) }}
                    </div>
                </div>
                <div class="content" v-if="type != 'text'">
                    <div
                        class="option-wwobject"
                        v-for="(value, index) in c_values"
                        :key="index"
                        @click="close(value.value)"
                    >
                        <wwObject
                            v-if="value.wwObject"
                            class="wwobject"
                            :ww-object="value.wwObject"
                            ww-no-section="true"
                            ww-no-anim="true"
                            ww-no-link="true"
                        ></wwObject>
                        <div class="value">{{ wwManagerLang.getText(value.text) }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'wwPopupSelect',
    props: {
        options: Object,
        close: Function,
    },
    data() {
        return {
            wwManagerLang: wwLib.wwManagerLang,
            values: [],
            d_search: '',
        };
    },
    computed: {
        type() {
            return this.options.type || 'text';
        },
        image() {
            return this.options.image || process.env.VUE_APP_CDN_URL + 'public/images/no_image_selected.png';
        },
        c_values() {
            if (!this.d_search) {
                return this.values;
            }
            return this.values.filter(value => {
                return (
                    wwLib.wwManagerLang.getText(value.text).toLowerCase().indexOf(this.d_search.toLowerCase()) !== -1
                );
            });
        },
    },
    watch: {
        options() {
            this.setValues();
        },
    },
    methods: {
        init() {},
        setValues() {
            this.values = this.options.values || [
                {
                    value: 'Error',
                    text: {
                        en: 'Error',
                        fr: 'Erreur',
                    },
                },
            ];

            if (this.options.type != 'text') {
                for (let value of this.values) {
                    let data = _.cloneDeep(this.options.data || {});

                    data = this.mergeRecursive(data, value.data);
                }
            }
        },
        mergeRecursive(obj1, obj2) {
            for (var p in obj2) {
                try {
                    // Property in destination object set; update its value.
                    if (typeof obj2[p] == 'object') {
                        obj1[p] = this.mergeRecursive(obj1[p], obj2[p]);
                    } else {
                        obj1[p] = obj2[p];
                    }
                } catch (e) {
                    // Property in destination object not set; create it and set its value.
                    obj1[p] = obj2[p];
                }
            }

            return obj1;
        },
        selectFirstResult() {
            if (this.c_values.length) {
                this.close(this.c_values[0].value);
            }
        },
    },
    mounted() {
        this.init();
    },
    created() {
        this.setValues();
    },
};
</script>

<style scoped lang="scss">
$popupselect-hover-color: white;
$popupselect-color: #bbbbbb;

.ww-popup-select {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 106;

    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        color: var(--ww-color-dark-400);
        cursor: pointer;
        font-size: 2rem;
    }

    .layout {
        height: 100vh;
        display: flex;
        flex-direction: column;

        .header {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 50px 0 40px 0;

            .search {
                max-width: 500px;
            }
        }

        .container {
            flex-grow: 1;
            overflow: auto;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 50px;

            .content {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .option {
                    color: $popupselect-color;
                    border: 1px solid $popupselect-color;
                    border-radius: var(--ww-spacing-02);
                    text-align: center;
                    padding: var(--ww-spacing-02) var(--ww-spacing-03);
                    font-size: 1.3rem;
                    cursor: pointer;
                    transition: all 0.2s ease;
                    text-transform: uppercase;

                    & + .option {
                        margin-top: 40px;
                    }

                    &:hover {
                        color: $popupselect-hover-color;
                        border-color: $popupselect-hover-color;
                        transform: scale(1.05);
                    }
                }

                .option-wwobject {
                    background-color: white;
                    padding: 30px 30px 10px 30px;
                    cursor: pointer;
                    border-radius: 3px;
                    position: relative;

                    & + .option-wwobject {
                        margin-top: 40px;
                    }

                    .wwobject {
                        width: 400px;
                    }

                    .value {
                        text-align: center;
                        font-size: 1rem;
                        margin-top: 5px;
                    }

                    .overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}
</style>
