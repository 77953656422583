import { v4 as uuid } from 'uuid';

export default {
    getCookie(cname) {
        try {
            return window.$cookies.get(cname);
        } catch (e) {
            let name = cname + '=';
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return '';
        }
    },
    getUid() {
        return uuid();
    },
    getTwicPicsPrefix() {
        return `${process.env.VUE_APP_TWICPICS_BASE}${process.env.VUE_APP_TWICPICS_FOLDER}`;
    },
    transformToTwicPics(url) {
        if (url.indexOf('twic=v1') !== -1) {
            return url;
        }
        let baseUrl = `${this.getTwicPicsPrefix()}${url}`;

        baseUrl += baseUrl.indexOf('?') === -1 ? '?' : '&';
        baseUrl += 'twic=v1';

        return baseUrl;
    },
    openCrisp(trackingEvent, trackingParams, message) {
        if (!window.$crisp) {
            wwLib.wwModals.open({
                title: { en: 'Uh oh 😢' },
                text: {
                    en: "Please disable your adblocker to display the live chat. We won't share your data with third-party ad services.",
                },
                buttons: [
                    {
                        text: {
                            en: 'I understand',
                            fr: "J'ai compris",
                        },
                        color: '-primary -green',
                        value: true,
                        enter: true,
                    },
                ],
            });

            trackingParams = { ...(trackingParams || {}), hasAdblocker: true };
        } else {
            window.$crisp.push(['set', 'message:text', [message || '']]);
            window.$crisp.push(['do', 'chat:open']);
        }

        if (trackingEvent) window.analytics.track(trackingEvent, trackingParams || {});
    },
};
