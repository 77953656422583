<template>
    <div class="forgot-password">
        <div class="container">
            <div class="container email-sent paragraph-m" v-if="submited">
                An Email to set up a new password has been sent, if the account exists !
            </div>
            <form class="form" @submit.prevent="forgotPassword" v-else>
                <label class="auth-label" for="email">Please enter your account email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    class="auth-input ww-editor-input -large"
                    v-model="email"
                    placeholder="name@company.com"
                    required
                    autofocus
                    autocomplete="email"
                />
                <div v-if="error" class="error-message label-s">{{ error }}</div>
                <button class="submit-button ww-editor-button -primary" type="submit">
                    <span v-if="!isLoading">Send</span>
                    <span v-else>
                        <wwVeryLittleLoader />
                    </span>
                </button>
            </form>
            <div class="bottom-action label-button">
                <router-link class="ww-editor-link" to="/sign-in">Back to Sign in</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import services from '@/services';
import wwVeryLittleLoader from '@/components/elements/wwVeryLittleLoader.vue';

export default {
    name: 'ForgotPassword',
    components: { wwVeryLittleLoader },
    data() {
        return {
            email: '',
            submited: false,
            isLoading: false,
            error: '',
        };
    },
    methods: {
        async forgotPassword() {
            if (this.isLoading) return;
            this.error = '';
            this.isLoading = true;
            try {
                await services.api.user.sendMailForNewPassword(this.email);
                this.submited = true;
            } catch (err) {
                this.error =
                    'Unknown error, please contact support@weweb.io. This can be caused by VPN or an enterprise network.';
                wwLib.wwLog.error(err);
            }
            this.isLoading = false;
        },
    },
};
</script>

<style scoped lang="scss">
.forgot-password {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .email-sent {
        min-height: 150px;
    }
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: var(--ww-spacing-04);
            width: 100%;
            .auth-label {
                margin-bottom: var(--ww-spacing-02);
                font-size: var(--ww-font-size-03);
                color: var(--ww-color-dark-500);
            }
            .auth-input {
                width: 100%;
                margin-bottom: var(--ww-spacing-03);
            }
            .error-message {
                width: 100%;
                text-align: center;
                color: var(--ww-color-red-500);
            }
            .submit-button {
                width: 100%;
                margin-top: var(--ww-spacing-03);
            }
        }
        .bottom-action {
            margin: var(--ww-spacing-03) 0;
        }
    }
}
</style>
