import wwLib from './wwLib';

import wwAssets from './services/wwAssets';
wwLib.wwAssets = wwAssets;

import wwLog from './services/wwLog';
wwLib.wwLog = wwLog;

import wwManagerLang from './services/wwManagerLang';
wwLib.wwManagerLang = wwManagerLang;

import wwPopups from './services/wwPopups';
wwLib.wwPopups = wwPopups;

import wwModals from './services/wwModals';
wwLib.wwModals = wwModals;

import wwNotification from './services/wwNotification';
wwLib.wwNotification = wwNotification;

import wwPopupSelects from './services/wwPopupSelects';
wwLib.wwPopupSelects = wwPopupSelects;

import wwShortcuts from './services/wwShortcuts';
wwLib.wwShortcuts = wwShortcuts;

export default wwLib;
