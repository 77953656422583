/* eslint-disable no-console */

export default {
    init() {
        if (process.env.NODE_ENV == 'development' || window.$cookies.get('debug')) {
            this.allowLogs();
        }
    },

    allowLogs() {
        this.log = function (...args) {
            console.log(...args);
        };
        this.debug = function (...args) {
            console.debug(...args);
        };
    },

    log() {},
    debug() {},

    warn(...args) {
        console.warn(...args);
    },
    info(...args) {
        console.info(...args);
    },
    error(...args) {
        console.error(...args);
    },
};
