<template>
    <div class="ww-popup-source-code-figma ww-scroll-bar">
        <label class="source-code__label caption-s" for="source-code-name">
            Component name
            <span class="source-code__label-required">{{ $t('utils.required') }}</span>
        </label>
        <div class="source-code__row">
            <input
                class="source-code__input ww-editor-input -large"
                name="source-code-name"
                :disabled="edition"
                v-model="sourceCodeName"
                @input="setName"
                placeholder="name"
            />
            <wwLoaderSmall class="source-code__loader" v-if="checkingName" />
        </div>
        <label class="source-code__label caption-s" for="source-code-name">
            Figma code
            <span class="source-code__label-required">{{ $t('utils.required') }}</span>
        </label>
        <div class="source-code__row">
            <textarea
                style="height: 250px"
                class="source-code__input ww-editor-input -large"
                name="source-code-name"
                v-model="repository.url"
                @input="setFigmaUrl($event.target.value)"
                placeholder="name"
            />
        </div>
        <div class="source-code__error" v-if="sourceCodeNameError">{{ sourceCodeNameError }}</div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'wwPopupSourceCodeFigma',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    watch: {
        sourceCodeName() {
            if (this.sourceCodeName.length < 2) this.options.setButtonState('NEXT', 'disabled');
            else this.options.setButtonState('NEXT', 'ok');
        },
        sourceCodeNameError() {
            if (this.sourceCodeNameError) this.options.setButtonState('NEXT', 'disabled');
        },
    },
    data() {
        return {
            edition: false,
            repository: { url: '' },
            checkingName: false,
            checkingNameTimeout: null,
            sourceCodeName: null,
            sourceCodeNameError: null,
            sourceCodeType: 'section',
        };
    },
    methods: {
        ...mapActions({
            createPlugin: 'v2/createPlugin',
            createWwObjectBase: 'v2/createWwObjectBase',
            createSectionBase: 'v2/createSectionBase',
        }),
        async init() {
            this.edition = this.options.storyData.edition;

            this.sourceCodeName = this.options.data.name || '';
            this.options.result.name = this.sourceCodeName;

            this.sourceCodeType = this.options.data.type || 'section';
            this.options.result.type = this.sourceCodeType;

            this.repository = this.options.data.repository || {};
            this.options.result.repository = this.repository;

            this.setFigmaUrl(this.options.result.repository.url);
        },

        setFigmaUrl(url) {
            this.$set(this.repository, 'url', url || '');
        },

        setName() {
            this.options.result.name = this.sourceCodeName;
        },

        async beforeNext() {
            if (!this.options.result.name) {
                this.sourceCodeNameError = 'Set a name for this component.';
                return false;
            }

            if (!this.edition) {
                this.options.setLoadingStatus(true);
                try {
                    if (this.sourceCodeType === 'wwobject')
                        await this.createWwObjectBase({
                            name: this.sourceCodeName,
                            organizationId: this.options.data.workspaceId,
                            repository: this.repository,
                            type: 'figma',
                        });
                    else if (this.sourceCodeType === 'section')
                        await this.createSectionBase({
                            name: this.sourceCodeName,
                            organizationId: this.options.data.workspaceId,
                            repository: this.repository,
                            type: 'figma',
                        });
                } catch (err) {
                    wwLib.wwLog.error(err);
                }
                this.options.setLoadingStatus(false);
            }

            return true;
        },
    },
    created() {
        this.init();
    },
    beforeDestroy() {},
};
</script>

<style scoped lang="scss">
.ww-popup-source-code-figma {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;
    .source-code {
        &__label {
            display: flex;
            font-weight: 500;
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
            &-required {
                margin-left: auto;
                color: var(--ww-color-dark-400);
            }
        }
        &__row {
            display: flex;
        }
        &__input {
            width: 100%;
            margin-bottom: var(--ww-spacing-03);
        }
        &__loader {
            transform: scale(0.8);
        }
        &__error {
            margin: var(--ww-spacing-04) auto;
            color: var(--ww-color-red-500);
        }
    }
}
</style>
