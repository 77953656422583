<template>
    <div class="ww-manager-github-btn" :style="{ width: width + 'px' }">
        <div class="btn first" @click="selectOrga()" :class="{ valid: this.d_orga }" :title="d_orga ? d_orga : ''">
            <div class="content">
                <span class="title">Organization</span>
                <span class="value" v-if="!d_loadingOrgs">{{ d_orga ? d_orga : 'Select...' }}</span>
                <span class="icon fas fa-spinner fa-spin" v-if="d_loadingOrgs"></span>
            </div>
        </div>
        <div
            class="btn"
            @click="d_orga && selectRepo()"
            :class="{ disabled: !this.d_orga, valid: this.d_repo }"
            :title="d_repo ? d_repo : ''"
        >
            <div class="content">
                <span class="title">Repository</span>
                <span class="value" v-if="!d_loadingRepos">{{ d_repo ? d_repo : 'Select...' }}</span>
                <span class="icon fas fa-spinner fa-spin" v-if="d_loadingRepos"></span>
            </div>
        </div>
        <div
            class="btn last"
            @click="d_repo && selectBranch()"
            :class="{ disabled: !this.d_repo, valid: this.d_branch }"
            :title="d_branch ? d_branch : ''"
        >
            <div class="content">
                <span class="title">Branch</span>
                <span class="value" v-if="!d_loadingBranches">{{ d_branch ? d_branch : 'Select...' }}</span>
                <span class="icon fas fa-spinner fa-spin" v-if="d_loadingBranches"></span>
            </div>
        </div>
    </div>
</template>

<script>
import services from '@/services';

export default {
    name: 'wwManagerGithubButton',
    props: {
        width: [Number, String],
        invert: [Boolean, String],
        workspaceId: String,
        organization: String,
        repository: String,
        branch: String,
    },
    data() {
        return {
            d_orga: null,
            d_orgaIsOrg: false,
            d_repo: null,
            d_branch: null,

            d_githubOrgs: {
                type: 'text',
                values: [],
            },
            d_loadingOrgs: false,
            d_githubRepos: {
                type: 'text',
                values: [],
            },
            d_loadingRepos: false,
            d_githubBranches: {
                type: 'text',
                values: [],
            },
            d_loadingBranches: false,
        };
    },
    computed: {
        githubPathOk() {
            return this.organization && this.repository && this.branch;
        },
    },
    watch: {
        organization() {
            this.init();
        },
        repository() {
            this.init();
        },
        branch() {
            this.init();
        },
    },
    methods: {
        async init() {
            this.d_orga = this.organization || null;
            this.d_repo = this.repository || null;
            this.d_branch = this.branch || null;
        },

        async selectOrga() {
            try {
                if (!this.d_githubOrgs.values.length) {
                    this.d_loadingOrgs = true;
                    const orgs = await services.wwGithub.getOrganizations(this.workspaceId);
                    if (!orgs || !orgs.length) {
                        return false;
                    }

                    let first = true;
                    for (const org of orgs) {
                        this.d_githubOrgs.values.push({
                            value: org,
                            default: first,
                            text: {
                                en: org.login,
                            },
                        });
                        first = false;
                    }
                    this.d_loadingOrgs = false;
                }

                const selectedOrga = await wwLib.wwPopupSelects.open(this.d_githubOrgs);

                if (this.d_orga != selectedOrga.login) {
                    this.d_orga = selectedOrga.login;
                    this.d_orgaIsOrg = selectedOrga.isOrg;

                    this.d_githubRepos.values = [];
                    this.d_githubBranches.values = [];

                    this.d_repo = null;
                    this.d_branch = null;
                }
                return true;
            } catch (error) {
                this.d_loadingOrgs = false;

                if (error.message !== 'No Result') this.showError();

                return false;
            }
        },

        async selectRepo() {
            try {
                if (!this.d_githubRepos.values.length) {
                    this.d_loadingRepos = true;

                    let repos;
                    if (this.d_orgaIsOrg) {
                        repos = await services.wwGithub.getOrgRepos(this.workspaceId, this.d_orga);
                    } else {
                        repos = await services.wwGithub.getUserRepos(this.workspaceId, this.d_orga);
                    }

                    if (!repos || !repos.length) {
                        wwLib.wwNotification.open({
                            text: { en: 'No repository in organization' },
                            color: 'red',
                        });
                        this.d_loadingRepos = false;
                        return false;
                    }

                    let first = true;
                    for (const repo of repos) {
                        this.d_githubRepos.values.push({
                            value: repo,
                            default: first,
                            text: {
                                en: repo.name,
                            },
                        });
                        first = false;
                    }
                    this.d_loadingRepos = false;
                }

                const selectedRepo = await wwLib.wwPopupSelects.open(this.d_githubRepos);

                if (this.d_repo != selectedRepo.name) {
                    this.d_repo = selectedRepo.name;

                    this.d_githubBranches.values = [];

                    this.d_branch = null;
                }
                return true;
            } catch (error) {
                this.d_loadingRepos = false;

                if (error.message !== 'No Result') this.showError();

                return false;
            }
        },

        async selectBranch() {
            try {
                if (!this.d_githubBranches.values.length) {
                    this.d_loadingBranches = true;
                    let branches = await services.wwGithub.getRepoBranches(this.workspaceId, this.d_orga, this.d_repo);

                    if (!branches || !branches.length) {
                        branches = [
                            {
                                name: 'main',
                            },
                        ];
                    }

                    let first = true;
                    for (const branch of branches) {
                        this.d_githubBranches.values.push({
                            value: branch,
                            default: first,
                            text: {
                                en: branch.name,
                            },
                        });
                        first = false;
                    }
                    this.d_loadingBranches = false;
                }

                const selectedBranch = await wwLib.wwPopupSelects.open(this.d_githubBranches);

                this.d_branch = selectedBranch.name;

                this.$emit('change', {
                    organization: this.d_orga,
                    repository: this.d_repo,
                    branch: this.d_branch,
                });

                return true;
            } catch (error) {
                this.d_loadingBranches = false;

                if (error.message !== 'No Result') this.showError();

                return false;
            }
        },
        showError() {
            wwLib.wwNotification.open({
                text: {
                    en: `
                    <b>Unable to connect to Github.</b>
                    <br/>
                    Please check your Github connection in the Workspace settings
                    `,
                },
                color: 'red',
                duration: 5000,
            });
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.ww-manager-github-btn {
    cursor: pointer;
    display: flex;
    .btn {
        border: 1px solid var(--ww-color-blue-500);
        position: relative;
        overflow: hidden;
        flex-grow: 1;
        background-color: var(--ww-color-blue-500);
        transition: all 0.3s ease;
        color: var(--ww-color-white);
        width: 200px;

        .content {
            padding: 5px 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .title {
                opacity: 0.8;
                font-size: 12px;
            }

            .value {
                font-size: 13px;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .icon {
                font-size: 16px;
            }
        }

        &:hover {
            color: var(--ww-color-blue-500);
            background-color: var(--ww-color-white);
        }

        &.valid {
            border: 1px solid var(--ww-color-green-500);
            background-color: var(--ww-color-green-500);
            &:hover {
                background-color: var(--ww-color-white);
                color: var(--ww-color-green-500);
            }
        }

        &.disabled {
            border: 1px solid var(--ww-color-dark-400);
            cursor: not-allowed;
            background-color: var(--ww-color-dark-400);
            &:hover {
                background-color: var(--ww-color-white);
                color: var(--ww-color-dark-400);
            }
        }

        &.first {
            border-radius: var(--ww-border-radius-02) 0 0 var(--ww-border-radius-02);
        }
        &.last {
            border-radius: 0 var(--ww-border-radius-02) var(--ww-border-radius-02) 0;
        }
        &:not(.last) {
            margin-right: 2px;
        }
    }
}
</style>
