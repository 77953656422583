<template>
    <div class="ww-tabs">
        <div class="tab-container">
            <slot name="before"></slot>
            <div class="tabs">
                <div class="topic label-button" v-for="(tab, i) in tabs" :key="i">
                    <router-link
                        v-if="tab.route"
                        :to="{ name: tab.route }"
                        @mouseenter.native="setTransitionDirection(tab, i)"
                    >
                        <wwTab :tag="tab.tag">{{ tab.name }}</wwTab>
                    </router-link>
                    <div v-else-if="tab.action" @mouseenter="setTransitionDirection(tab, i)" @click="onAction(tab)">
                        <wwTab :tag="tab.tag">{{ tab.name }}</wwTab>
                    </div>
                </div>
            </div>
            <slot name="after"></slot>
        </div>
        <transition :name="transitionDirection" mode="out-in">
            <slot>
                <router-view class="tabs-details ww-scroll-bar"></router-view>
            </slot>
        </transition>
    </div>
</template>

<script>
const TABS_ROUTES = [];

export default {
    name: 'wwTabs',
    props: {
        tabs: { required: true },
    },
    data() {
        return { selectedTab: {}, transitionDirection: 'neutral' };
    },
    methods: {
        setTransitionDirection(tab) {
            const location = this.$route.name;
            const direction = tab.route;

            const transitionDirection =
                TABS_ROUTES.indexOf(location) <= TABS_ROUTES.indexOf(direction) ? 'left' : 'right';

            this.transitionDirection = transitionDirection;
        },
        onAction(tab) {
            tab.action && tab.action();
        },
    },
    created() {
        this.tabs.forEach(tab => {
            TABS_ROUTES.push(tab.route);
            if (tab.active) this.selectedTab = tab;
        });
    },
};
</script>

<style lang="scss" scoped>
.ww-tabs {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .tab-container {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        margin: var(--ww-spacing-04) 0;

        .tabs {
            height: 100%;
            display: flex;
            flex-grow: 2;

            @media (max-width: 992px) {
                overflow-x: scroll;
            }

            align-items: center;
            .topic {
                .router-link-active {
                    .ww-tab {
                        color: var(--ww-color-blue-500);
                        background-color: white;
                    }
                }
            }
        }
    }

    .tabs-details {
        overflow-y: auto;
    }
}
/* -------------------------------------------------------------------------- */
/*                          Neutral Route transition                          */
/* -------------------------------------------------------------------------- */
.neutral-enter-active {
    transition: all 0.2s;
}
.neutral-leave-active {
    transition: all 0.2s;
}
.neutral-enter,
.neutral-leave-to {
    opacity: 0;
}
/* -------------------------------------------------------------------------- */
/*                          Route transition to right                         */
/* -------------------------------------------------------------------------- */
.right-enter-active {
    transition: all 0.2s;
}
.right-leave-active {
    transition: all 0.2s;
}
.right-enter {
    transform: translateX(-20px);
    opacity: 0;
}
.right-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
/* -------------------------------------------------------------------------- */
/*                          Route transition to left                         */
/* -------------------------------------------------------------------------- */
.left-enter-active {
    transition: all 0.2s;
}
.left-leave-active {
    transition: all 0.2s;
}
.left-enter {
    transform: translateX(20px);
    opacity: 0;
}
.left-leave-to {
    transform: translateX(-20px);
    opacity: 0;
}
</style>
