<template>
    <div class="reset-password">
        <div class="container">
            <form class="form" @submit.prevent="forgotPassword">
                <label class="auth-label" for="new-password">New password</label>
                <input
                    type="password"
                    id="new-password"
                    name="new-password"
                    class="auth-input ww-editor-input -large"
                    v-model="password"
                    placeholder="••••••••••••"
                    required
                    autofocus
                    autocomplete="new-password"
                />
                <label class="auth-label" for="confirm-password">Confirm new password</label>
                <input
                    type="password"
                    id="confirm-password"
                    name="confirm-password"
                    class="auth-input ww-editor-input -large"
                    v-model="confirmPassword"
                    placeholder="••••••••••••"
                    required
                    autocomplete="new-password"
                />
                <div v-if="error" class="error-message label-s">{{ error }}</div>
                <button class="submit-button ww-editor-button -primary" type="submit">
                    <span v-if="!isLoading">Change password</span>
                    <span v-else>
                        <wwVeryLittleLoader />
                    </span>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import services from '@/services';
import wwVeryLittleLoader from '@/components/elements/wwVeryLittleLoader.vue';
const PASSWORD_REGEX =
    /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/gm;

export default {
    name: 'ResetPassword',
    components: { wwVeryLittleLoader },
    data() {
        return {
            password: '',
            confirmPassword: '',
            token: '',
            error: '',
            isLoading: false,
        };
    },
    computed: {
        match() {
            return this.password === this.confirmPassword;
        },
    },
    methods: {
        async forgotPassword() {
            if (this.isLoading) return;
            if (!this.match) {
                this.error = 'Please enter the same password in both inputs.';
                return;
            }
            if (this.password.length > 72) {
                this.error = 'Password must not contain more than 72 characters.';
                return;
            }
            if (!this.password.match(PASSWORD_REGEX)) {
                this.error =
                    'Password must contain at least 8 characters including 1 lowercase, 1 uppercase, 1 number, 1 special character.';
                return;
            }

            this.error = '';
            this.isLoading = true;
            try {
                await services.api.user.updateForgottenPassword(this.token, this.password);
                this.$router.push({ path: '/sign-in' });
            } catch (err) {
                switch ((err.graphQLErrors && err.graphQLErrors[0] && err.graphQLErrors[0].message) || true) {
                    case '400: Bad Request':
                        this.error = 'Invalid token, please redo forgot password.';
                        break;
                    default:
                        this.error =
                            'Unknown error, please contact support@weweb.io. This can be caused by VPN or an enterprise network.';
                }
                wwLib.wwLog.error(err);
            }
            this.isLoading = false;
        },
    },
    mounted() {
        this.token = this.$route.query.token;
        if (!this.token) this.$router.push({ name: 'forgot-password' });
    },
};
</script>

<style scoped lang="scss">
.reset-password {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: var(--ww-spacing-04);
            width: 100%;
            .auth-label {
                margin-bottom: var(--ww-spacing-02);
                font-size: var(--ww-font-size-03);
                color: var(--ww-color-dark-500);
            }
            .auth-input {
                width: 100%;
                margin-bottom: var(--ww-spacing-03);
            }
            .error-message {
                width: 100%;
                text-align: center;
                color: var(--ww-color-red-500);
            }
            .submit-button {
                width: 100%;
                margin-top: var(--ww-spacing-03);
            }
        }
    }
}
</style>
