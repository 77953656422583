export default {
    en: {
        confirmPlan: {
            billingInfo: 'Billing information',
            labelCard: 'ending in',
            card: 'Card',
            cardEndingIn: 'card ending in',
            useCoupon: 'use a coupon',
            total: 'Total',
            subtotal: 'Subtotal',
            prorationDiscount: 'Proration Discount',
            fromBasicPlan: 'from your basic plan',
            payToday: 'To pay today',
            callSupport: 'call support',
            vat: 'VAT',
            coupon: 'Coupon',
            accept: 'Accept',
            terms: 'terms & conditions',
            subscribe: 'SUBSCRIBE',
            pleaseAcceptTerms: 'Please accept the terms to continue',
            cardDeclined: 'Your card was declined',
            errorOccured: 'An error occured, please contact the support team at support@weweb.io',
            byStripe: 'by Stripe',
            updateCard: 'UPDATE',
            cardNamePlaceholder: "Card owner's name",
        },
        domain: {
            inputLabel: 'Domain Name (ex: weweb or weweb.io)',
            availability: 'Check availability',
            domainInfo: 'Domaine Info',
            notAvailable: 'not available',
            moreOn: 'See more on gandi.net',
        },
        plan: {
            payToday: 'to pay today',
            toBeDefined: 'To be defined',
            talkToday: "Let's talk today",
            select: 'SELECT',
            contactUs: 'CONTACT US',
        },
        updateAdressAndCard: {
            card: 'Card',
            byStripe: 'by Stripe',
            update: 'UPDATE',
            cardNamePlaceholder: "Card owner's name",
            fillinTheFrom: 'Please fill in the form',
            billingAddress: 'Billing address',
            company: 'Company',
            street: 'Number and Street',
            postalCode: 'Postal Code',
            city: 'City',
            phone: 'Phone',
        },
        trialWarning: {
            thankyou:
                'Thank you for trying the WeWeb editor. Your project will remain safely stored in our databases. Here are several options you can consider for the future.',
            remaining: 'remaining test days',
            continueTrial: 'Continue trial',
            upgradeButton: 'Upgrade plan',
            notReady: "If you're not ready to upgrade to a paying plan, here are other options available:",
            restart: 'Restart your trial',
            restartText:
                "If you haven't had the opportunity to try the product in its entirety or if you need some time to evaluate it, please let us know. Just send us an email to <a href='mailto:subscription@weweb.io'>subscription@weweb.io</a> and we will extend your trial period",
            share: 'Share feedback',
            shareText:
                'If WeWeb does not suit you, let us know what you are looking for and we may be able to offer you other solutions that would suit you better. You can email us at ',
            close: 'Close your account',
            closeText:
                "You can close and delete your data. Or, do nothing and we'll automatically close it and delete your data for you in 90 days. But don't worry, we will send you another email before that happens",
        },
        inviteMembers: {
            addAnotherMember: 'Add another member',
            addCustomMessage: 'Add custom message',
            customMessage: 'Custom message',
        },
        organizationOnwership: {
            owner: 'Owner of organization',
        },
    },
};
