<template>
    <div class="ww-maintenance-popup" v-if="show && maintenance && maintenance.message">
        <div class="ww-maintenance-popup__message" v-html="maintenance.message"></div>
        <wwEditorIcon class="ww-maintenance-popup__icon" name="none" @click="close" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {},
    data() {
        return {
            show: true,
        };
    },
    computed: {
        ...mapGetters({
            maintenance: 'v2/getMaintenance',
        }),
    },
    methods: {
        ...mapActions({
            fetchMaintenance: 'v2/fetchMaintenance',
        }),

        close() {
            this.show = false;
        },
    },
    mounted() {
        this.fetchMaintenance();
    },
};
</script>

<style lang="scss" scoped>
.ww-maintenance-popup {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--ww-color-red-600);
    padding: 10px;
    border-radius: 0 0 5px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;

    &__message {
        margin-right: 10px;
    }
    &__icon {
        cursor: pointer;
    }
}
</style>
