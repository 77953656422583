<template>
    <div class="ww-manager-listbox" @keypress="onKeypress" :class="theme" @click="!noList ? (isOpen = !isOpen) : null">
        <button
            ref="button"
            class="ww-manager-listbox__button"
            :class="{ 'no-list': noList }"
            tabindex="0"
            aria-haspopup="listbox"
            :disabled="disabled"
            :aria-expanded="isOpen ? 'true' : 'false'"
        >
            <slot :name="$scopedSlots.selected ? 'selected' : 'option'" :option="selectedOption || {}"></slot>
            <!-- <span
                class="wwi wwi-chevron-down ww-manager-listbox__arrow"
                :class="{ '-reversed': isOpen, '-hide': noList }"
            ></span> -->
            <wwEditorIcon
                class="ww-manager-listbox__arrow"
                name="chevron-down-outline"
                :class="{ '-reversed': isOpen }"
                small
            />
        </button>
        <div @click.stop class="ww-manager-listbox__list" :class="{ '-open': isOpen, '-has-search': hasSearch }">
            <input
                class="ww-editor-input ww-manager-listbox__list-search"
                type="text"
                placeholder="Search"
                @input="search = $event.target.value"
                autofocus
            />
            <ul class="ww-manager-listbox__list-content ww-scroll-bar" role="listbox" ref="list">
                <li
                    v-for="(option, index) in filteredOptions"
                    :key="index"
                    role="option"
                    class="ww-manager-listbox__option"
                    :class="{ '-selected': isSelected(option) }"
                    ref="option"
                    tabindex="0"
                    :aria-selected="isSelected(option) ? 'true' : 'false'"
                    @click="selectOption(option)"
                    @keydown.enter.prevent="selectOption(option)"
                    @keydown.space.prevent="selectOption(option)"
                    @keydown.esc.prevent="isOpen = false"
                    @keydown.down="focusNextOption(index)"
                    @keydown.right="focusNextOption(index)"
                    @keydown.up="focusPreviousOption(index)"
                    @keydown.left="focusPreviousOption(index)"
                >
                    <slot name="option" :option="option" :index="index" :is-selected="isSelected(option)"></slot>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'wwManagerListbox',
    inject: {
        theme: { default: null },
    },
    props: {
        /** Each option need to be an object with a value key */
        options: { type: Array, required: true },
        value: null,
        disabled: { type: Boolean, default: false },
        zIndex: { type: Number, default: 0 },
    },
    data() {
        return {
            isOpen: false,
            search: '',
        };
    },
    computed: {
        filteredOptions() {
            if (this.search) {
                return this.options.filter(option => {
                    return option.label.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                });
            } else {
                return this.options;
            }
        },
        selectedOption() {
            return this.options.find(option => this.isSelected(option)) || this.options[0];
        },
        noList() {
            return !this.options || !this.options.length;
        },
        hasSearch() {
            return !this.noList && this.options.length > 10;
        },
    },
    mounted() {
        document.addEventListener('click', this.onClick, { capture: true });
    },
    methods: {
        isSelected(option) {
            return option.value === this.value;
        },
        selectOption(option, forceClose = true) {
            this.$emit('input', option.value);
            if (forceClose) this.isOpen = false;
        },
        focusNextOption(index) {
            this.focusOption(index === this.options.length - 1 ? 0 : index + 1);
        },
        focusPreviousOption(index) {
            this.focusOption(index === 0 ? this.options.length - 1 : index - 1);
        },
        focusOption(index) {
            if (this.$refs.option[index]) {
                this.$refs.option[index].focus();
            }
        },
        onClick(event) {
            let el = event.target;
            let isInside = false;
            while (el && !isInside) {
                isInside = el === this.$el;
                el = el.parentNode;
            }
            if (!isInside) {
                this.isOpen = false;
            }
        },
        // TODO: add a throttle?
        onKeypress(event) {
            if (this.hasSearch) return;

            event.preventDefault();
            event.stopPropagation();
            // We treat only alpha caracters
            const index = this.options.findIndex(
                ({ label } = {}) => label && label.toLowerCase().startsWith(event.key)
            );
            if (index >= 0) {
                if (this.isOpen) {
                    this.focusOption(index);
                }
                this.selectOption(this.options[index], false);
            }
        },
    },
    destroyed() {
        document.removeEventListener('click', this.onClick);
    },
    watch: {
        disabled() {
            this.isOpen = false;
        },
        isOpen(val) {
            if (val) {
                // Wait next tick because cannot focus hidden element
                this.$nextTick(() => {
                    const index = this.options.findIndex(({ value }) => value === this.value);
                    this.focusOption(index);
                });
            } else {
                this.$refs.button.focus();
            }
        },
    },
};
</script>

<style lang="scss">
.ww-manager-listbox {
    --bg-color: var(--ww-color-dark-200);
    --text-color: var(--ww-color-dark-700);
    position: relative;
    &.dark {
        --bg-color: var(--ww-color-dark-600);
        --text-color: white;
    }
    &__button {
        padding: calc(var(--ww-spacing-01) / 2) var(--ww-spacing-01) calc(var(--ww-spacing-01) / 2) var(--ww-spacing-02);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.no-list {
            cursor: default;
        }
    }
    &__list {
        width: 100%;
        top: 100%;
        position: absolute;
        height: 0px;
        overflow: hidden;
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 0.3s ease, transform 0.3s ease;
        z-index: 30;
        margin: 5px 0 0 0;
        background-color: var(--bg-color);

        &-content {
            margin: 0;
            width: 100%;
            list-style: none;
            padding: 0;
        }

        &-search {
            display: none;
            width: calc(100% - 10px);
            margin: 5px auto;
        }

        &.-has-search {
            .ww-manager-listbox__list {
                &-search {
                    display: block;
                }
                &-content {
                    height: 150px;
                    overflow-y: scroll;
                }
            }
        }

        &.-open {
            height: auto;
            overflow: unset;
            opacity: 1;
            transform: translateY(0);
        }
    }
    &__option {
        cursor: pointer;
        color: var(--text-color);
        transition: all 0.2s ease;
        &:focus,
        &:hover,
        &.-selected {
            background-color: var(--ww-color-primary);
            color: white;
            outline: none;
        }
    }
    &__arrow {
        font-size: 12px;
        transition: 0.2s linear;
        margin-left: var(--ww-spacing-01);
        margin-top: 2px;
        &.-reversed {
            transform: rotate(180deg);
            margin-top: 0px;
        }
        &.-hide {
            opacity: 0;
        }
    }
}
</style>
