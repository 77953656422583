<template>
    <div class="ww-popup" data-is-ui>
        <div class="ww-popup__background"></div>
        <div class="ww-popup__container" :class="activeSize">
            <div class="ww-popup__item -header">
                <transition tag="div" name="header-previous">
                    <wwEditorIcon
                        v-if="popupHistory.length > 1"
                        class="header-previous"
                        name="chevron-back-outline"
                        @click="previous"
                        small
                    />
                </transition>
                <div
                    class="header-title paragraph-s"
                    :class="{ '-danger': options.theme === 'danger', '-warning': options.theme === 'warning' }"
                    v-if="activePopup"
                >
                    <transition name="header-title" mode="out-in">
                        <div :key="popupIndex">{{ wwManagerLang.getText(activeTitle) }}</div>
                    </transition>
                </div>
                <button
                    v-if="!options.programmaticallyHandled"
                    type="button"
                    class="header-close ww-editor-button -icon -close"
                    @click="closePopup"
                >
                    <wwEditorIcon name="none" large />
                </button>
            </div>
            <div class="ww-popup__item -popup">
                <wwLoader :loading="loading" />
                <component
                    :is="popup.type"
                    v-for="(popup, index) in popupHistory"
                    :key="index"
                    class="ww-popup__item-content ww-scroll-bar"
                    :class="popup.activeClass"
                    :active="popup.activeClass == 'active'"
                    :options="popup"
                    @close="closePopup"
                    @next="next"
                    @end="end"
                    @previous="previous"
                />
            </div>
            <transition name="-footer" mode="out-in" v-if="activePopup && !options.programmaticallyHandled">
                <div class="ww-popup__item -footer" v-if="activeButtons" :key="popupIndex">
                    <button
                        v-for="(button, index) in activeButtons"
                        :key="index"
                        class="ww-editor-button -primary"
                        :class="{ '-red': options.theme === 'danger', '-yellow': options.theme === 'warning' }"
                        :disabled="loading || button.state === 'disabled'"
                        @click="next(button.next)"
                    >
                        {{ wwManagerLang.getText(button.text) }}
                        <wwEditorIcon
                            v-if="button.icon"
                            class="ww-editor-button-icon -right"
                            :name="button.icon"
                            small
                        />
                    </button>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

export default {
    name: 'wwPopup',
    props: {
        options: Object,
        close: Function,
    },
    data() {
        return {
            wwManagerLang: wwLib.wwManagerLang,
            popupId: Math.random(),
            popupHistory: [],
            popupIndex: 0,
            loading: false,
        };
    },
    computed: {
        activePopup() {
            return this.popupHistory.find(popup => popup.activeClass === 'active');
        },
        activeButtons() {
            if (!this.activePopup) return null;
            return this.activePopup.buttons || null;
        },
        activeSize() {
            if (!this.activePopup) return null;
            return this.activePopup.size ? this.activePopup.size : wwLib.wwPopups.SIZES.FULL;
        },
        activeTitle() {
            if (!this.activePopup) return null;
            return this.activePopup.title;
        },
    },
    methods: {
        /*=============================================m_ÔÔ_m=============================================\
          SHORTCUTS
        \================================================================================================*/
        setShortcut() {
            wwLib.wwShortcuts.add({
                tag: this.popupId,
                key: 'escape',
                action: this.previousOrClose,
            });
        },
        removeShortcut() {
            wwLib.wwShortcuts.remove(this.popupId);
        },
        /*=============================================m_ÔÔ_m=============================================\
          POPUPS
        \================================================================================================*/
        getPopup(name) {
            const storyData = wwLib.wwPopups.story[name];

            // This is the "options" object passed to the next popup
            return {
                index: parseInt(this.popupIndex),
                type: storyData.type,
                name: name,
                title: storyData.title,
                config: _.cloneDeep(this.getPopupConfig(name)),
                data: _.cloneDeep(this.activePopup ? this.activePopup.data : this.options.data),
                result: _.cloneDeep(this.activePopup ? this.activePopup.result : {}),
                buttons: _.cloneDeep(storyData.buttons),
                storyData: _.cloneDeep(storyData.storyData),
                next: _.cloneDeep(storyData.next),
                activeClass: 'next',
                size: storyData.size,
                setLoadingStatus: status => (this.loading = status ? true : false),
                setButtonState: function (button, state) {
                    if (typeof button === 'object') {
                        Vue.set(button, 'state', state);
                    } else if (typeof this.buttons[button] === 'object') {
                        Vue.set(this.buttons[button], 'state', state);
                    }
                },
                goToNext: popup => this.next(popup, true),
                end: () => this.end(),
            };
        },
        getPopupConfig(name) {
            if (this.options && this.options.config && this.options.config[name]) {
                return this.options.config[name];
            }
            return {};
        },
        async next(next, skipBeforeNext) {
            const component = this.$el.querySelector('.ww-popup__item-content.active')
                ? this.$el.querySelector('.ww-popup__item-content.active').__vue__
                : null;

            if (!skipBeforeNext && component && component.beforeNext && typeof component.beforeNext === 'function') {
                if ((await component.beforeNext()) === false) {
                    return;
                }
            }

            if (!next) return await this.end();

            ++this.popupIndex;

            let newPopup = this.getPopup(next);

            //Check if next popup needs to be skiped.
            let skipPopup = false;
            if (typeof newPopup.config.skip != 'undefined' && newPopup.config.skip !== null) {
                if (newPopup.config.skip === true) {
                    skipPopup = true;
                }
                if (typeof newPopup.config.skip === 'function') {
                    skipPopup = newPopup.config.skip(newPopup.data, this.activePopup.result.get());
                    if (skipPopup.then) {
                        skipPopup = await skipPopup;
                    }
                }
            }

            // If skip next popup, set next as the next next popup.
            if (skipPopup) next = wwLib.wwPopups.story[next].next;

            if (!next) return await this.end();

            wwLib.wwLog.debug('POPUP/NEXT', next);

            //Generate next popup
            newPopup = this.getPopup(next);

            this.popupHistory.push(newPopup);

            setTimeout(() => {
                this.popupHistory.forEach(popup => (popup.activeClass = 'previous'));
                newPopup.activeClass = 'active';
            }, 100);
        },
        previous() {
            if (this.popupHistory.length <= 1 || this.popupHistory.indexOf(this.activePopup) <= 0) return;

            const activePopupIndex = this.popupHistory.indexOf(this.activePopup);

            this.popupHistory[activePopupIndex].activeClass = 'next';
            this.popupHistory[activePopupIndex - 1].activeClass = 'active';

            --this.popupIndex;

            wwLib.wwLog.debug('POPUP/PREVIOUS', this.popupHistory[activePopupIndex - 1].name);

            setTimeout(() => this.popupHistory.splice(this.popupHistory.length - 1, 1), 100);
        },
        previousOrClose() {
            if (this.popupHistory.length <= 1 || this.popupHistory.indexOf(this.activePopup) <= 0) this.close();
            else this.previous();
        },
        async end() {
            const component = this.$el.querySelector('.ww-popup__item-content.active')
                ? this.$el.querySelector('.ww-popup__item-content.active').__vue__
                : null;

            if (component && component.beforeEnd && typeof component.beforeEnd === 'function') {
                if ((await component.beforeEnd()) === false) {
                    return;
                }
            }
            this.close(_.cloneDeep(this.activePopup.result));
        },
        closePopup() {
            this.close();
        },
    },
    mounted() {
        wwLib.wwLog.debug('POPUP/OPEN', this.options.firstPage);
        const firstPopup = this.getPopup(this.options.firstPage);
        firstPopup.activeClass = 'active';
        this.popupHistory.push(firstPopup);
        this.setShortcut();
    },
    beforeDestroy() {
        this.removeShortcut();
    },
};
</script>

<style scoped lang="scss">
.ww-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__background {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: var(--ww-color-dark-700);
        transform: scale(1.1);
        opacity: 0.6;
    }
    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: calc(100% - var(--ww-spacing-05));
        max-height: calc(100% - var(--ww-spacing-05));
        &.-small {
            width: 524px;
            height: 164px;
        }
        &.-medium {
            width: 524px;
            height: 524px;
        }
        &.-large {
            width: 824px;
            height: 70vh;
        }
        &.-full {
            width: 100%;
            height: 100%;
        }
        transition: all 0.5s ease;
        background-color: var(--ww-color-white);
        border: 1px solid var(--ww-color-dark-200);
        border-radius: var(--ww-border-radius-03);
        box-shadow: var(--ww-box-shadow-01);
        overflow: hidden;
        padding: var(--ww-spacing-03) var(--ww-spacing-04);
    }
    &__item {
        position: relative;
        width: 100%;
        &.-header {
            display: flex;
            align-items: center;
            padding: var(--ww-spacing-02) 0;
            border-bottom: 1px solid var(--ww-color-dark-200);

            .header-previous {
                color: var(--ww-color-dark-500);
                cursor: pointer;
                margin-right: var(--ww-spacing-02);
                &-enter-active,
                &-leave-active {
                    transition: all 0.5s ease;
                }
                &-enter,
                &-leave-to {
                    width: 0;
                    margin: 0;
                }
            }

            .header-title {
                color: var(--ww-color-blue-500);
                overflow: hidden;
                &-enter-active,
                &-leave-active {
                    transition: all 0.3s ease;
                }
                &-enter {
                    transform: translateY(-100%);
                }
                &-leave-to {
                    transform: translateY(100%);
                }

                &.-danger {
                    color: var(--ww-color-red-500);
                }
                &.-warning {
                    color: var(--ww-color-yellow-500);
                }
            }

            .header-close {
                margin-left: auto;
                padding: 0;
                cursor: pointer;
            }
        }
        &.-popup {
            flex-grow: 1;
            position: relative;
            background-color: white;
            overflow: hidden;
        }
        &.-footer {
            flex-basis: 40px;
            min-height: 40px;
            display: flex;
            justify-content: center;
            overflow: hidden;
            button {
                margin: var(--ww-spacing-01);
            }
            &-enter-active,
            &-leave-active {
                transition: all 1s ease;
            }
            &-enter,
            &-leave-to {
                min-height: 0px;
                flex-basis: 0px;
            }
        }
        &-content {
            width: 100%;
            height: 100%;
            top: 0;
            position: absolute !important;
            overflow: auto;
            left: 0;
            transition: transform 0.5s ease;
            &.active {
                transform: translate3d(0, 0, 0);
            }
            &.previous {
                transform: translate3d(-100%, 0, 0);
            }
            &.next {
                transform: translate3d(100%, 0, 0);
            }
        }
    }
}
</style>
