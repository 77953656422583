import Vue from 'vue';
import VueI18n from 'vue-i18n';
import lang from './lang';

Vue.use(VueI18n);

// const locale = window.$cookies.get('wwLang') || navigator.language.split('-')[0];
const locale = 'en';

export default new VueI18n({
    fallbackLocale: 'en',
    locale,
    messages: lang,
});
