import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { concat } from 'apollo-link';
import router from '@/router';

// HTTP connexion to the API
const uriApi = process.env.VUE_APP_APOLLO_API_URL;

const httpLinkApi = new HttpLink({
    uri: uriApi,
    credentials: 'include',
});

const UPGRADE_BUTTONS = [
    { text: { en: 'Cancel' }, color: '-secondary', value: false, escape: true },
    { text: { en: 'Upgrade now' }, color: '-primary', value: true, enter: true },
];

// Cache implementation
const cacheApi = new InMemoryCache();
//Error handling
const errorLink = onError(({ graphQLErrors, operation }) => {
    if (graphQLErrors) {
        graphQLErrors.map(async ({ extensions, message }) => {
            if (extensions && extensions.code === 'METRICS_EXCEEDED') {
                let config = null;
                switch (message) {
                    case 'PUBLISH_UNAVAILABLE_PLUGINS':
                        config = {
                            title: 'You need to upgrade to perform this action',
                            description:
                                'You have added more plugins than what your plan allows. Please upgrade your plan to publish again.',
                            buttons: UPGRADE_BUTTONS,
                            link: 'workspaces-projects-plans',
                        };
                        break;
                    case 'PUBLISH_UNAVAILABLE_PLUGINS_PAID':
                        config = {
                            title: 'Plugin subscription required',
                            description:
                                'You have added a paid plugin to your project. Please go the editor to confirm your subscription and publish again.',
                            buttons: [
                                { text: { en: 'Cancel' }, color: '-secondary', value: false, escape: true },
                                { text: { en: 'Go to editor' }, color: '-primary', value: true, enter: true },
                            ],
                            action: () => {
                                window.location.href = `${process.env.VUE_APP_MANAGER_URL}/${operation.variables.designId}`;
                            },
                        };
                        break;
                    case 'CHECKPOINTS_LIMIT':
                        config = {
                            title: 'You have reached your checkpoints limit',
                            description:
                                'You cannot have more checkpoints in your current plan. Please contact us if you need more.',
                            buttons: UPGRADE_BUTTONS,
                            link: 'workspaces-projects-plans',
                        };
                        break;
                    case 'PUBLISH_UNAVAILABLE_AUTH_ROLES':
                        config = {
                            title: 'Roles and permissions not allowed in your current plan',
                            description:
                                'You have set up roles and permissions in your project. This feature is not available in your current plan. Please upgrade to publish or remove roles and permissions from your project.',
                            buttons: UPGRADE_BUTTONS,
                            link: 'workspaces-projects-plans',
                        };
                        break;
                }
                if (config) {
                    const confirm = await wwLib.wwModals.open({
                        title: { en: config.title },
                        text: { en: config.description },
                        buttons: config.buttons,
                    });
                    if (confirm) {
                        if (config.link) router.push({ name: config.link });
                        else if (config.action) config.action();
                    }
                }
            }
        });
    }
});

// Create the apollo clients
export default new ApolloClient({
    link: concat(errorLink, httpLinkApi),
    cache: cacheApi,
});
