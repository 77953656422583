<template>
    <div class="ww-manager-click-menu">
        <div class="btn" @click="show($event)">
            <i class="fas fa-ellipsis-v"></i>
        </div>
    </div>
</template>

<script>
import wwManagerMenu from './wwManagerMenu.vue';
import Vue from 'vue';

export default {
    name: 'wwManagerMenuButton',
    props: {
        actions: Array,
        item: [Boolean, String, Array, Object, Number],
        disabled: [Boolean, String],
    },
    computed: {},
    data() {
        return {
            wwManagerMenu: null,
            d_componentInstance: null,
        };
    },
    methods: {
        show(event) {
            if (this.d_componentInstance && this.d_componentInstance.$el) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }

            this.d_componentInstance = new this.wwManagerMenu({
                propsData: {
                    actions: this.actions,
                    x: event.clientX,
                    y: event.clientY,
                },
            });

            this.d_componentInstance.$mount();
            document.querySelector('#app').append(this.d_componentInstance.$el);

            this.d_componentInstance.$on('runAction', this.runAction);
            setTimeout(() => {
                window.addEventListener('click', this.close);
            }, 1);
        },
        close() {
            if (this.d_componentInstance && this.d_componentInstance.$el) {
                this.d_componentInstance.close();

                setTimeout(() => {
                    this.d_componentInstance.$el.remove();
                    this.d_componentInstance = null;
                }, 400);
            }

            window.removeEventListener('click', this.close);
        },
        runAction(action) {
            if (action && action.action && typeof action.action == 'function') {
                action.action(this.item ? this.item : undefined);
            }
            this.close();
        },
    },
    mounted() {
        this.wwManagerMenu = Vue.extend(wwManagerMenu);
    },
    beforeDestroy() {
        this.close();

        window.removeEventListener('click', this.close);
    },
};
</script>

<style scoped lang="scss">
.ww-manager-click-menu {
    position: relative;
    width: 30px;
    height: 30px;
    display: inline-block;

    .btn {
        width: 100%;
        height: 100%;
        border: 1px solid var(--ww-color-dark-600);
        color: var(--ww-color-dark-600);
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.2s ease;
        background-color: white;

        &:hover {
            box-shadow: 0px 0px 4px 0px var(--ww-color-dark-600);
        }
    }

    .menu {
        z-index: 1;
        position: absolute;
        top: 50%;
        right: 50%;
        width: 150px;
        background-color: white;
        box-shadow: 0px 0px 4px 0px var(--ww-color-dark-600);
        transition: transform 0.3s ease, opacity 0.3s ease;
        transform-origin: 100% 0%;
        transform: scale(0.5);
        opacity: 0;
        pointer-events: none;
        border-radius: 3px;
        text-align: left;
        overflow: hidden;

        &.up {
            top: auto;
            bottom: 50%;
            transform-origin: 100% 100%;
        }

        &.show {
            pointer-events: all;
            transform: scale(1);
            opacity: 1;
        }

        .action {
            padding: 10px;
            cursor: pointer;
            transition: background-color 0.2s ease;
            &:hover {
                background-color: #ececec;
            }

            &.red {
                color: var(--ww-color-red-500);
                &:hover {
                    color: white;
                    background-color: var(--ww-color-red-500);
                }
            }

            &.orange {
                color: var(--ww-color-yellow-700);
                &:hover {
                    color: white;
                    background-color: var(--ww-color-yellow-700);
                }
            }

            &.blue {
                color: var(--ww-color-blue-400);
                &:hover {
                    color: white;
                    background-color: var(--ww-color-blue-400);
                }
            }

            &.green {
                color: var(--ww-color-green-500);
                &:hover {
                    color: white;
                    background-color: var(--ww-color-green-500);
                }
            }

            &.yellow {
                color: var(--ww-color-yellow-500);
                &:hover {
                    color: white;
                    background-color: var(--ww-color-yellow-500);
                }
            }

            &.pink {
                color: var(--ww-color-purple-500);
                &:hover {
                    color: white;
                    background-color: var(--ww-color-purple-500);
                }
            }

            &.grey {
                color: var(--ww-color-dark-600);
                &:hover {
                    color: white;
                    background-color: var(--ww-color-dark-600);
                }
            }
        }
    }
}
</style>
