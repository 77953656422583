import Vue from 'vue';
import io from 'socket.io-client';

const socket = io(process.env.VUE_APP_API_URL.slice(0, -3), {
    autoConnect: false,
    path: '/v1/socket/dashboard',
    transports: ['websocket'],
    withCredentials: true,
});

const tryingToReconnectNotif = () => {
    return wwLib.wwNotification.open({
        text: {
            en: '<i class="fas fa-circle-notch fa-spin"></i> Trying to reconnect',
            fr: '<i class="fas fa-circle-notch fa-spin"></i> Reconnection',
        },
        color: 'orange',
        duration: 9999999,
    });
};

let reconnectNofif = null;

export default function () {
    return store => {
        window.addEventListener('beforeunload', () => {
            socket.disconnect();
        });
        /*=============================================m_ÔÔ_m=============================================\
            CONNECTION
        \================================================================================================*/
        socket.on('connect', () => {
            wwLib.wwLog.debug('SOCKET/EVENT', 'connected:', socket.connected);
        });
        socket.on('disconnect', () => {
            wwLib.wwLog.debug('SOCKET/EVENT', 'connected:', socket.connected);
        });
        socket.on('connected_elsewhere', async () => {
            wwLib.wwLog.debug('SOCKET/EVENT', 'connected_elsewhere');

            store.commit('v2/logoutUser');

            window.location.href = '/sign-in';
        });
        socket.on('signout', async () => {
            wwLib.wwLog.debug('SOCKET/EVENT', 'signout');

            store.commit('v2/logoutUser');

            window.location.href = '/sign-in';
        });
        socket.on('reconnecting', () => {
            if (!reconnectNofif) reconnectNofif = tryingToReconnectNotif();
        });
        socket.on('reconnect', () => {
            if (reconnectNofif) {
                reconnectNofif.close();
                reconnectNofif = null;
            }
        });
        /*=============================================m_ÔÔ_m=============================================\
            WEBSITE
        \================================================================================================*/
        socket.on('design/deploy/update', payload => {
            wwLib.wwLog.debug('SOCKET/EVENT', 'design/deploy/update', payload);
            store.commit('v2/updateDesignDeploy', { websiteId: payload.designId, deploy: payload });
        });
        /*=============================================m_ÔÔ_m=============================================\
            SOURCE CODE
        \================================================================================================*/
        socket.on('source_code/section/status', payload => {
            wwLib.wwLog.debug('SOCKET/EVENT', 'source_code/section/status', payload);
            store.commit('v2/updateSectionBaseSourceCodeStatus', {
                sectionBaseId: payload.sourceCodeId,
                sourceCodeStatus: payload,
            });
        });
        socket.on('source_code/wwobject/status', payload => {
            wwLib.wwLog.debug('SOCKET/EVENT', 'source_code/wwobject/status', payload);
            store.commit('v2/updateWwObjectBaseSourceCodeStatus', {
                wwObjectBaseId: payload.sourceCodeId,
                sourceCodeStatus: payload,
            });
        });
        socket.on('source_code/plugin/status', payload => {
            wwLib.wwLog.debug('SOCKET/EVENT', 'source_code/plugin/status', payload);
            store.commit('v2/updatePluginSourceCodeStatus', {
                pluginId: payload.sourceCodeId,
                sourceCodeStatus: payload,
            });
        });
        /*=============================================m_ÔÔ_m=============================================\
            STORE MUTATION
        \================================================================================================*/
        store.subscribe(async mutation => {
            switch (mutation.type) {
                case 'v2/setCurrentOrganizationId':
                    wwLib.wwLog.debug('SOCKET/STORE', mutation.type);
                    Vue.$cookies.set(
                        'ww-socket-session-id',
                        mutation.payload.organizationId,
                        null,
                        null,
                        process.env.VUE_APP_HOSTNAME
                    );
                    if (store.getters['v2/getCurrentOrganization'])
                        window.userflow.identify(store.state.v2.user.id, {
                            support: (store.getters['v2/getCurrentOrganization'].features || {}).support,
                        });
                    return;
                case 'v2/setOrganizations':
                    wwLib.wwLog.debug('SOCKET/STORE', mutation.type);
                    if (store.getters['v2/getCurrentOrganization'])
                        window.userflow.identify(store.state.v2.user.id, {
                            support: (store.getters['v2/getCurrentOrganization'].features || {}).support,
                        });
                    return;
                case 'v2/setUser':
                    wwLib.wwLog.debug('SOCKET/STORE', mutation.type);
                    return socket.connect();
            }
        });
    };
}
