export default {
    /*=============================================m_ÔÔ_m=============================================\
        General
    \================================================================================================*/
    maintenance: null,
    pageProgress: 0,
    fullPageLoading: false,
    /*=============================================m_ÔÔ_m=============================================\
        Users
    \================================================================================================*/
    user: {},
    /*=============================================m_ÔÔ_m=============================================\
        Organizations
    \================================================================================================*/
    organizations: {},
    currentOrganizationId: null,
    /*=============================================m_ÔÔ_m=============================================\
        Designs
    \================================================================================================*/
    designs: {},
    currentDesignId: null,
    /*=============================================m_ÔÔ_m=============================================\
        SectionBases
    \================================================================================================*/
    sectionBases: {},
    /*=============================================m_ÔÔ_m=============================================\
        WwObjectBases
    \================================================================================================*/
    wwObjectBases: {},
    /*=============================================m_ÔÔ_m=============================================\
        Plugins
    \================================================================================================*/
    plugins: {},
    /*=============================================m_ÔÔ_m=============================================\
        SelfHosting
    \================================================================================================*/
    selfHostingUpdateStatus: {},
};
