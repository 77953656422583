<template>
    <div id="wrapper">
        <div class="profile-main-loader">
            <div class="loader">
                <svg class="circular-loader" viewBox="25 25 50 50">
                    <circle
                        class="loader-path"
                        cx="50"
                        cy="50"
                        r="20"
                        fill="none"
                        stroke="var(--ww-color-white)"
                        stroke-width="3"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
html,
body {
    height: 100%;
}

#wrapper {
    position: relative;
    height: 100%;
}

.profile-main-loader {
    width: 45px;
    z-index: 9000 !important;
}

.profile-main-loader .loader {
    position: relative;
    margin: 0px auto;
    width: var(--ww-font-size-05);
    height: var(--ww-font-size-05);
}
.profile-main-loader .loader:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.circular-loader {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
}

.loader-path {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}
@-webkit-keyframes color {
    0% {
        stroke: var(--ww-color-white);
    }
    40% {
        stroke: var(--ww-color-white);
    }
    66% {
        stroke: var(--ww-color-white);
    }
    80%,
    90% {
        stroke: var(--ww-color-white);
    }
}
@keyframes color {
    0% {
        stroke: var(--ww-color-white);
    }
    40% {
        stroke: var(--ww-color-white);
    }
    66% {
        stroke: var(--ww-color-white);
    }
    80%,
    90% {
        stroke: var(--ww-color-white);
    }
}
</style>
