<template>
    <div class="ww-tab" :class="{ tag: tag && tag.text }">
        <slot></slot>
        <div v-if="tag && tag.text" :class="tag.color" class="ww-tab-tag">{{ tag.text }}</div>
    </div>
</template>

<script>
export default {
    name: 'wwTab',
    props: {
        tag: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.ww-tab {
    position: relative;
    padding: var(--ww-spacing-01) var(--ww-spacing-02);
    margin-right: var(--ww-spacing-02);
    border-radius: var(--ww-spacing-01);
    color: var(--ww-color-dark-600);
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    transition: all 0.4s;
    white-space: nowrap;

    &.tag {
        margin-right: var(--ww-spacing-04);
    }

    &:hover {
        color: var(--ww-color-blue-500);
        transition: all 0.4s;
    }

    &-tag {
        position: absolute;
        top: -12px;
        left: calc(100% - 12px);
        border-radius: var(--ww-spacing-01);
        font-size: var(--ww-font-size-02);

        &.red {
            color: white;
            padding: var(--ww-spacing-00) var(--ww-spacing-01);
            background-color: var(--ww-color-red-500);
        }

        &.green {
            color: white;
            padding: var(--ww-spacing-00) var(--ww-spacing-01);
            background-color: var(--ww-color-green-500);
        }

        &.blue {
            color: white;
            padding: var(--ww-spacing-00) var(--ww-spacing-01);
            background-color: var(--ww-color-blue-500);
        }

        &.yellow {
            color: white;
            padding: var(--ww-spacing-00) var(--ww-spacing-01);
            background-color: var(--ww-color-yellow-500);
        }

        &.dark {
            color: white;
            padding: var(--ww-spacing-00) var(--ww-spacing-01);
            background-color: var(--ww-color-dark-700);
        }
    }
}
</style>
