import wwLib from '@/wwLib';

let wwShortcutsSelf;

export default {
    shortcuts: [],
    keys: {},
    modifiers: {
        ALT: false,
        CTRL: false,
        SHIFT: false,
    },

    init() {
        wwShortcutsSelf = this;

        // window.addEventListener('keypress', function(event){ wwLib.wwLog.debug(event); if(event.ctrlKey && event.code == 'Space'){ event.stopPropagation(); event.preventDefault(); wwLib.wwLog.debug('CTRL - Space');} });

        wwLib.getFrontWindow().addEventListener('keypress', this.onKey);
        wwLib.getFrontWindow().addEventListener('keyup', this.onKey);
        wwLib.getFrontWindow().addEventListener('keydown', this.onKey);

        if (wwLib.getManagerWindow()) {
            wwLib.getManagerWindow().addEventListener('keypress', this.onKey);
            wwLib.getManagerWindow().addEventListener('keyup', this.onKey);
            wwLib.getManagerWindow().addEventListener('keydown', this.onKey);
        }
    },
    isElementActive(element) {
        if (!element) {
            return true;
        }

        if (typeof element == 'string') {
            if (wwLib.getFrontDocument().querySelector(element)) {
                element = wwLib.getFrontDocument().querySelector(element);
            } else {
                element = wwLib.getManagerDocument().querySelector(element);
            }
        }

        if (Array.isArray(element)) {
            return wwLib.wwLog.info('wwShortcuts : multiples elements not supported yet');
        }

        if (!element) {
            return true;
        }

        let activeEl = document.activeElement;

        while (activeEl && activeEl != element) {
            activeEl = activeEl.parentElement;
        }

        if (activeEl == element) {
            return true;
        }

        return false;
    },

    add(options, action) {
        options = options || {};
        if (!options.key || options.fn) {
            return wwLib.wwLog.error('No key and/or no function specified');
        }

        if (options.modifiers && typeof options.modifiers == 'string') {
            options.modifiers = [options.modifiers];
        }

        if (!options.action && action && typeof action == 'function') {
            options.action = action;
        }

        this.shortcuts.push({
            tag: options.tag || '',
            element: options.element || null,
            key: options.key,
            modifiers: options.modifiers,
            type: options.type || 'keydown', //down / up / toggle
            action: options.action,
            manager: options.manager || false,
            stopEvent: options.stopEvent || false,
        });
    },

    remove(tag) {
        for (let i = this.shortcuts.length - 1; i > -1; i--) {
            if (this.shortcuts[i].tag == tag) {
                this.shortcuts.splice(i, 1);
            }
        }
    },

    onKey(event) {
        let key = event.key.toUpperCase();
        let type = event.type;

        wwShortcutsSelf.modifiers = {
            ALT: event.altKey,
            CTRL: event.ctrlKey,
            SHIFT: event.shiftKey,
        };

        wwLib.$store.dispatch('manager/setShowSpacings', event.ctrlKey);

        //Correct keydown repeating problem.
        if (event.type == 'keydown') {
            if (wwShortcutsSelf.keys[key]) {
                type = 'keypress';
            }
            wwShortcutsSelf.keys[key] = true;
        } else if (event.type == 'keyup') {
            wwShortcutsSelf.keys[key] = false;
        }

        for (let i = wwShortcutsSelf.shortcuts.length - 1; i > -1; i--) {
            const shortcut = wwShortcutsSelf.shortcuts[i];
            if ((shortcut.type == 'keytoggle' && (type == 'keyup' || type == 'keydown')) || shortcut.type == type) {
                if (shortcut.key.toUpperCase() != key) {
                    continue;
                }

                if (!wwShortcutsSelf.hasModifiers(shortcut.modifiers)) {
                    continue;
                }

                // if (!wwShortcutsSelf.isElementActive(shortcut.element)) {
                //     return;
                // }

                if (shortcut.stopEvent) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                shortcut.action();
                return;
            }
        }
    },

    hasModifiers(shortcutModifiers) {
        if (!shortcutModifiers) {
            return true;
        }
        if (typeof shortcutModifiers == 'string') {
            shortcutModifiers = [shortcutModifiers];
        }

        for (const mod of shortcutModifiers) {
            if (!wwShortcutsSelf.modifiers[mod.toUpperCase()]) {
                return false;
            }
        }
        return true;
    },

    isPressed(key) {
        if (!key) {
            return;
        }

        key = key.toUpperCase();

        return wwShortcutsSelf.keys[key] || false;
    },
};
