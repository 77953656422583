import axios from 'axios';

const apiUrl = process.env.VUE_APP_API_URL;

import store from '@/store';
window.$store = store;

/*=============================================m_ÔÔ_m=============================================\
    CONNEXION
\================================================================================================*/
const getIsConnected = async function (workspaceId) {
    try {
        const result = await axios({
            method: 'GET',
            url: `${apiUrl}/github/${workspaceId}/user`,
        });
        if (result && result.data) {
            return true;
        }
    } catch (error) {
        wwLib.wwLog.error('error', error);
    }
    return false;
};

const deleteAccessToken = async function (workspaceId) {
    try {
        await axios({
            method: 'DELETE',
            url: `${apiUrl}/github/${workspaceId}/delete_access_token`,
        });
    } catch (e) {
        await wwLib.wwNotification.open({
            text: {
                en: '<b>An error occured, please contact the support team at support@weweb.io</b> ',
                fr: "<b>Une erreur s'est produite, veuillez contacter le support à support@weweb.io</b>",
            },
            color: 'red',
            duration: '5000',
        });
        throw e;
    }
};

const waitForAccessToken = async function () {
    let i = 10;

    while (i-- > 0) {
        const result = await axios({
            method: 'GET',
            url: apiUrl + '/github/has_access_token',
        });

        if (result && result.data && result.data.hasGithubAccessToken) {
            return result.data.hasGithubAccessToken;
        }
    }

    throw new Error('No token access found');
};

const openGithubWindow = async function (userId, workspaceId) {
    return new Promise(resolve => {
        const githubWindow = window.open(
            `https://github.com/login/oauth/authorize?client_id=${
                process.env.VUE_APP_GITHUB_OAUTH_CLIENT_ID
            }&scope=repo,write:repo_hook,read:org,read:user&prompt=select_account&redirect_uri=${encodeURI(
                `${process.env.VUE_APP_API_URL}/github/callback?data=${encodeURIComponent(
                    `{"userId":"${userId}","workspaceId":"${workspaceId}"}`
                )}`
            )}`,
            'Connect to Github',
            'menubar=no, status=no, scrollbars=no, menubar=no, width=600, height=650'
        );

        let timer = setInterval(function () {
            if (githubWindow.closed) {
                clearInterval(timer);
                return resolve();
            }
        }, 1000);
    });
};

/*=============================================m_ÔÔ_m=============================================\
    ORGANIZATIONS / REPOS
\================================================================================================*/
const getOrganizations = async function (workspaceId) {
    if (!_.isEmpty(store.getters['github/getOrganizations'])) {
        return store.getters['github/getOrganizations'];
    }
    const result = await axios({
        method: 'GET',
        url: `${apiUrl}/github/${workspaceId}/organizations`,
    });

    store.dispatch('github/setOrganizations', result.data);

    return result.data;
};
const getOrgRepos = async function (workspaceId, org) {
    if (!_.isEmpty(store.getters['github/getRepos'](org))) {
        return store.getters['github/getRepos'](org);
    }
    const result = await axios({
        method: 'GET',
        url: `${apiUrl}/github/${workspaceId}/org_repos?org=${org}`,
    });

    store.dispatch('github/setRepos', {
        organizationLogin: org,
        repos: result.data,
    });

    return result.data;
};
const getUserRepos = async function (workspaceId, org) {
    if (!_.isEmpty(store.getters['github/getRepos']('user'))) {
        return store.getters['github/getRepos']('user');
    }
    const result = await axios({
        method: 'GET',
        url: `${apiUrl}/github/${workspaceId}/user_repos?org=${org}`,
    });

    store.dispatch('github/setRepos', {
        organizationLogin: 'user',
        repos: result.data,
    });

    return result.data;
};
const getRepoBranches = async function (workspaceId, owner, repo) {
    if (!_.isEmpty(store.getters['github/getBranches'](owner, repo))) {
        return store.getters['github/getBranches'](owner, repo);
    }
    const result = await axios({
        method: 'GET',
        url: `${apiUrl}/github/${workspaceId}/repo_branches?owner=${owner}&repo=${repo}`,
    });

    store.dispatch('github/setBranches', {
        organizationLogin: owner,
        repoName: repo,
        branches: result.data,
    });

    return result.data;
};

/*=============================================m_ÔÔ_m=============================================\
    WEBHOOKS
\================================================================================================*/
const createWebhook = async function (owner, repo) {
    const result = await axios({
        method: 'POST',
        url: apiUrl + '/github/create_webhook',
        data: {
            owner,
            repo,
        },
    });

    return result.data;
};

export {
    getIsConnected,
    deleteAccessToken,
    waitForAccessToken,
    openGithubWindow,
    getOrganizations,
    getOrgRepos,
    getUserRepos,
    getRepoBranches,
    createWebhook,
};
