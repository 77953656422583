import apollo from '@/apollo';
import {
    // Auth
    LOGIN_MANAGER,
    LOGOUT,
    // Users
    GET_ME,
    GET_USERS,
    GET_USER,
    SIGN_UP_USER,
    SIGN_UP_USER_TO_ORGANIZATION,
    UPDATE_FORGOTTEN_PASSWORD,
    SEND_MAIL_FOR_NEW_PASSWORD,
    UPDATE_USER,
    // Admin
    ADD_WEWEB_ADMIN,
    REMOVE_WEWEB_ADMIN,
} from './user.graphql';

export default {
    /*=============================================m_ÔÔ_m=============================================\
        Auth
    \================================================================================================*/
    async loginManager(email, password) {
        const result = await apollo.mutate({
            mutation: LOGIN_MANAGER,
            variables: {
                email,
                password,
            },
        });
        if (!result) throw new Error('FAILED_TO_LOGIN_MANAGER');

        return result.data.loginManager;
    },
    async logout() {
        const result = await apollo.mutate({
            mutation: LOGOUT,
        });
        if (!result) throw new Error('FAILED_TO_LOGOUT');

        return result.data.logout;
    },
    /*=============================================m_ÔÔ_m=============================================\
        Users
    \================================================================================================*/
    async getMe() {
        const result = await apollo.query({
            query: GET_ME,
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_ME');

        return result.data.getMe;
    },
    async getUsers() {
        const result = await apollo.query({
            query: GET_USERS,
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_USERS');

        return result.data.getUsers;
    },
    async getUser(id) {
        const result = await apollo.query({
            query: GET_USER,
            variables: {
                id,
            },
        });
        if (!result) throw new Error('FAILED_TO_GET_USER');

        return result.data.getUser;
    },
    async signUpUser(name, email, password, referralId) {
        const result = await apollo.mutate({
            mutation: SIGN_UP_USER,
            variables: {
                name,
                email,
                password,
                referralId,
            },
        });
        if (!result) throw new Error('FAILED_TO_SIGN_UP_USER');

        return result.data.signUpUser;
    },
    async signUpUserToOrganization(password, token) {
        const result = await apollo.mutate({
            mutation: SIGN_UP_USER_TO_ORGANIZATION,
            variables: {
                password,
                token,
            },
        });
        if (!result) throw new Error('FAILED_TO_SIGN_UP_USER_TO_ORGANIZATION');

        return result.data.signUpUserToOrganization;
    },
    async updateForgottenPassword(token, newPassword) {
        const result = await apollo.mutate({
            mutation: UPDATE_FORGOTTEN_PASSWORD,
            variables: {
                token,
                newPassword,
            },
        });
        if (!result) throw new Error('FAILED_TO_UPDATE_FORGOTTEN_PASSWORD');

        return result.data.updateForgottenPassword;
    },
    async sendMailForNewPassword(email) {
        const result = await apollo.mutate({
            mutation: SEND_MAIL_FOR_NEW_PASSWORD,
            variables: {
                email,
            },
        });
        if (!result) throw new Error('FAILED_TO_SEND_MAIL_FOR_NEW_PASSWORD');

        return result.data.sendMailForNewPassword;
    },
    async updateUser(id, email, name, picture) {
        const result = await apollo.mutate({
            mutation: UPDATE_USER,
            variables: {
                id,
                email,
                name,
                picture,
            },
        });
        if (!result) throw new Error('FAILED_TO_UPDATE_USER');

        return result.data.updateUser;
    },
    /*=============================================m_ÔÔ_m=============================================\
        Admin
    \================================================================================================*/
    async addWewebAdmin(userId) {
        const result = await apollo.mutate({
            mutation: ADD_WEWEB_ADMIN,
            variables: {
                userId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_ADD_WEWEB_ADMIN');

        return result.data.addWewebAdmin;
    },
    async removeWewebAdmin(userId) {
        const result = await apollo.mutate({
            mutation: REMOVE_WEWEB_ADMIN,
            variables: {
                userId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_REMOVE_WEWEB_ADMIN');

        return result.data.removeWewebAdmin;
    },
};
