// initial state
const state = {
    organizations: {},
    repos: {},
    branches: {},
};

// getters
const getters = {
    getOrganizations(state) {
        return state.organizations;
    },
    getOrganization(state) {
        return function (organizationLogin) {
            return state.organizations[organizationLogin];
        };
    },
    getRepos(state) {
        return function (organizationLogin) {
            const repos = [];
            if (!state.repos) {
                return repos;
            }
            for (let key in state.repos) {
                if (key.startsWith(organizationLogin + '/')) {
                    repos.push(state.repos[key]);
                }
            }
            return repos;
        };
    },
    getRepo(state) {
        return function (organizationLogin, repoName) {
            return state.repos[organizationLogin + '/' + repoName];
        };
    },
    getBranches(state) {
        return function (organizationLogin, repoName) {
            const branches = [];
            if (!state.branches) {
                return branches;
            }
            for (let key in state.branches) {
                if (key.startsWith(organizationLogin + '/' + repoName + '/')) {
                    branches.push(state.branches[key]);
                }
            }
            return branches;
        };
    },
    getBranch(state) {
        return function (organizationLogin, repoName, branchName) {
            return state.branches[organizationLogin + '/' + repoName + '/' + branchName];
        };
    },
};

// actions
const actions = {
    setOrganizations({ commit }, organizations) {
        commit('setOrganizations', organizations);
    },
    setRepos({ commit }, options) {
        commit('setRepos', options);
    },
    setBranches({ commit }, options) {
        commit('setBranches', options);
    },
};

// mutations
const mutations = {
    setOrganizations(state, newOrganizations) {
        state.organizations = newOrganizations;
    },
    setRepos(state, options) {
        if (!options.organizationLogin || !options.repos) {
            return;
        }

        for (const repo of options.repos) {
            state.repos[options.organizationLogin + '/' + repo.name] = repo;
        }
    },
    setBranches(state, options) {
        if (!options.organizationLogin || !options.repoName || !options.branches) {
            return;
        }

        for (const branch of options.branches) {
            state.branches[options.organizationLogin + '/' + options.repoName + '/' + branch.name] = branch;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
