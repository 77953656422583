import apollo from '@/apollo';
import {
    // Organization
    CREATE_ORGANIZATION,
    GET_ORGANIZATIONS,
    GET_ORGANIZATION,
    UPDATE_ORGANIZATION,
    DELETE_ORGANIZATION,
    // Member
    GET_ORGANIZATION_MEMBERS,
    UPDATE_ORGANIZATION_MEMBER_ROLE,
    DELETE_ORGANIZATION_MEMBER,
    // Invitation
    CREATE_ORGANIZATION_INVITATIONS,
    GET_ORGANIZATION_INVITATIONS,
    RESEND_ORGANIZATION_INVITATION,
    DELETE_ORGANIZATION_INVITATION,
    // Contact Us
    CONTACT_US,
    //Self-host
    GENERATE_SELF_HOST_PUBLIC_KEY,
    GENERATE_SELF_HOST_PRIVATE_KEY,
    TEST_SELF_HOST_CONFIG,
    GET_SELF_HOST_UPDATE_STATUS,
} from './workspace.graphql';

const apiUrl = process.env.VUE_APP_API_URL;

export default {
    /*=============================================m_ÔÔ_m=============================================\
        Organization
    \================================================================================================*/
    async createOrganization(name) {
        const result = await apollo.mutate({
            mutation: CREATE_ORGANIZATION,
            variables: {
                name,
            },
        });
        if (!result) throw new Error('FAILED_TO_CREATE_ORGANIZATION');

        return result.data.createOrganization;
    },
    async getOrganizations() {
        const result = await apollo.query({
            query: GET_ORGANIZATIONS,
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_ORGANIZATIONS');

        return result.data.getOrganizations;
    },
    async getOrganization(organizationId) {
        const result = await apollo.query({
            query: GET_ORGANIZATION,
            variables: {
                organizationId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_ORGANIZATION');

        return result.data.getOrganization;
    },
    async getOrganizationInfo(organizationId) {
        const { data } = await axios.get(`${apiUrl}/organizations/${organizationId}/info`);
        return data;
    },
    async updateOrganization(organizationId, name, selfHostSettings) {
        const result = await apollo.mutate({
            mutation: UPDATE_ORGANIZATION,
            variables: {
                organizationId,
                name,
                selfHostSettings,
            },
        });
        if (!result) throw new Error('FAILED_TO_UPDATE_ORGANIZATION');

        return result.data.updateOrganization;
    },
    async deleteOrganization(organizationId) {
        const result = await apollo.mutate({
            mutation: DELETE_ORGANIZATION,
            variables: {
                organizationId,
            },
        });
        if (!result) throw new Error('FAILED_TO_DELETE_ORGANIZATION');

        return result.data.deleteOrganization;
    },
    /*=============================================m_ÔÔ_m=============================================\
        Member
    \================================================================================================*/
    async getOrganizationMembers(organizationId) {
        const result = await apollo.query({
            query: GET_ORGANIZATION_MEMBERS,
            variables: {
                organizationId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_ORGANIZATION_MEMBERS');

        return result.data.getOrganizationMembers;
    },
    async updateOrganizationMemberRole(organizationId, userId, role) {
        const result = await apollo.mutate({
            mutation: UPDATE_ORGANIZATION_MEMBER_ROLE,
            variables: {
                organizationId,
                userId,
                role,
            },
        });
        if (!result) throw new Error('FAILED_TO_UPDATE_ORGANIZATION_MEMBER_ROLE');

        return result.data.updateOrganizationMemberRole;
    },
    async deleteOrganizationMember(organizationId, userId) {
        const result = await apollo.mutate({
            mutation: DELETE_ORGANIZATION_MEMBER,
            variables: {
                organizationId,
                userId,
            },
        });
        if (!result) throw new Error('FAILED_TO_DELETE_ORGANIZATION_MEMBER');

        return result.data.deleteOrganizationMember;
    },
    /*=============================================m_ÔÔ_m=============================================\
        Invitation
    \================================================================================================*/
    async createOrganizationInvitations(organizationId, users, message) {
        const result = await apollo.query({
            query: CREATE_ORGANIZATION_INVITATIONS,
            variables: {
                organizationId,
                users,
                message,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_CREATE_ORGANIZATION_INVITE');

        return result.data.createOrganizationInvitations;
    },
    async getOrganizationInvitations(organizationId, status) {
        const result = await apollo.query({
            query: GET_ORGANIZATION_INVITATIONS,
            variables: {
                organizationId,
                status: status,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_ORGANIZATION_INVITATIONS');

        return result.data.getOrganizationInvitations;
    },
    async resendOrganizationInvitation(organizationId, invitationId) {
        const result = await apollo.query({
            query: RESEND_ORGANIZATION_INVITATION,
            variables: {
                organizationId,
                invitationId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_RESEND_ORGANIZATION_INVITATION');

        return result.data.resendOrganizationInvitation;
    },
    async deleteOrganizationInvitation(organizationId, invitationId) {
        const result = await apollo.query({
            query: DELETE_ORGANIZATION_INVITATION,
            variables: {
                organizationId,
                invitationId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DELETE_ORGANIZATION_INVITATION');

        return result.data.deleteOrganizationInvitation;
    },
    /*=============================================m_ÔÔ_m=============================================\
        Contact Us
    \================================================================================================*/
    async contactUs(organizationId, userId, contactType, message) {
        const result = await apollo.query({
            query: CONTACT_US,
            variables: {
                organizationId,
                userId,
                contactType,
                message,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_CONTACT US');

        return result.data.contactUs;
    },
    /*=============================================m_ÔÔ_m=============================================\
        Self hosting
    \================================================================================================*/
    async generateSelfHostPublicKey(organizationId) {
        const result = await apollo.mutate({
            mutation: GENERATE_SELF_HOST_PUBLIC_KEY,
            variables: {
                organizationId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GENERATE_SELF_HOST_PUBLIC_KEY');

        return result.data.generateSelfHostPublicKey;
    },
    async generateSelfHostPrivateKey(organizationId) {
        const result = await apollo.mutate({
            mutation: GENERATE_SELF_HOST_PRIVATE_KEY,
            variables: {
                organizationId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GENERATE_SELF_HOST_PRIVATE_KEY');

        return result.data.generateSelfHostPrivateKey;
    },
    async testSelfHostConfig(organizationId, publicKey, privateKey) {
        const result = await apollo.query({
            query: TEST_SELF_HOST_CONFIG,
            variables: {
                organizationId,
                publicKey,
                privateKey,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_TEST_SELF_HOST_CONFIG');

        return result.data.testSelfHostConfig;
    },
    async getSelfHostUpdateStatus(organizationId) {
        try {
            const result = await apollo.query({
                query: GET_SELF_HOST_UPDATE_STATUS,
                variables: {
                    organizationId,
                },
                fetchPolicy: 'no-cache',
            });
            if (!result) throw new Error('FAILED_TO_GET_SELF_HOST_UPDATE_STATUS');

            return result.data.getSelfHostUpdateStatus;
        } catch (error) {
            return {};
        }
    },
};
