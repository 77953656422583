<template>
    <transition name="ww-editor-fly-out-transition">
        <div v-show="isVisible" class="ww-editor-fly-out">
            <slot></slot>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'wwEditorFlyOut',
    props: {
        isVisible: { type: Boolean },
    },
};
</script>

<style lang="scss" scoped>
.ww-editor-fly-out {
    position: absolute;
    pointer-events: inherit;
    width: max-content;
    padding: var(--ww-spacing-02) var(--ww-spacing-03);
    border: 1px solid var(--ww-color-dark-200);
    border-radius: var(--ww-spacing-01);
    background-color: var(--ww-color-white);
    color: var(--ww-color-dark-500);
    box-shadow: var(--ww-box-shadow-02);
}
.ww-editor-fly-out-transition-enter-active,
.ww-editor-fly-out-transition-leave-active {
    transition: opacity 0.5s;
}
.ww-editor-fly-out-transition-enter,
.ww-editor-fly-out-transition-leave-to {
    opacity: 0;
}
</style>
