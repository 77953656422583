<template>
    <div class="ww-popup-rename-website ww-scroll-bar">
        <div class="container">
            <label for="wewebPreviewURL" class="label label-m" style="margin-top: var(--ww-spacing-03)">{{
                $t('websites.settings.wewebPreviewURL')
            }}</label>
            <span class="label label-s">Without ending '/'</span>
            <input
                name="wewebPreviewURL"
                class="input ww-editor-input -large"
                type="text"
                :placeholder="$t('websites.settings.wewebPreviewURLPlaceholder')"
                v-model="wewebPreviewURL"
                autofocus
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import services from '@/services';

export default {
    name: 'wwPopupWebsiteSelfHostWewebPreviewURL',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
        active: Boolean,
    },
    data() {
        return {
            wewebPreviewURL: '',
        };
    },
    computed: {
        ...mapGetters({
            workspace: 'v2/getCurrentOrganization',
        }),
    },
    methods: {
        ...mapActions({
            fetchOrganization: 'v2/fetchOrganization',
        }),
        init() {
            this.wewebPreviewURL = this.options.data.workspace.selfHostSettings
                ? this.options.data.workspace.selfHostSettings.wewebPreviewURL
                : '';
            this.options.setButtonState('NEXT', 'ok');
        },
        async beforeNext() {
            this.options.setLoadingStatus(true);
            try {
                const selfHostSettings = this.options.data.workspace.selfHostSettings || {};
                selfHostSettings.wewebPreviewURL = this.wewebPreviewURL;
                if (selfHostSettings.wewebPreviewURL.endsWith('/')) {
                    selfHostSettings.wewebPreviewURL = selfHostSettings.wewebPreviewURL.slice(0, -1);
                }

                await services.api.workspace.updateOrganization(
                    this.workspace.id,
                    this.workspace.name,
                    selfHostSettings
                );
                this.fetchOrganization(this.workspace.id);

                wwLib.wwNotification.open({
                    text: {
                        en: '<b>weweb-server URL saved</b>',
                    },
                    color: 'green',
                });
            } catch (err) {
                wwLib.wwLog.error(err);
                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Error while saving weweb-server URL</b>',
                    },
                    color: 'red',
                });
            }
            this.options.setLoadingStatus(false);
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.ww-popup-rename-website {
    width: 100%;
    display: flex;
    justify-content: center;

    .container {
        margin-top: var(--ww-spacing-04);
        width: 100%;
        display: flex;
        flex-direction: column;
        .label {
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
        }
        .input {
            width: 100%;
        }
        .requiredLabel {
            position: absolute;
            right: 0;
            color: var(--ww-color-dark-400);
        }
        .result {
            margin: var(--ww-spacing-04) auto;
            &.error {
                color: var(--ww-color-red-500);
            }
            &.valid {
                color: var(--ww-color-green-500);
            }
        }
    }
}
</style>
