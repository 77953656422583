<template>
    <div class="ww-popup-workspace-invite">
        <div class="popup-wrapper ww-scroll-bar">
            <div class="invite-wrapper" v-for="(user, index) in users" :key="index">
                <div class="input-wrapper">
                    <input
                        name="mailInput"
                        type="email"
                        class="ww-editor-input -large invit-input"
                        v-model="user.email"
                        :placeholder="$t('utils.email')"
                    />
                </div>

                <div class="input-wrapper">
                    <input
                        name="nameInput"
                        type="text"
                        class="ww-editor-input -large invit-input"
                        v-model="user.name"
                        :placeholder="$t('utils.name')"
                    />
                </div>
                <div class="fit-wrapper" v-if="!isGuest">
                    <wwEditorSelect
                        class="element__select caption-m"
                        :options="roleOptions"
                        v-model="user.role"
                        @change="setRole($event, index)"
                        placeholder="Select"
                        large
                    />
                </div>
                <div class="remove-user">
                    <wwEditorIcon
                        @click="removeInvite(index)"
                        class="remove-user-icon"
                        :class="{ disabled: lastField }"
                        name="delete"
                    />
                </div>
            </div>
            <p class="caption-s email-alert" v-if="!validEmail">Please make sure to enter valid email.</p>
            <div class="add-member-wrapper">
                <button class="ww-editor-button -icon -primary" @click="inviteMembers">
                    <wwEditorIcon class="ww-editor-button-icon -left" name="add" small />{{
                        $t('popup.inviteMembers.addAnotherMember')
                    }}
                </button>
            </div>
        </div>

        <template v-if="isGuest">
            <div class="caption-m text-stale-500 mb-4">
                <b>These free invitations will only work if your guests hold an agency partner plan.</b>
            </div>
            <div v-if="membersCount + users.length > 10" class="ww-box border-red-500 label-l text-stale-900 p-4 mb-4">
                Contact us to have more guests.
            </div>
        </template>
        <template v-else>
            <UpgradeCard
                v-if="needToUpgradePlan"
                title="To add more members please upgrade your workspace plan."
                type="workspace"
                small
                hasPlan
            />
            <div v-else-if="needToPurchaseSeats" class="ww-box border-red-500 label-l text-stale-900 p-4 mb-4">
                You need to purchase more seats.
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import services from '@/services';
import UpgradeCard from '@/components/elements/UpgradeCard.vue';

export default {
    name: 'wwPopupWorkspaceInvite',
    components: { UpgradeCard },
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            membersCount: 1,
            message: '',
            users: [
                {
                    email: '',
                    name: '',
                    role: 'member',
                },
            ],
            roleOptions: [
                {
                    label: this.$t('organizations.members.roles.member'),
                    value: 'member',
                },
                {
                    label: this.$t('organizations.members.roles.admin'),
                    value: 'admin',
                },
            ],
            validEmail: true,
        };
    },
    computed: {
        ...mapGetters({
            currentOrganization: 'v2/getCurrentOrganization',
        }),
        lastField() {
            return this.users.length < 2;
        },
        needToPurchaseSeats() {
            return this.users.length + this.membersCount > this.currentOrganization.features.members;
        },
        needToUpgradePlan() {
            return this.users.length + this.membersCount > this.currentOrganization.features.maxMembers;
        },
        isGuest() {
            return this.options.data.type === 'guest';
        },
    },
    watch: {
        users: {
            deep: true,
            handler() {
                this.setButtonState();
            },
        },
        needToPurchaseSeats() {
            this.setButtonState();
        },
        needToUpgradePlan() {
            this.setButtonState();
        },
    },
    methods: {
        async init() {
            try {
                this.setButtonState();
                this.options.result.users = this.users;
            } catch (error) {
                wwLib.wwLog.error(error);
            }
        },
        setMessage() {
            try {
                this.options.result.message = this.message;
            } catch (error) {
                wwLib.wwLog.error(error);
            }
        },
        setRole(role, index) {
            try {
                this.users[index].role = role;
                this.setButtonState();
            } catch (error) {
                wwLib.wwLog.error(error);
            }
        },
        addCustomMessage() {
            try {
                this.toggleMessage = !this.toggleMessage;
            } catch (error) {
                wwLib.wwLog.error(error);
            }
        },
        inviteMembers() {
            try {
                const user = {
                    email: '',
                    name: '',
                    role: 'member',
                };
                this.users.push(user);
                this.setButtonState();
            } catch (error) {
                wwLib.wwLog.error(error);
            }
        },
        removeInvite(index) {
            if (this.lastField) return;
            try {
                this.users.splice(index, 1);
                this.setButtonState();
            } catch (error) {
                wwLib.wwLog.error(error);
            }
        },
        checkInputs(user) {
            if (user.email.length === 0) {
                this.validEmail = true;
                return;
            }
            if (!user.email.length || !user.name.length) return;
            else this.validEmail = true;

            this.validEmail = this.validateEmail(user.email) ? true : false;
        },
        validateEmail(email) {
            try {
                return /\S+@\S+\.\S+/.test(email);
            } catch (error) {
                wwLib.wwLog.error(error);
            }
        },
        setButtonState() {
            try {
                let error = false;
                for (const user of this.users) {
                    if (!user.name || !this.validateEmail(user.email)) error = true;
                }
                if (this.isGuest) {
                    if (this.membersCount + this.users.length > 10) error = true;
                } else {
                    if (this.needToPurchaseSeats || this.needToUpgradePlan) error = true;
                }
                if (!error) {
                    this.options.setButtonState('NEXT', 'ok');
                    return true;
                } else {
                    this.options.setButtonState('NEXT', 'disabled');
                    return false;
                }
            } catch (error) {
                wwLib.wwLog.error(error);
            }
        },
        async beforeNext() {
            this.options.setLoadingStatus(true);
            try {
                if (this.isGuest) {
                    this.users = this.users.map(user => ({ ...user, role: 'guest' }));
                }
                const invitations = await services.api.workspace.createOrganizationInvitations(
                    this.currentOrganization.id,
                    this.users,
                    this.message
                );
                if (!invitations) throw new Error('FAILED_TO_RESEND_INVITATION');
                this.isLoading = false;
                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Invitation successfully sent</b>',
                        fr: '<b>Invitation envoyée avec succès</b>',
                    },
                    color: 'green',
                    duration: 5000,
                });
            } catch (err) {
                if (err.graphQLErrors && err.graphQLErrors[0] && err.graphQLErrors[0].message === 'MEMBERS_LIMIT') {
                    wwLib.wwLog.error(err);
                } else {
                    wwLib.wwNotification.open({
                        text: {
                            en: '<b>Error while sending the invitations.</b><br/>You cannot have more than <b>20</b> pending invitations in this workspace.',
                            fr: "<b>Erreur lors de l'envoie des invitations.</b><br/>Vous ne pouvez pas avoir plus de <b>20</b> invitations en attente dans ce workspace.",
                        },
                        color: 'red',
                        duration: 5000,
                    });
                }
            }
            this.options.setLoadingStatus(false);
        },
    },
    mounted() {
        this.membersCount = this.options.data.members.filter(member =>
            this.isGuest ? member.role === 'guest' : member.role !== 'guest'
        );
        this.setButtonState();
    },
};
</script>

<style scoped lang="scss">
.ww-popup-workspace-invite {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;

    .email-alert {
        color: var(--ww-color-red-500);
    }

    .invite-wrapper {
        margin: var(--ww-spacing-03) 0 var(--ww-spacing-04) 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .input-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 42%;

            .label {
                color: var(--ww-color-dark-500);
                position: absolute;
                top: 0px;
                transform: translateY(-105%);
            }

            .invit-input {
                width: 100%;
            }
        }
    }

    .add-member-wrapper {
        margin: var(--ww-spacing-05) 0;
        transition: 0.3s;

        &:hover {
            cursor: pointer;
            color: var(--ww-color-blue-500);
            transition: 0.3s;
        }
    }

    .remove-user {
        .remove-user-icon {
            color: var(--ww-color-dark-500);
            &:hover {
                color: var(--ww-color-red-500);
                cursor: pointer;
                transition: 0.3s;
            }
            &.disabled {
                cursor: no-drop;
                color: var(--ww-color-dark-400);
            }
        }
    }

    .button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        margin-top: var(--ww-spacing-06);
    }

    .textarea-wrapper {
        margin: var(--ww-spacing-05) 0 var(--ww-spacing-05) 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        box-sizing: border-box;
        text-align: left;

        .invite-message {
            margin-top: var(--ww-spacing-01);
            width: 100%;
        }

        .label {
            color: var(--ww-color-dark-500);
        }
    }
}
</style>
