<template>
    <div class="ww-popup-workspace-delete ww-scroll-bar">
        <div class="description">
            <div class="tooltip">Unexpected bad things will happen if you don't read this!</div>
            <p>
                This action <b>cannot be undone</b>. This will permanently delete the
                <b>{{ options.data.workspace.name }}</b> workspace, projects, coded components and remove all member
                associations.
            </p>
            <p>
                Please type <b>{{ options.data.workspace.name }}</b> to confirm.
            </p>
        </div>
        <label class="workspace__label caption-s" for="workspace-name">
            {{ $t('organizations.settings.informations.organizationName') }}
            <span class="workspace__label-required"> {{ $t('utils.required') }}</span>
        </label>
        <input
            type="text"
            class="workspace__input ww-editor-input -large caption-m"
            name="workspace-name"
            v-model="userInput"
            :placeholder="$t('utils.name')"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'wwPopupWorkspaceDelete',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
        active: Boolean,
    },
    data() {
        return {
            userInput: '',
        };
    },
    watch: {
        userInput() {
            this.setButtonState();
        },
    },
    computed: {
        isValid() {
            if (!this.userInput) return false;
            return this.options.data.workspace.name === this.userInput.trim();
        },
    },
    methods: {
        ...mapActions({
            deleteWorkspaceStore: 'v2/deleteOrganization',
        }),
        setButtonState() {
            if (this.isValid) this.options.setButtonState('DELETE', 'ok');
            else this.options.setButtonState('DELETE', 'disabled');
        },
        async deleteWorkspace() {
            try {
                await this.deleteWorkspaceStore(this.options.data.workspace.id);

                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Workspace successfully deleted</b> ',
                        fr: '<b>Workspace supprimé avec succès</b>',
                    },
                    color: 'green',
                    duration: '5000',
                });

                this.$router.replace('/workspaces');
            } catch (error) {
                wwLib.wwLog.error(error);
                wwLib.wwNotification.open({
                    text: {
                        en: '<b>Error while deleting the workpsace</b>',
                        fr: '<b>Erreur lors de la suppression du workspace</b>',
                    },
                    color: 'red',
                    duration: '5000',
                });
            }
        },
        async beforeNext() {
            this.options.setLoadingStatus(true);
            await this.deleteWorkspace();
            this.options.setLoadingStatus(false);
        },
    },
    mounted() {
        this.setButtonState();
    },
};
</script>

<style lang="scss" scoped>
.ww-popup-workspace-delete {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;

    .description {
        .tooltip {
            padding: var(--ww-spacing-02);
            border-radius: var(--ww-spacing-01);
            background-color: var(--ww-color-red-100);
        }
    }
    .workspace {
        &__label {
            display: flex;
            font-weight: 500;
            color: var(--ww-color-dark-500);
            margin-bottom: var(--ww-spacing-01);
            &-required {
                margin-left: auto;
                color: var(--ww-color-dark-400);
            }
        }
        &__row {
            display: flex;
            align-items: center;
        }
        &__input {
            width: 100%;
            margin-bottom: var(--ww-spacing-03);
            &-radio {
                max-width: 50%;
            }
        }
        &__loader {
            transform: scale(0.8);
        }
        &__error {
            margin: var(--ww-spacing-04) auto;
            color: var(--ww-color-red-500);
        }
        &__lang-selector {
            margin-bottom: var(--ww-spacing-03);
        }
    }
}
</style>
