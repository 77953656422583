<template>
    <wwManagerListbox
        class="ww-editor-select ww-editor-input"
        :class="{ small, large, dark, disabled }"
        :options="options"
        :value="value"
        @input="$emit('input', $event)"
    >
        <template v-slot:selected="{ option }">
            <span class="ww-editor-select-label" v-if="value !== undefined">
                {{ wwManagerLang.getText(option.label) }}
            </span>
            <span class="ww-editor-select-placeholder" v-else>{{ placeholder }}</span>
        </template>
        <template v-slot:option="{ option, isSelected }">
            <div class="ww-editor-select__option">
                <wwEditorIcon small name="tick" v-show="isSelected"></wwEditorIcon>
                <span>{{ wwManagerLang.getText(option.label) }}</span>
            </div>
        </template>
    </wwManagerListbox>
</template>

<script>
import wwManagerListbox from '@/components/elements/wwManagerListbox/wwManagerListbox';

export default {
    name: 'wwEditorSelect',
    components: { wwManagerListbox },
    props: {
        value: { type: [String, Number, Object, Boolean] },
        options: {
            type: Array,
            default: () => [],
        },
        small: { type: Boolean, default: false },
        large: { type: Boolean, default: false },
        dark: { type: Boolean, default: false },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            wwManagerLang: wwLib.wwManagerLang,
        };
    },
    watch: {
        options() {
            if (this.options.length === 1) this.$emit('input', this.options[0].value);
        },
    },
    mounted() {
        if (this.options.length === 1) this.$emit('input', this.options[0].value);
    },
};
</script>

<style lang="scss">
.ww-editor-select {
    --bg-color: var(--ww-color-dark-200);
    --bg-color-hover: var(--ww-color-blue-200);
    --bg-color-selected: var(--ww-color-blue-500);
    --color: var(--ww-color-dark-700);

    transition: all 1s ease;
    position: relative;
    font-size: var(--ww-font-size-03);
    border-radius: var(--ww-border-radius-01);
    background-color: var(--bg-color);
    color: var(--color);
    cursor: pointer;

    ::v-deep .ww-manager-listbox {
        background-color: orange;
        cursor: pointer;
    }

    .ww-manager-listbox__button {
        display: flex;
        justify-content: space-between;
        padding: 0;
        border-radius: 0;
        background: none;
        border: none;
        outline: none;
        font-size: inherit;
        color: inherit;
        cursor: inherit;
        &:focus {
            outline: none;
        }
    }
    .ww-manager-listbox__list {
        left: 0;
        border-radius: var(--ww-border-radius-01);
        overflow: hidden;
    }
    .ww-manager-listbox__option {
        padding: var(--ww-spacing-01) var(--ww-spacing-02);
        background-color: var(--bg-color);
        color: var(--color);
        text-transform: capitalize;
        .ww-editor-select__option {
            display: flex;
            align-items: center;
            width: 100%;
            .ww-editor-icon {
                margin-right: var(--ww-spacing-00);
            }
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &:hover {
            background: var(--bg-color-hover);
        }
        &.-selected {
            background: var(--bg-color-selected);
            color: var(--ww-color-white);
        }
    }

    &-label {
        text-transform: capitalize;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &-placeholder {
        color: var(--ww-color-dark-400);
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &.small {
        padding: calc(var(--ww-spacing-01) / 2) var(--ww-spacing-01) calc(var(--ww-spacing-01) / 2) var(--ww-spacing-02);
        font-size: var(--ww-font-size-01);
    }
    &.large {
        padding: var(--ww-spacing-03) var(--ww-spacing-02);
        border-radius: var(--ww-border-radius-02);
        font-size: var(--ww-font-size-03);
    }
    &.dark {
        --bg-color: var(--ww-color-dark-600);
        --bg-color-hover: var(--ww-color-dark-700);
        --color: var(--ww-color-white);
    }
}
</style>
