import wwPopupSectionBaseVersions from './wwPopupSectionBaseVersions.vue';
import wwPopupWwObjectBaseVersions from './wwPopupWwObjectBaseVersions.vue';
import wwPopupPluginVersions from './wwPopupPluginVersions.vue';
import wwPopupSourceCodeType from './wwPopupSourceCodeType.vue';
import wwPopupSourceCodeGithub from './wwPopupSourceCodeGithub.vue';
import wwPopupSourceCodeFigma from './wwPopupSourceCodeFigma.vue';
import wwPopupSourceCodeWorkspaces from './wwPopupSourceCodeWorkspaces.vue';

export default function install(Vue) {
    Vue.component('wwPopupSectionBaseVersions', wwPopupSectionBaseVersions);
    Vue.component('wwPopupWwObjectBaseVersions', wwPopupWwObjectBaseVersions);
    Vue.component('wwPopupPluginVersions', wwPopupPluginVersions);
    Vue.component('wwPopupSourceCodeType', wwPopupSourceCodeType);
    Vue.component('wwPopupSourceCodeGithub', wwPopupSourceCodeGithub);
    Vue.component('wwPopupSourceCodeFigma', wwPopupSourceCodeFigma);
    Vue.component('wwPopupSourceCodeWorkspaces', wwPopupSourceCodeWorkspaces);
}
