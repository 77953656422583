import apollo from '@/apollo';
import {
    // Design
    GET_DESIGNS,
    GET_DESIGNS_METRICS,
    GET_DESIGN,
    UPDATE_DESIGN,
    DELETE_DESIGN,
    UNPUBLISH_DESIGN,
    DUPLICATE_DESIGN,
    // Domain
    DOMAIN_CHECK,
    DOMAIN_ADD,
    DOMAIN_CONNECT,
    DELETE_DOMAIN_NAME,
    DOMAIN_RETRY,
    // Publish
    PUBLISH,
    PROMOTE,
    CHECKPOINT,
    IS_EXPORT_ZIP_EXIST,
    GET_EXPORT_ZIP_STATUS,
    GENERATE_EXPORT_ZIP,
    SET_ACTIVE_CACHE_VERSION,
    GET_CACHE_VERSIONS,
    GET_SELF_HOST_DOMAIN,
    UPDATE_SELF_HOST_DOMAIN,
} from './design.graphql';

export default {
    /*=============================================m_ÔÔ_m=============================================\
        Design
    \================================================================================================*/
    async getDesigns(organizationId) {
        const result = await apollo.query({
            query: GET_DESIGNS,
            variables: {
                organizationId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_DESIGNS');

        return result.data.getDesigns;
    },
    async getDesignsMetrics(organizationId) {
        const result = await apollo.query({
            query: GET_DESIGNS_METRICS,
            variables: {
                organizationId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_DESIGNS_METRICS');

        return result.data.getDesignsMetrics;
    },
    async getDesign(designId) {
        const result = await apollo.query({
            query: GET_DESIGN,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_DESIGN');
        return result.data.getDesign;
    },
    async updateDesign(
        designId,
        { name, icons, headScripts, bodyScripts, homePageId, manifest, langs, deployHook, deployConfig }
    ) {
        const result = await apollo.mutate({
            mutation: UPDATE_DESIGN,
            variables: {
                designId,
                name,
                icons,
                headScripts,
                bodyScripts,
                homePageId,
                manifest,
                langs,
                deployHook,
                deployConfig,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_UPDATE_DESIGN');

        return result.data.updateDesign;
    },
    async deleteDesign(designId) {
        const result = await apollo.mutate({
            mutation: DELETE_DESIGN,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DELETE_DESIGN');

        return result.data.deleteDesign;
    },
    async unpublishDesign(designId) {
        const result = await apollo.mutate({
            mutation: UNPUBLISH_DESIGN,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_UNPUBLISH_DESIGN');

        return result.data.unpublishDesign;
    },
    async deleteDomainName(designId) {
        const result = await apollo.mutate({
            mutation: DELETE_DOMAIN_NAME,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DELETE_DOMAIN_NAME');

        return result.data.deleteDomainName;
    },
    async domainRetry(designId) {
        const result = await apollo.mutate({
            mutation: DOMAIN_RETRY,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DOMAIN_RETRY');

        return result.data.deleteDomainName;
    },
    async duplicateDesign(organizationId, designId, designName, langs) {
        const result = await apollo.mutate({
            mutation: DUPLICATE_DESIGN,
            variables: {
                organizationId,
                designId,
                designName,
                langs,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DUPLICATE_DESIGN');

        return result.data.duplicateDesign;
    },
    /*=============================================m_ÔÔ_m=============================================\
        Domain
    \================================================================================================*/
    async domainCheck(domainName) {
        const result = await apollo.query({
            query: DOMAIN_CHECK,
            variables: {
                domainName,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DOMAIN_CHECK');

        return result.data.domainCheck;
    },
    async domainAdd(designId, domainNames) {
        const result = await apollo.mutate({
            mutation: DOMAIN_ADD,
            variables: {
                designId,
                domainNames,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DOMAIN_ADD');

        return result.data.domainAdd;
    },
    async domainConnect(designId, domainName) {
        const result = await apollo.mutate({
            mutation: DOMAIN_CONNECT,
            variables: {
                designId,
                domainName,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_DOMAIN_CONNECT');

        return result.data.domainConnect;
    },

    /*=============================================m_ÔÔ_m=============================================\
        Publish
    \================================================================================================*/
    async publish(designId, commit, description, env) {
        const result = await apollo.mutate({
            mutation: PUBLISH,
            variables: {
                designId,
                commit,
                description,
                env,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_PUBLISH');

        return result.data.publish;
    },
    async promote(designId, cacheVersion) {
        const result = await apollo.mutate({
            mutation: PROMOTE,
            variables: {
                designId,
                cacheVersion,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_PROMOTE');

        return result.data.release;
    },
    async checkpoint(designId, cacheVersion) {
        const result = await apollo.mutate({
            mutation: CHECKPOINT,
            variables: {
                designId,
                cacheVersion,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_CHECKPOINT');

        return result.data.checkpoint;
    },
    /*=============================================m_ÔÔ_m=============================================\
        SELF HOST
    \================================================================================================*/
    async isExportZipExist(designId, cacheVersion, raw) {
        const result = await apollo.query({
            query: IS_EXPORT_ZIP_EXIST,
            variables: {
                designId,
                cacheVersion,
                raw,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_IS_EXPORT_ZIP_EXIST');

        return result.data.isExportZipExist.isExportZipExist;
    },
    async getExportZipStatus(designId, cacheVersion, raw) {
        const result = await apollo.query({
            query: GET_EXPORT_ZIP_STATUS,
            variables: {
                designId,
                cacheVersion,
                raw,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_EXPORT_ZIP_STATUS');

        return result.data.getExportZipStatus;
    },
    async generateExportZip(designId, cacheVersion, raw) {
        const result = await apollo.mutate({
            mutation: GENERATE_EXPORT_ZIP,
            variables: {
                designId,
                cacheVersion,
                raw,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GENERATE_EXPORT_ZIP');

        return result.data.generateExportZip;
    },
    async setActiveCacheVersion(designId, cacheVersion, env) {
        const result = await apollo.mutate({
            mutation: SET_ACTIVE_CACHE_VERSION,
            variables: {
                designId,
                cacheVersion,
                env,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_SET_ACTIVE_CACHE_VERSION');

        return result.data.setActiveCacheVersion;
    },
    async getCacheVersions(designId) {
        const result = await apollo.query({
            query: GET_CACHE_VERSIONS,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_EXPORT_ZIP_STATUS');

        return result.data.getCacheVersions;
    },
    async getSelfHostDomain(designId) {
        const result = await apollo.query({
            query: GET_SELF_HOST_DOMAIN,
            variables: {
                designId,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_GET_SELF_HOST_DOMAIN');

        return result.data.getSelfHostDomain;
    },
    async updateSelfHostDomain(designId, domain, stagingDomain) {
        const result = await apollo.mutate({
            mutation: UPDATE_SELF_HOST_DOMAIN,
            variables: {
                designId,
                domain,
                stagingDomain,
            },
            fetchPolicy: 'no-cache',
        });
        if (!result) throw new Error('FAILED_TO_UPDATE_SELF_HOST_DOMAIN');

        return result.data.updateSelfHostDomain;
    },
};
