<template>
    <div class="ww-modal">
        <div class="ww-modal__background"></div>
        <div class="ww-modal__container">
            <div class="content">
                <div class="ww-modal__item header">
                    <div class="title paragraph-m">{{ options.title[$i18n.locale] }}</div>
                </div>
                <div class="ww-modal__item modal caption-m">
                    <div v-html="options.text[$i18n.locale]"></div>
                </div>
                <div class="ww-modal__item footer">
                    <button
                        class="footer-button ww-editor-button"
                        :class="[button.color]"
                        v-for="(button, index) in options.buttons"
                        :key="index"
                        @click="closeModal(button.value)"
                    >
                        {{ button.text[$i18n.locale] }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'wwModal',
    props: {
        options: Object,
        close: Function,
    },
    data() {
        return {
            wwManagerLang: wwLib.wwManagerLang,
            modalId: null,
        };
    },
    methods: {
        init() {
            this.modalId = Math.random();
        },
        enter() {
            let enterButton = this.options.buttons[0];
            for (let button of this.options.buttons) {
                if (button.enter) {
                    enterButton = button;
                }
            }
            this.closeModal(enterButton.value);
        },
        escape() {
            let escapeButton = null;

            for (let button of this.options.buttons) {
                if (button.escape) escapeButton = button;
            }
            if (escapeButton) this.closeModal(escapeButton.value);
        },
        closeModal(value) {
            this.close(value);
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
.ww-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    &__background {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: var(--ww-color-dark-700);
        transform: scale(1.1);
        opacity: 0.6;
    }
    &__container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: var(--ww-border-radius-03);
        overflow: hidden;
        box-shadow: var(--ww-box-shadow-01);
        .content {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            position: relative;
            width: 500px;
            background-color: var(--ww-color-white);
        }
    }
    &__item {
        width: 100%;
        position: relative;

        &.header {
            flex-basis: 75px;
            display: flex;
            justify-content: center;
            align-items: center;
            .title {
                color: var(--ww-color-blue-500);
                transition: opacity 0.5s ease;
            }
        }

        &.modal {
            flex-grow: 1;
            position: relative;
            padding: 0 var(--ww-spacing-04) var(--ww-spacing-04) var(--ww-spacing-04);
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: calc(100vh - 200px);
            overflow: auto;
        }

        &.footer {
            flex-basis: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            .footer-button {
                margin: 0 var(--ww-spacing-03);
            }
        }
    }
}
</style>
