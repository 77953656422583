import gql from 'graphql-tag';
import { DESIGN_FRAGMENT, DESIGN_METRICS_FRAGMENT, DESIGN_VERSION_FRAGMENT } from './design.fragments';

/*=============================================m_ÔÔ_m=============================================\
    Designs
\================================================================================================*/
export const GET_DESIGNS = gql`
    query GetDesigns($organizationId: String!) {
        getDesigns(organizationId: $organizationId) {
            ...DesignFragment
        }
    }
    ${DESIGN_FRAGMENT}
`;

export const GET_DESIGNS_METRICS = gql`
    query GetDesignsMetrics($organizationId: String!) {
        getDesignsMetrics(organizationId: $organizationId) {
            ...DesignMetricsFragment
        }
    }
    ${DESIGN_METRICS_FRAGMENT}
`;

export const GET_DESIGN = gql`
    query GetDesign($designId: String!) {
        getDesign(designId: $designId) {
            ...DesignFragment
        }
    }
    ${DESIGN_FRAGMENT}
`;

// export const GET_DESIGN_INFO = gql``;

// export const GET_DESIGNS_TO_DUPLICATE = gql``;

// export const CREATE_EMPTY_DESIGN = gql``;

export const UPDATE_DESIGN = gql`
    mutation UpdateDesign(
        $designId: String!
        $name: String
        $icons: JSON
        $headScripts: String
        $bodyScripts: String
        $homePageId: String
        $manifest: JSON
        $langs: JSON
        $deployHook: String
        $deployConfig: JSON
    ) {
        updateDesign(
            designId: $designId
            name: $name
            icons: $icons
            headScripts: $headScripts
            bodyScripts: $bodyScripts
            homePageId: $homePageId
            manifest: $manifest
            langs: $langs
            deployHook: $deployHook
            deployConfig: $deployConfig
        ) {
            ...DesignFragment
        }
    }
    ${DESIGN_FRAGMENT}
`;

export const DELETE_DESIGN = gql`
    mutation DeleteDesign($designId: String!) {
        deleteDesign(designId: $designId) {
            success
        }
    }
`;

export const UNPUBLISH_DESIGN = gql`
    mutation UnpublishDesign($designId: String!) {
        unpublishDesign(designId: $designId) {
            success
        }
    }
`;

export const DELETE_DOMAIN_NAME = gql`
    mutation DeletedDomainName($designId: String!) {
        deleteDomainName(designId: $designId) {
            success
        }
    }
`;

export const DOMAIN_RETRY = gql`
    mutation domainRetry($designId: String!) {
        domainRetry(designId: $designId) {
            success
        }
    }
`;

export const DUPLICATE_DESIGN = gql`
    mutation DuplicateDesign($designId: String!, $designName: String, $organizationId: String!, $langs: JSON) {
        duplicateDesign(designId: $designId, designName: $designName, organizationId: $organizationId, langs: $langs) {
            id
        }
    }
`;

/*=============================================m_ÔÔ_m=============================================\
    Design version
\================================================================================================*/
export const GET_DESIGN_VERSIONS = gql`
    query GetDesignVersions($designId: String!) {
        getDesignVersions(designId: $designId) {
            ...DesignVersionFragment
        }
    }
    ${DESIGN_VERSION_FRAGMENT}
`;

// export const DUPLICATE_DESIGN_VERSION = gql``;

export const DELETE_DESIGN_VERSION = gql`
    mutation DeleteDesignVersion($designVersionId: String!) {
        deleteDesignVersion(designVersionId: $designVersionId) {
            success
        }
    }
`;

export const CHANGE_DESIGN_VERSION_TYPE = gql`
    mutation ChangeDesignVersionType($designVersionId: String!, $type: String!) {
        changeDesignVersionType(designVersionId: $designVersionId, type: $type) {
            ...DesignVersionFragment
        }
    }
    ${DESIGN_VERSION_FRAGMENT}
`;

/*=============================================m_ÔÔ_m=============================================\
    Section
\================================================================================================*/
// export const GET_SECTIONS_UPDATES = gql``;

// export const UPDATE_SECTIONS_VERSIONS = gql``;

/*=============================================m_ÔÔ_m=============================================\
    Domain
\================================================================================================*/
export const DOMAIN_CHECK = gql`
    query DomainCheck($domainName: String!) {
        domainCheck(domainName: $domainName) {
            success
            data
        }
    }
`;

export const DOMAIN_ADD = gql`
    mutation DomainAdd($designId: String!, $domainNames: JSON!) {
        domainAdd(designId: $designId, domainNames: $domainNames) {
            success
        }
    }
`;

export const DOMAIN_CONNECT = gql`
    mutation DomainConnect($designId: String!, $domainName: String!) {
        domainConnect(designId: $designId, domainName: $domainName) {
            redirection
            ssl
            domain
        }
    }
`;

/*=============================================m_ÔÔ_m=============================================\
    Redirection
\================================================================================================*/
// export const GET_REDIRECTION = gql``;

// export const GET_DESIGN_REDIRECTION = gql``;

// export const CREATE_REDIRECTION = gql``;

// export const UPDATE_REDIRECTION = gql``;

// export const DELETE_REDIRECTION = gql``;

/*=============================================m_ÔÔ_m=============================================\
    Image
\================================================================================================*/
// export const GET_DESIGN_IMAGES = gql``;

// export const EDIT_DESIGN_IMAGE = gql``;

/*=============================================m_ÔÔ_m=============================================\
    Save history
\================================================================================================*/
// export const GET_SAVE_HISTORY = gql``;

/*=============================================m_ÔÔ_m=============================================\
    Publish
\================================================================================================*/
export const PUBLISH = gql`
    mutation Publish($designId: String!, $commit: String!, $description: String, $env: String!) {
        publish(designId: $designId, commit: $commit, description: $description, env: $env) {
            success
            status
        }
    }
`;

export const PROMOTE = gql`
    mutation Release($designId: String!, $cacheVersion: Int) {
        release(designId: $designId, cacheVersion: $cacheVersion) {
            success
        }
    }
`;

export const CHECKPOINT = gql`
    mutation checkpoint($designId: String!, $cacheVersion: Int) {
        checkpoint(designId: $designId, cacheVersion: $cacheVersion) {
            success
        }
    }
`;

/*=============================================m_ÔÔ_m=============================================\
    Form config
\================================================================================================*/
// export const GET_FORM_CONFIG = gql``;

// export const CREATE_FORM_CONFIG = gql``;

// export const UPDATE_FORM_CONFIG = gql``;

// export const DELETE_FORM_CONFIG = gql``;

/*=============================================m_ÔÔ_m=============================================\
    Page
\================================================================================================*/
// export const GET_PAGE = gql``;

// export const GET_PAGE_WITH_LINKED_SECTION = gql``;

// export const SET_HOME_PAGE = gql``;

// export const CREATE_PAGE = gql``;

// export const UPDATE_PAGE = gql``;

// export const DELETE_PAGE = gql``;

/*=============================================m_ÔÔ_m=============================================\
    Templates
\================================================================================================*/
export const GET_TEMPLATES = gql`
    query GetTemplates {
        getTemplates {
            id
            displayName
            description
            designId
            preview
            previewLink
        }
    }
`;

// export const CREATE_TEMPLATE = gql``;

// export const UPDATE_TEMPLATE = gql``;

// export const DELETE_TEMPLATE = gql``;

/*=============================================m_ÔÔ_m=============================================\
    SELF HOST
\================================================================================================*/
export const IS_EXPORT_ZIP_EXIST = gql`
    query IsExportZipExist($designId: String!, $cacheVersion: Int!, $raw: Boolean) {
        isExportZipExist(designId: $designId, cacheVersion: $cacheVersion, raw: $raw) {
            isExportZipExist
        }
    }
`;

export const GET_EXPORT_ZIP_STATUS = gql`
    query GetExportZipStatus($designId: String!, $cacheVersion: Int!, $raw: Boolean) {
        getExportZipStatus(designId: $designId, cacheVersion: $cacheVersion, raw: $raw) {
            progress
            status
        }
    }
`;

export const GENERATE_EXPORT_ZIP = gql`
    mutation GenerateExportZip($designId: String!, $cacheVersion: Int!, $raw: Boolean) {
        generateExportZip(designId: $designId, cacheVersion: $cacheVersion, raw: $raw) {
            progress
            status
        }
    }
`;

export const SET_ACTIVE_CACHE_VERSION = gql`
    mutation SetActiveCacheVersion($designId: String!, $cacheVersion: Int!, $env: String!) {
        setActiveCacheVersion(designId: $designId, cacheVersion: $cacheVersion, env: $env) {
            success
            updateStatus
        }
    }
`;

export const GET_CACHE_VERSIONS = gql`
    query GetCacheVersions($designId: String!) {
        getCacheVersions(designId: $designId) {
            success
            cacheVersions {
                cacheVersion
                activeProd
                activeStaging
                filesOk
                createdAt
            }
        }
    }
`;

export const GET_SELF_HOST_DOMAIN = gql`
    query GetSelfHostDomain($designId: String!) {
        getSelfHostDomain(designId: $designId) {
            success
            domain
            stagingDomain
        }
    }
`;

export const UPDATE_SELF_HOST_DOMAIN = gql`
    mutation UpdateSelfHostDomain($designId: String!, $domain: String!, $stagingDomain: String) {
        updateSelfHostDomain(designId: $designId, domain: $domain, stagingDomain: $stagingDomain) {
            success
        }
    }
`;
