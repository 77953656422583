<template>
    <div class="ww-popup-website-domain-connect-caa">
        <div class="faq-title caption-m">Your domain name is missing a <b>CAA record</b>.</div>
        <br />
        <div class="faq caption-m">
            To allow WeWeb to generate an SSL certificate for your domain name and get HTTPS connection, you need to add
            a <b>CAA (Certification Authority Authorization) record</b> to your DNS.
            <br />
            <br />
            WeWeb is using <b>AWS</b> (Amazon Web Services) to generate the SSL certificate.
            <br />
            <br />
            Follow the instructions on this page to add a <b>CAA record</b> to your DNS:
            <br />
            <br />
            <a
                href="https://docs.aws.amazon.com/acm/latest/userguide/troubleshooting-caa.html"
                target="_blank"
                class="link"
                >https://docs.aws.amazon.com/acm/latest/userguide/troubleshooting-caa.html</a
            >.
            <br />
            <br />
            Once the <b>CAA record</b> is added, wait a few minutes and come back to this page to add your domain name.
        </div>
    </div>
</template>

<script>
export default {
    name: 'wwPopupWebsiteDomainConnectHelp',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
};
</script>

<style scoped lang="scss">
.ww-popup-website-domain-connect-caa {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: var(--ww-spacing-03) 0;

    .faq-title {
        margin-top: var(--ww-spacing-02);
    }
    .faq {
        margin-bottom: var(--ww-spacing-02);
    }

    .link {
        color: var(--ww-color-blue-500);
        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
