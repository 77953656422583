<template>
    <div class="ww-notification" :class="options.color">
        <div class="close" @click="close">
            <wwEditorIcon name="none" />
        </div>
        <div class="text" :class="{ pointer: options.action }" v-html="options.text[$i18n.locale]" @click="click"></div>
    </div>
</template>

<script>
export default {
    name: 'wwNotification',
    props: {
        options: Object,
    },
    data() {
        return {
            wwManagerLang: wwLib.wwManagerLang,
        };
    },
    methods: {
        init() {},

        close() {
            this.options.close();
        },

        click() {
            if (typeof this.options.action == 'function') {
                this.options.action();
                this.options.close();
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.ww-notification {
    color: var(--ww-color-white);
    margin: var(--ww-spacing-04) 0 0 0;
    padding: var(--ww-spacing-04);
    border-radius: var(--ww-border-radius-02);
    display: flex;
    align-items: center;
    box-shadow: var(--ww-box-shadow-01);

    &.grey {
        background-color: var(--ww-color-dark-500);
    }
    &.red {
        background-color: var(--ww-color-red-500);
    }
    &.purple {
        background-color: var(--ww-color-purple-500);
    }
    &.orange {
        background-color: var(--ww-color-yellow-700);
    }
    &.yellow {
        background-color: var(--ww-color-yellow-500);
    }
    &.blue {
        background-color: var(--ww-color-blue-500);
    }
    &.green {
        background-color: var(--ww-color-green-500);
    }
    .text {
        flex-grow: 1;
        &.pointer {
            cursor: pointer;
        }
    }
    .close {
        display: flex;
        align-items: center;
        margin-right: var(--ww-spacing-04);
        cursor: pointer;
    }
}
</style>
