<template>
    <div class="ww-popup-list ww-scroll-bar">
        <div class="list">
            <div class="item-container" v-for="(item, index) in options.storyData.list" v-bind:key="index">
                <!-- <div class="separator" v-if="item.separator && !item.hidden">{{getText(item, 'separator')}}</div> -->
                <div class="separator" v-if="item.separator && !item.hidden">{{ $t(item[$i18n.locale]) }}</div>
                <div class="item" v-if="!item.hidden" v-bind:class="{ active: item.active }" v-on:click="next(item)">
                    <div class="icon" :class="item.icon"></div>
                    <div class="title-desc">
                        <div class="title">{{ item.title[$i18n.locale] }}</div>
                        <div v-if="item.desc" class="desc">{{ getText(item, 'desc') }}</div>
                    </div>
                    <div v-if="item.shortcut" class="shortcut">{{ item.shortcut }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'wwPopupList',
    props: {
        options: {
            type: Object,
            default: function () {
                return {};
            },
        },
        active: Boolean,
    },
    data() {
        return {
            listId: null,
        };
    },
    computed: {},
    watch: {
        active() {
            if (this.active) {
                this.addShortcuts();
            } else {
                this.removeShortcuts();
            }
        },
    },
    methods: {
        init() {
            this.options.data.count++;
            this.options.result.count = this.options.data.count;

            wwLib.wwLog.log(this.options);

            this.listId = 'list-' + Math.random();

            this.addShortcuts();
        },

        /*=============================================m_ÔÔ_m=============================================\
          SHORTCUTS
        \================================================================================================*/
        addShortcuts() {
            /*
            let self = this;

            for (let key in this.options.storyData.list) {
                let item = this.options.storyData.list[key];
                if (item.shortcut) {

                    wwLib.wwShortcuts.add({
                        tag: this.listId,
                        key: item.shortcut,
                        action: function () {
                            self.setActiveItem(item);
                        }
                    })
                }
            }
            */
        },
        removeShortcuts() {
            wwLib.wwShortcuts.remove(this.listId);
        },

        /*=============================================m_ÔÔ_m=============================================\
          UTILS
        \================================================================================================*/
        getText(item, text) {
            return wwLib.wwManagerLang.getText(item[text] || '');
        },
        setActiveItem(item) {
            for (let key in this.options.storyData.list) {
                this.options.storyData.list[key].active = false;
                if (item && item == this.options.storyData.list[key]) {
                    item.active = true;

                    let self = this;
                    setTimeout(function () {
                        self.next(item);
                    }, 100);
                    setTimeout(function () {
                        self.setActiveItem();
                    }, 500);
                }
            }
            this.$forceUpdate();
        },

        /*=============================================m_ÔÔ_m=============================================\
          NEXT
        \================================================================================================*/
        async next(item) {
            if (item.confirm) {
                const modalValue = await wwLib.wwModals.open({
                    title: item.confirm.title,
                    text: item.confirm.text,
                    buttons: [
                        {
                            text: {
                                en: 'Cancel',
                                fr: 'Annuler',
                            },
                            color: '-secondary',
                            value: false,
                            escape: true,
                        },
                        {
                            text: {
                                en: 'Confirm',
                                fr: 'Confirmer',
                            },
                            color: '-primary -blue',
                            value: true,
                            enter: true,
                        },
                    ],
                });

                if (!modalValue) {
                    return;
                }
            }

            if (item.result) {
                for (let key in item.result) {
                    this.options.result[key] = item.result[key];
                }
            }

            this.$emit('next', item.next);
        },
    },
    mounted: function () {
        this.init();
    },
    beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.ww-popup-list {
    width: 100%;
    padding: 0 15px;
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item-container {
    width: 100%;
}

.separator {
    color: #e02a4d;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: bold;
    margin: 20px 10px 15px 10px;
}

.item {
    padding: 10px 5px;
    margin: 10px;
    border: 2px solid var(--ww-color-dark-400);
    color: var(--ww-color-dark-600);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 50px;
    cursor: pointer;
    user-select: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;

    &:hover,
    &.active {
        box-shadow: 0px 0px 13px -5px rgba(0, 0, 0, 0.3);
        transform: scale(1.005);
    }

    &:hover {
        border-color: var(--ww-color-green-500);
        .icon {
            color: var(--ww-color-green-500);
        }
    }

    &.active {
        border-color: var(--ww-color-green-500)-strong;
        background-color: var(--ww-color-green-500);
        color: white;
        .icon {
            color: white;
            transition: none;
        }
        .shortcut {
            color: white;
        }
        .desc {
            color: white !important;
        }
    }

    .icon {
        font-size: 2rem;
        padding: 0 15px;
        transition: color 0.3s ease;
    }

    .title-desc {
        flex-grow: 1;
        font-family: 'Montserrat', sans-serif;

        .title {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: bold;
        }

        .desc {
            font-size: 0.9rem;
            color: var(--ww-color-dark-400);
            margin-top: 5px;
        }
    }

    .shortcut {
        padding: 0 15px;
        font-size: 1.2rem;
        color: var(--ww-color-dark-400);
        font-style: italic;
        text-decoration: underline;
    }
}

@media (max-width: 767px) {
    .shortcut {
        display: none;
    }
}

@media (min-width: 768px) {
    .item-container {
        width: 650px;
    }
}

@media (min-width: 992px) {
    .item-container {
        width: 800px;
    }
}
</style>
