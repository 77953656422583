<template>
    <div class="ww-loader-small">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>

<script>
export default {
    props: {},
};
</script>

<style lang="scss" scoped>
.ww-loader-small {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 40px;

    div {
        position: absolute;
        top: 14px;
        left: 8px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
        background: var(--ww-color-blue-500);

        &:nth-child(4) {
            animation: lds-ellipsis1 2.5s infinite;
        }

        &:nth-child(3) {
            animation: lds-ellipsis2 2.5s infinite;
        }

        &:nth-child(2) {
            animation: lds-ellipsis3 2.5s infinite;
        }

        &:nth-child(1) {
            animation: lds-ellipsis4 2.5s infinite;
        }
    }
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    25% {
        transform: scale(1);
    }
    50% {
        transform: translate(24px, 0) scale(1);
    }
    75% {
        transform: translate(48px, 0) scale(1);
    }
    100% {
        transform: translate(48px, 0) scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: scale(1);
    }
    25% {
        transform: translate(24px, 0) scale(1);
    }
    50% {
        transform: translate(48px, 0) scale(1);
    }
    75% {
        transform: translate(48px, 0) scale(0);
    }
    76% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: translate(24px, 0) scale(1);
    }
    25% {
        transform: translate(48px, 0) scale(1);
    }
    50% {
        transform: translate(48px, 0) scale(0);
    }
    51% {
        transform: scale(0);
    }
    75% {
        transform: scale(1);
    }
    100% {
        transform: translate(24px, 0) scale(1);
    }
}

@keyframes lds-ellipsis4 {
    0% {
        transform: translate(48px, 0) scale(1);
    }
    25% {
        transform: translate(48px, 0) scale(0);
    }
    26% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: translate(24px, 0) scale(1);
    }
    100% {
        transform: translate(48px, 0) scale(1);
    }
}
</style>
