import wwLib from '@/wwLib';

export default {
    story: {},
    openPopupSelects: [],

    init() {},
    /*=============================================m_ÔÔ_m=============================================\
      OPEN POPUPSELECT
    \================================================================================================*/
    open(options) {
        let managerSelf;

        if (wwLib.env == 'front') {
            managerSelf = window.parent.wwLib.wwPopupSelects;
        } else {
            managerSelf = this;
        }

        return managerSelf._open(options);
    },
    _open(options) {
        const self = this;

        return new Promise(function (resolve, reject) {
            options = options || {
                type: 'text',
                values: [
                    {
                        value: null,
                        text: {
                            en: '- Select -',
                            fr: '- Choisir -',
                        },
                    },
                ],
            };

            const currentPopupSelect = {
                options: options,
                id: Math.random(),
            };

            currentPopupSelect.close = function (result) {
                self.openPopupSelects.splice(self.openPopupSelects.indexOf(currentPopupSelect), 1);
                if (typeof result !== 'undefined') {
                    return resolve(result);
                } else {
                    return reject(new Error('No Result'));
                }
            };

            self.openPopupSelects.push(currentPopupSelect);
        });
    },
};
