<template>
    <div class="ww-search-bar" :style="style">
        <div class="input-container">
            <svg
                class="search-icon"
                width="16"
                height="16"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.7037 9.87633L8.49844 7.67109C9.02936 6.9643 9.31597 6.10399 9.315 5.22C9.315 2.96203 7.47797 1.125 5.22 1.125C2.96203 1.125 1.125 2.96203 1.125 5.22C1.125 7.47797 2.96203 9.315 5.22 9.315C6.10399 9.31597 6.9643 9.02936 7.67109 8.49844L9.87633 10.7037C9.98796 10.8035 10.1336 10.8567 10.2832 10.8525C10.4329 10.8483 10.5753 10.787 10.6811 10.6811C10.787 10.5753 10.8483 10.4329 10.8525 10.2832C10.8567 10.1336 10.8035 9.98796 10.7037 9.87633ZM2.295 5.22C2.295 4.64149 2.46655 4.07597 2.78795 3.59496C3.10935 3.11394 3.56618 2.73904 4.10065 2.51765C4.63513 2.29627 5.22325 2.23834 5.79064 2.3512C6.35803 2.46407 6.87922 2.74264 7.28829 3.15171C7.69736 3.56078 7.97594 4.08197 8.0888 4.64936C8.20166 5.21676 8.14373 5.80488 7.92235 6.33935C7.70096 6.87382 7.32606 7.33065 6.84504 7.65205C6.36403 7.97345 5.79851 8.145 5.22 8.145C4.44453 8.14407 3.70108 7.8356 3.15274 7.28726C2.6044 6.73892 2.29593 5.99547 2.295 5.22Z"
                    fill="#B8BFC0"
                />
            </svg>
            <input
                ref="search"
                type="text"
                v-model="internalValue"
                :placeholder="placeholder || 'Search'"
                @keydown="checkEnter($event)"
            />
            <div class="loading" v-if="loading">
                <i class="fas fa-spinner fa-spin"></i>
            </div>
        </div>

        <div class="clear-btn" @click="$emit('clear')" v-if="clear">
            <span class="wwi wwi-cross"></span>
        </div>

        <div class="filter-btn" @click="handleFilterClick" v-if="filters">
            <span>{{ $t('utils.filters') }}</span>
            <svg
                class="filter-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.96967 3.21967C1.26256 2.92678 1.73744 2.92678 2.03033 3.21967L6 7.18934L9.96967 3.21967C10.2626 2.92678 10.7374 2.92678 11.0303 3.21967C11.3232 3.51256 11.3232 3.98744 11.0303 4.28033L6.53033 8.78033C6.23744 9.07322 5.76256 9.07322 5.46967 8.78033L0.96967 4.28033C0.676777 3.98744 0.676777 3.51256 0.96967 3.21967Z"
                    fill="#1E2324"
                />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'wwManagerSearchBar',
    props: {
        placeholder: String,
        search: String,
        value: String,
        filters: [String, Boolean],
        clear: [String, Boolean],
        debounce: [String, Number],
        width: String,
        loading: [String, Boolean],
    },
    computed: {
        style() {
            const style = {
                width: this.width,
            };
            return style;
        },

        debounceMs() {
            try {
                return parseInt(this.debounce);
            } catch (error) {
                return 0;
            }
        },
    },
    data() {
        return {
            internalValue: '',
            timeout: null,
        };
    },
    watch: {
        internalValue() {
            let self = this;
            clearTimeout(this.timeout);

            this.timeout = setTimeout(function () {
                self.$emit('input', self.internalValue);
                self.$emit('change', self.internalValue);
            }, this.debounceMs);
        },
        value() {
            this.internalValue = this.value;
        },
    },
    methods: {
        checkEnter(event) {
            if (event.keyCode == 13) {
                this.$emit('enter');
            }
            if (event.keyCode == 38) {
                event.preventDefault();
                event.stopPropagation();
                this.$emit('up');
            }
            if (event.keyCode == 40) {
                event.preventDefault();
                event.stopPropagation();
                this.$emit('down');
            }
        },
        handleFilterClick() {
            this.$emit('filters');
        },
    },
    mounted() {
        this.internalValue = this.value;
        this.reverseIcon = false;
        this.$refs.search.focus();
    },
};
</script>

<style scoped lang="scss">
.ww-search-bar {
    height: 35px;
    width: 100% !important;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;

    overflow: hidden;

    .input-container {
        background-color: var(--ww-color-white);
        padding-left: 10px;
        width: 100%;
        height: 100%;
        position: relative;

        .search-icon {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
        }

        input {
            padding-left: 20px;
            width: 100%;
            border: 0;
            // margin-left: 20px;
            outline: none;
            height: 100%;
            border-radius: 8px;

            &::placeholder {
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                line-height: 16px;
            }
        }
        .loading {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            color: var(--ww-color-dark-600);
        }
    }

    .clear-btn {
        border-left: 1px solid #d4d4d4;
        padding: 0 10px;
        height: 100%;
        line-height: 40px;
        color: var(--ww-color-dark-500);
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;
        user-select: none;

        &:hover {
            background-color: #efefef;
        }
    }
    .filter-btn {
        padding-left: 10px;
        margin-left: 20px;
        height: 100%;
        line-height: 40px;
        color: var(--ww-color-dark-700);
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;
        user-select: none;
        border-radius: 8px;

        &:hover {
            background-color: #efefef;
        }

        .filter-icon {
            margin-right: 10px;
            margin-left: 30px;

            path {
                fill: var(--ww-color-dark-700);
            }
        }
    }
}
</style>
