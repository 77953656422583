export default {
    story: {},
    openModals: [],
    init() {},
    /*=============================================m_ÔÔ_m=============================================\
      OPEN MODAL
    \================================================================================================*/
    open(options) {
        const self = this;

        return new Promise(function (resolve) {
            options = options || {};

            options.title = options.title || {
                en: 'Are you sure ?',
                fr: 'Êtes-vous sûr ?',
            };

            options.text = options.text || {};
            options.buttons = options.buttons || [];
            if (options.buttons.length == 0) {
                options.buttons.push({
                    text: {
                        en: 'Cancel',
                        fr: 'Annuler',
                    },
                    color: '-secondary',
                    value: false,
                    escape: true,
                });
                options.buttons.push({
                    text: {
                        fr: 'Ok',
                        en: 'Ok',
                    },
                    color: '-primary -green',
                    value: true,
                    enter: true,
                });
            }

            const currentModal = {
                options: options,
                id: Math.random(),
            };

            currentModal.close = function (result) {
                self.openModals.splice(self.openModals.indexOf(currentModal), 1);
                return resolve(result);
            };

            self.openModals.push(currentModal);
        });
    },
};
