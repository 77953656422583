import Vue from 'vue';

const vm = new Vue({});

export default {
    vm: vm,
    $on: function (event, fn) {
        vm.$on(event, fn);
    },
    $emit: function (event, ...args) {
        if (!event) {
            return;
        }
        vm.$emit(event, ...args);
    },
    $off: function (event, fn) {
        vm.$off(event, fn);
    },

    /*=============================================m_ÔÔ_m=============================================\
      INIT
    \================================================================================================*/
    init(i18n) {
        this.wwLog.init();
        this.wwPopups.init();
        this.wwModals.init();
        this.wwNotification.init();
        this.wwPopupSelects.init();
        this.wwManagerLang.init(i18n);

        this.$emit('wwLib:init');
    },
};
