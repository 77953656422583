import gql from 'graphql-tag';
import { ORGANIZATION_FRAGMENT } from './workspace.fragments';

/*=============================================m_ÔÔ_m=============================================\
    Organization
\================================================================================================*/
export const GET_ORGANIZATIONS = gql`
    query GetOrganizations {
        getOrganizations {
            ...OrganizationFragment
            role
            owner {
                id
                name
                email
            }
        }
    }
    ${ORGANIZATION_FRAGMENT}
`;

export const GET_ORGANIZATION = gql`
    query GetOrganization($organizationId: String!) {
        getOrganization(organizationId: $organizationId) {
            ...OrganizationFragment
            discount
            owner {
                id
                name
                email
            }
        }
    }
    ${ORGANIZATION_FRAGMENT}
`;

export const CREATE_ORGANIZATION = gql`
    mutation CreateOrganization($name: String!) {
        createOrganization(name: $name) {
            ...OrganizationFragment
            owner {
                id
                name
                email
            }
        }
    }
    ${ORGANIZATION_FRAGMENT}
`;

export const UPDATE_ORGANIZATION = gql`
    mutation UpdateOrganization($organizationId: String!, $name: String!, $picture: String, $selfHostSettings: JSON) {
        updateOrganization(
            organizationId: $organizationId
            name: $name
            picture: $picture
            selfHostSettings: $selfHostSettings
        ) {
            ...OrganizationFragment
            owner {
                id
                name
                email
            }
        }
    }
    ${ORGANIZATION_FRAGMENT}
`;

export const DELETE_ORGANIZATION = gql`
    mutation DeleteOrganization($organizationId: String!) {
        deleteOrganization(organizationId: $organizationId) {
            success
        }
    }
`;

export const SEARCH_ORGANIZATIONS = gql`
    query SearchOrganizations($search: String!) {
        searchOrganizations(search: $search) {
            id
            name
        }
    }
`;

/*=============================================m_ÔÔ_m=============================================\
    Organization - Member
\================================================================================================*/
export const GET_ORGANIZATION_MEMBERS = gql`
    query GetOrganizationMembers($organizationId: String!) {
        getOrganizationMembers(organizationId: $organizationId) {
            user {
                id
                name
                email
            }
            role
        }
    }
`;

export const DELETE_ORGANIZATION_MEMBER = gql`
    mutation DeleteOrganizationMember($organizationId: String!, $userId: String!) {
        deleteOrganizationMember(organizationId: $organizationId, userId: $userId) {
            success
        }
    }
`;

export const UPDATE_ORGANIZATION_MEMBER = gql`
    mutation UpdateOrganizationMemberRole($organizationId: String!, $userId: String!, $role: String!) {
        updateOrganizationMemberRole(organizationId: $organizationId, userId: $userId, role: $role) {
            id
        }
    }
`;

export const UPDATE_ORGANIZATION_MEMBER_ROLE = gql`
    mutation UpdateOrganizationMemberRole($organizationId: String!, $userId: String!, $role: String!) {
        updateOrganizationMemberRole(organizationId: $organizationId, userId: $userId, role: $role) {
            success
        }
    }
`;

/*=============================================m_ÔÔ_m=============================================\
    Organization - Invitation
\================================================================================================*/
export const CREATE_ORGANIZATION_INVITATIONS = gql`
    mutation CreateOrganizationInvitations($organizationId: String!, $users: JSON!, $message: String) {
        createOrganizationInvitations(organizationId: $organizationId, users: $users, message: $message) {
            id
            name
            email
            role
            status
            author {
                id
                name
            }
            updatedAt
        }
    }
`;

export const GET_ORGANIZATION_INVITATIONS = gql`
    query GetOrganizationInvitations($organizationId: String!, $status: String) {
        getOrganizationInvitations(organizationId: $organizationId, status: $status) {
            id
            name
            email
            role
            status
            author {
                name
            }
            updatedAt
        }
    }
`;

export const DELETE_ORGANIZATION_INVITATION = gql`
    mutation DeleteOrganizationInvitation($organizationId: String!, $invitationId: String!) {
        deleteOrganizationInvitation(organizationId: $organizationId, invitationId: $invitationId) {
            success
        }
    }
`;

export const RESEND_ORGANIZATION_INVITATION = gql`
    mutation ResendOrganizationInvitation($organizationId: String!, $invitationId: String!) {
        resendOrganizationInvitation(organizationId: $organizationId, invitationId: $invitationId) {
            success
        }
    }
`;

/*=============================================m_ÔÔ_m=============================================\
    Organization - ContactUs
\================================================================================================*/
export const CONTACT_US = gql`
    mutation ContactUs($organizationId: String!, $userId: String!, $contactType: String!, $message: String!) {
        contactUs(organizationId: $organizationId, userId: $userId, contactType: $contactType, message: $message) {
            success
        }
    }
`;

/*=============================================m_ÔÔ_m=============================================\
    Organization - Selfhost
\================================================================================================*/
export const GENERATE_SELF_HOST_PUBLIC_KEY = gql`
    mutation GenerateSelfHostPublicKey($organizationId: String!) {
        generateSelfHostPublicKey(organizationId: $organizationId) {
            selfHostPublicKey
        }
    }
`;

export const GENERATE_SELF_HOST_PRIVATE_KEY = gql`
    mutation GenerateSelfHostPrivateKey($organizationId: String!) {
        generateSelfHostPrivateKey(organizationId: $organizationId) {
            selfHostPrivateKey
        }
    }
`;

export const TEST_SELF_HOST_CONFIG = gql`
    query TestSelfHostConfig($organizationId: String!, $publicKey: String!, $privateKey: String!) {
        testSelfHostConfig(organizationId: $organizationId, publicKey: $publicKey, privateKey: $privateKey) {
            running
            updateStatus {
                updateStatus
                currentVersion
                lastVersion
            }
            files
            filesStorage
            filesPath
            database
            publicKey
            privateKey
        }
    }
`;

export const GET_SELF_HOST_UPDATE_STATUS = gql`
    query GetSelfHostUpdateStatus($organizationId: String!) {
        getSelfHostUpdateStatus(organizationId: $organizationId) {
            updateStatus
            currentVersion
            lastVersion
        }
    }
`;
