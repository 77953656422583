<template>
    <div>
        <wwLoader :loading="true" />
        <iframe class="marketplace-iframe" :src="marketplaceUrl"></iframe>
    </div>
</template>

<script>
import wwLoader from '@/components/elements/wwLoader.vue';

export default {
    components: { wwLoader },
    props: {
        options: { type: Object, default: () => ({}) },
        active: Boolean,
    },
    emits: ['end'],
    data() {
        return {
            marketplaceUrl: `${process.env.VUE_APP_MARKETPLACE_URL}/new-project/?workspaceId=${this.options.data.workspaceId}`,
        };
    },
    methods: {
        onMessage(event) {
            if (event.data === 'PROJECT_CREATED') this.$emit('end');
        },
    },
    mounted() {
        window.addEventListener('message', this.onMessage);
    },
    beforeDestroy() {
        window.removeEventListener('message', this.onMessage);
    },
};
</script>

<style lang="scss" scoped>
.marketplace-iframe {
    position: absolute;
    border-width: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
}
</style>
